import React, { useState, useEffect } from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { TYPE_ADD, TYPE_REMOVE } from '../../../../../../utils/userManagement/constant';

const TreeNode = ({ node, selectedNodes, onNodeToggle }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(node.children && node.children.length > 0);
  }, [node.children]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const isChecked = selectedNodes.some(selectedNode => selectedNode.id === node.id);

  const handleSwitchChange = (checked) => {
    if (checked) {
      onNodeToggle(TYPE_ADD, node);
    } else {
      onNodeToggle(TYPE_REMOVE, node);
    }
  };

  return (
    <li className='treeNode-list'>
      <div className="node-title">
        <div onClick={handleToggle} className="node-content">
          {node.children && node.children.length > 0 ? (
            <span className="toggle-icon">
              {expanded ? <DownOutlined /> : <RightOutlined />}
            </span>
          ) : (
            <RightOutlined className="leaf-icon" />
          )}
          {node.title}
        </div>
        <Switch className="node-switch" checked={isChecked} onChange={handleSwitchChange} />
      </div>
      {expanded && node.children && node.children.length > 0 && (
        <ul className='accountSelector-ul'>
          {node.children.map(child => (
            <TreeNode
              key={child.id}
              node={child}
              selectedNodes={selectedNodes}
              onNodeToggle={onNodeToggle}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default TreeNode;