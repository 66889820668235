import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import {
  getCustomUserSearchData,
  getCustomUserSearchLoading,
  getEditRoleData,
  getIndividualRoleData,
  getUserManagementRoleList,
  getUserManagementRoleLoading
} from '../../../../../../selectors/userManagement/userManagement.selector';
import { useSelector, useDispatch } from 'react-redux';
import { customSearchUsers, fetchRoleUsers } from '../../../../../../actions/userManagement/userManagement.action';
import TransferBoxInput from './transferBoxInput';
import TransferBoxOutPut from './transferBoxOutput';

const ItemTransferBox = ({ userList, loading, setUsers, setCustomRoleType }) => {
  const dispatch = useDispatch();

  const [selectedRole, setSelectedRole] = useState([]);
  const [outputBoxOne, setOutputBoxOne] = useState([]);
  const [InputputBoxOne, setInputBoxOne] = useState([]);
  const [InputputBoxTwo, setInputBoxTwo] = useState([]);
  const [outputBoxTwo, setOutputBoxTwo] = useState([]);
  const [pagination, setPagination] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [editRoleTypeList, setEditRoleTypeList] = useState([]);

  const userManagementRoleList = useSelector(getUserManagementRoleList);
  const userManagementRoleLoading = useSelector(getUserManagementRoleLoading);
  const editRoleData = useSelector(getEditRoleData);
  const individualRoleData = useSelector(getIndividualRoleData);
  const customUserSearchData = useSelector(getCustomUserSearchData);
  const customUserSearchLoading = useSelector(getCustomUserSearchLoading);

  useEffect(() => {
    if (individualRoleData?.userData?.length > 0 && editRoleData) {
      const outputArray = individualRoleData.userData.map(item => ({
        id: item.user_id,
        name: item.user_profile_name,
        sis_user_id: item.user_sis_id,
        from_base_role: item.from_base_role,
        searchBaseRole: item.searchBaseRole,
        label: item.label,
      }));
      setInputBoxTwo(outputArray);
    }
  }, [individualRoleData, editRoleData]);

  useEffect(() => {
    if (individualRoleData?.baseRoleArr?.length > 0 && editRoleData) {
      setEditRoleTypeList(individualRoleData.baseRoleArr);
    }
  }, [individualRoleData, editRoleData]);

  useEffect(() => {
    setInputBoxOne(customUserSearchData);
    setOutputBoxOne([]);
  }, [customUserSearchData]);

  useEffect(() => {
    if (Object.keys(selectedRole).length && Object.keys(pagination).length && searchValue.length === 0) {
      const payload = {
        role_type: selectedRole.label,
        roleId: selectedRole.roleId,
        searchValue: "",
        pagination: pagination,
        base_role_type:selectedRole.base_role_type,
      };
      dispatch(customSearchUsers(payload));
    }
  }, [JSON.stringify(selectedRole), JSON.stringify(pagination), searchValue]);

  useEffect(() => {
    if (Object.keys(selectedRole).length && Object.keys(pagination).length && searchValue.length > 2) {
      const payload = {
        role_type: selectedRole.label,
        roleId: selectedRole.roleId,
        searchValue: searchValue,
        pagination: pagination,
        base_role_type:selectedRole.base_role_type,
      };
      dispatch(customSearchUsers(payload));
    }
  }, [selectedRole, JSON.stringify(pagination), searchValue]);

  const rightClick = () => {
    setInputBoxTwo(prevState => [...prevState, ...outputBoxOne]);
  };

  const leftClick = () => {
    const filtered = InputputBoxTwo.filter(
      inputItem => !outputBoxTwo.some(outputItem => outputItem.id === inputItem.id)
    );
    setInputBoxTwo(filtered);
  };

  useEffect(() => {
    if (customUserSearchData.length && !customUserSearchLoading) {
      const filtered = customUserSearchData.filter(
        (inputItem) =>
          !InputputBoxTwo.some(
            (InputputBoxTwo) => InputputBoxTwo.id === inputItem.id
          )
      );
      setInputBoxOne(filtered);
    }
  }, [InputputBoxTwo, customUserSearchData, customUserSearchLoading]);

  useEffect(() => {
    const format = InputputBoxTwo.map(item => ({
      id: item.id,
      sis_user_id: item.sis_user_id,
      from_base_role: item.from_base_role,
    }));
    setUsers(format);
  }, [InputputBoxTwo]);

  return (
    <Row gutter={[8, 8]}>
      <Col span={11}>
        <TransferBoxInput
          inputData={InputputBoxOne}
          inputDataLoading={customUserSearchLoading}
          roleList={userManagementRoleList}
          roleListLoading={userManagementRoleLoading}
          setSelectRole={setSelectedRole}
          setOutput={setOutputBoxOne}
          setPage={setPagination}
          setSearchValue={setSearchValue}
        />
      </Col>
      <Col span={2}>
        <Row style={{ height: '100%' }} justify="space-around" align="middle">
          <div>
            <Row>
              <Button
                style={{ margin: '5px' }} icon={<RightOutlined />}
                onClick={rightClick}
              />
            </Row>
            <Row>
              <Button style={{ margin: '5px' }} icon={<LeftOutlined />}
                onClick={leftClick}
              />
            </Row>
          </div>
        </Row>
      </Col>
      <Col span={11}>
        <TransferBoxOutPut
          inputData={InputputBoxTwo}
          setOutput={setOutputBoxTwo}
          roleList={editRoleTypeList}
        />
      </Col>
    </Row>
  );
};

export default ItemTransferBox;