import React, { useEffect } from "react";
import RoleManagementTable from "./userManagement.RoleTable";
import {
  getUserManagementRoleTable,
  getUserManagementRoleTableLoading,
} from "../../../../selectors/userManagement/userManagement.selector";
import { useSelector, useDispatch } from 'react-redux';
import {
  editRole,
  fetchRolesTable,
  resetIndividualRoleData,
  resetPermissionPayload, resetSaveData
} from "../../../../actions/userManagement/userManagement.action";

const RoleManagementTableView = ({ searchValue }) => {
  const dispatch = useDispatch();
  const userManagementRoleTable = useSelector(getUserManagementRoleTable);
  const userManagementRoleTableLoading = useSelector(getUserManagementRoleTableLoading);

  useEffect(() => {
    dispatch(fetchRolesTable());
    dispatch(resetSaveData());
    dispatch(resetIndividualRoleData());
    dispatch(resetPermissionPayload())
    dispatch(editRole(false));
  }, []);
  return (
    <div>
      <RoleManagementTable
        roleList={userManagementRoleTable}
        roleListLoading={userManagementRoleTableLoading}
        searchValue={searchValue}
      />
    </div>)
}

export default RoleManagementTableView;