import React, { useEffect, useState, memo, useCallback } from "react";
import { Row, Col, Radio, Form, Switch } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
import AccountFilter from "./sidebarPartials/AccountFilter";
import {
  adminVerificationLoading,
  getAdminAccounts,
  getSelectedAccount,
  makeInnovativeCoursesLoading,
  makeInnovativeCoursesMainReportLoading,
  makeInnovativeCourseStatus,
  getfilterDateForFetchCourse,
  getIsApplicationAdmin,
  getStudentClusterDataLoading,
  getSelectedTerm,
  makeInnovativeTermsResponseData,
  makeInnovativeTermsLoading,
  makeInnovativeDateFilterData,
  getSelectedFilterRadioValue,
} from "../../../../selectors/innovative/course.selector";
import {
  getAdvisorAccess,
  getAdvisorViewVisibility,
  getAllCouresLoadingState,
  makeAdvisorFilters,
  makeAdvisorFiltersLoading,
  makeAdvisorTerms,
  makeAdvisorTermsLoading,
} from "../../../../selectors/innovative/advisorView.selector.js";
import {
  clearCourseData,
  fetchCourses,
  fetchNewCourseData,
  resetDateFilterForFetchCourse,
  setCourseStatus,
  setSelectedAccount,
  setTerm,
  updateApplicationLevelAdmin,
  updateFilterRadioValue
} from "../../../../actions/innovative/course.action";
import session from "../../../../utils/session";
import CourseStatusFilter from "./sidebarPartials/CourseStatus";
import CourseDateFilter from "../courseDateFilter/courseDateFilter.js";
import TermFilter from "../termFilter/index.jsx";
import AdvisorDatefilter from "../advisorDateFilter/advisorDatefilter.js";
import {
  setAdvisorStudentFilters,
  updateAdvisorViewVisibility,
} from "../../../../actions/innovative/advisorView.action.js";
import EnrollmentStatusFilter from "./sidebarPartials/EnrollmentStatus.js";
import AdvisorTermFilter from "./sidebarPartials/AdvisorTermFilter.js";

const ADMIN_PATH = "/insights/admin/courses";
const RADIO_PERIOD = "PERIOD";
const RADIO_STATUS = "STATUS";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const CustomHeaderBar = () => {

  const accountList = useSelector(getAdminAccounts);
  const accountsLoading = useSelector(adminVerificationLoading);
  const selectedAccount = useSelector(getSelectedAccount);
  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const areCoursesLoading = useSelector(makeInnovativeCoursesLoading);
  const courseMainReportLoading = useSelector(makeInnovativeCoursesMainReportLoading);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const selectedDateFilter = useSelector(getfilterDateForFetchCourse);
  const selectedState = useSelector(makeInnovativeCourseStatus);
  const selectedTermId = useSelector(getSelectedTerm);
  const terms = useSelector(makeInnovativeTermsResponseData);
  const termsLoading = useSelector(makeInnovativeTermsLoading);
  const dateFilterData = useSelector(makeInnovativeDateFilterData);
  const radioStateValue = useSelector(getSelectedFilterRadioValue);
  const advisorViewVisibility = useSelector(getAdvisorViewVisibility);
  const advisorFilters = useSelector(makeAdvisorFilters);
  const advisorFiltersLoading = useSelector(makeAdvisorFiltersLoading);
  const advisorAccess = useSelector(getAdvisorAccess);
  const advisorTerms = useSelector(makeAdvisorTerms);
  const advisorTermsLoading = useSelector(makeAdvisorTermsLoading);
  const allCouresLoadingState = useSelector(getAllCouresLoadingState); 

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const isAdminPath = path === ADMIN_PATH;
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin = _get(dashboardManagementData, "is_admin", false);

  const isAdminDashboardView = () => {
    return (path === ADMIN_PATH && (isApplicationAdminData.role.toLowerCase() === ACCOUNT_MEMBERSHIP || actAs.toLowerCase() === ACCOUNT_MEMBERSHIP));
  };

  const isAdminDashboard = isAdminDashboardView();

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  }

  const dispatch = useDispatch();
  const [isDateFilterVisible, setDateFilterVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [advisorStartDate, setAdvisorStartDate] = useState(null);
  const [advisorEndDate, setAdvisorEndDate] = useState(null);
  const [enrollmentStatus, setEnrollmentStatus] = useState();
  const [advisorTerm, setAdvisorTerm] = useState();

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs,
    }));
  }, [actAs]);

  const handleAccountsChange = accountId => {
    dispatch(setSelectedAccount(accountId));
    dispatch(fetchCourses({
      existingRequest: false,
      requestParams: {
        "user_id": session.get([
          session.keys.innovativeMeta,
          "token"
        ]),
        accountId,
        filterCourseStates: selectedState,
        selectedToggle: radioValue === RADIO_PERIOD ? "period" : "",
        ...selectedDateFilter,
        ...adminPayload,
        ...dateFilterData,
      }
    }))
  }

  useEffect(() => {
    setRadioValue(radioStateValue);
    setDateFilterVisible(radioStateValue === RADIO_PERIOD);
    if (radioStateValue === RADIO_STATUS) {
      if (selectedAccount) {
        dispatch(fetchCourses({
          existingRequest: false,
          requestParams: {
            "user_id": session.get([
              session.keys.innovativeMeta,
              "token"
            ]),
            accountId: selectedAccount,
            filterCourseStates: selectedState,
            selectedToggle: radioStateValue === RADIO_PERIOD ? "period" : "",
            ...selectedDateFilter,
            ...adminPayload
          }
        }))
      }
    }
  }, [radioStateValue]);

  const handleOnFilterApply = (statusList) => {
    dispatch(clearCourseData());
    dispatch(setCourseStatus(statusList));
    dispatch(fetchNewCourseData(true));
  }

  const onChange = e => {
    const value = _get(e, 'target.value', RADIO_STATUS);
    if (value === RADIO_STATUS) {
      dispatch(resetDateFilterForFetchCourse());
    }
    setRadioValue(value);
    dispatch(updateFilterRadioValue(value));
    setDateFilterVisible(value === RADIO_PERIOD);
  };

const advisorToggleStatus = checked =>{
  dispatch(updateAdvisorViewVisibility(checked));
}

  // term filter change: re-fetch course data
  const termChange = (e) => {
    dispatch(clearCourseData())
    dispatch(setTerm(e));
    dispatch(fetchCourses({
      existingRequest: true,
      requestParams: {
        "user_id": session.get([
          session.keys.innovativeMeta,
          "token"
        ]),
        accountId: selectedAccount,
        term_ids: [e],
        filterCourseStates: selectedState,
        selectedToggle: radioValue === "PERIOD" ? "period" : "",
        ...selectedDateFilter,
        ...adminPayload
      }
    }))
  }

  const enrollmentStatusSelect = status => {
      setEnrollmentStatus(status); 
  }
  const advisorTermSelect = term => {
    setAdvisorTerm(term);
  }

  const handleAdvisorDateFilter = (start ,end) => {
    setAdvisorStartDate(start);
    setAdvisorEndDate(end);
  }

  const isStringNullOrEmpty = (str) => {
    return str == null || str === '';
  }

  useEffect(() => {
    if (!isStringNullOrEmpty(advisorTerm) && !isStringNullOrEmpty(enrollmentStatus)
      && advisorViewVisibility && !isAdminDashboard) {
      const payload = {
        "enStart": null,
        "enEnd": null,
        "state": enrollmentStatus,
        "terms": [advisorTerm],
      }
      dispatch(setAdvisorStudentFilters(payload));
    }
  }, [enrollmentStatus, advisorTerm, isAdminDashboard, advisorViewVisibility]);

  useEffect(() => {
    if (!isStringNullOrEmpty(enrollmentStatus) && advisorViewVisibility && isAdminDashboard) {
      const payload = {
        enStart: advisorStartDate,
        enEnd: advisorEndDate,
        state: enrollmentStatus,
        terms: null,
      }
      dispatch(setAdvisorStudentFilters(payload));
    }
  }, [advisorStartDate, advisorEndDate, enrollmentStatus, isAdminDashboard, advisorViewVisibility]);

  const className = isClusterDataLoading ? "in-sectionCover-disabled" : "in-sectionCover";

  useEffect(() => {
    if ((radioValue === RADIO_PERIOD) &&
      advisorFilters.enrollmentStateArr &&
      advisorFilters.enrollmentStateArr.length > 0) {
      setEnrollmentStatus(advisorFilters.enrollmentStateArr[0]);
    }
  }, [radioValue, advisorTerms]);

  const advisorIsDisabled = useCallback(() => {
    return (isAdminDashboard
      ? (areCoursesLoading || courseMainReportLoading || allCouresLoadingState)
      : allCouresLoadingState)
      && !advisorViewVisibility;
  }, [isAdminDashboard, areCoursesLoading, courseMainReportLoading, allCouresLoadingState, advisorViewVisibility]);

  return (
    <div className={className}>
      <div className={"custom-filter-header"}>
        <Row gutter={15}>
          {isAdminDashboard && (
            <Col
              flex="181px"
              className="header-radio-col"
            >
              <Form.Item
                colon={false}
                className="header-radio-button-group"
              >
                <Radio.Group
                  onChange={onChange}
                  defaultValue={radioValue.length ? radioValue : RADIO_STATUS}
                  value={radioStateValue.length ? radioStateValue : RADIO_STATUS}
                >
                  <Radio value={RADIO_STATUS}>Status</Radio>
                  <Radio value={RADIO_PERIOD}>Period</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
          <Col flex="auto">
            <div className={isAdminPath ? "in-container" : "non-admin-header-row-container"}>
              <Row>
                {isAdminDashboard && isDateFilterVisible && !advisorViewVisibility && (
                  <Col span={8} className="header-col">
                    <Form.Item
                      colon={false}
                    >
                      <CourseDateFilter />
                    </Form.Item>
                  </Col>
                )}
                {advisorViewVisibility && isDateFilterVisible && (
                  <Col span={8} className="header-col">
                    <Form.Item
                      colon={false}
                    >
                      <AdvisorDatefilter
                        handleAdvisorDateFilter={handleAdvisorDateFilter}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isDateFilterVisible && !advisorViewVisibility &&(
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Course Status"
                    >
                      <CourseStatusFilter
                        onFilterApply={handleOnFilterApply}
                        selectedState={selectedState}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isDateFilterVisible && advisorViewVisibility && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Enrollment Status"
                    >
                      <EnrollmentStatusFilter
                        statusList={advisorFilters.enrollmentStateArr}
                        statusLoding={advisorFiltersLoading}
                        enrollmentStatusSelect={enrollmentStatusSelect}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isAdminDashboard && !advisorViewVisibility && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Term"
                    >
                      <TermFilter
                        terms={terms}
                        termsLoading={termsLoading}
                        reportLoading={areCoursesLoading || courseMainReportLoading}
                        termChange={termChange}
                        selectedTerm={selectedTermId}
                        wrapperId="innovative-term-parent-select"
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isAdminDashboard && advisorViewVisibility && (
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Term"
                    >
                      <AdvisorTermFilter
                        terms={advisorTerms}
                        termsLoading={advisorTermsLoading}
                        advisorTermSelect={advisorTermSelect}
                      />
                    </Form.Item>
                  </Col>
                )}
                {isAdminDashboard && !advisorViewVisibility &&(
                  <Col span={4} className="header-col">
                    <Form.Item
                      colon={false}
                      label="Accounts"
                    >
                      <AccountFilter
                        accounts={accountList}
                        accountChange={handleAccountsChange}
                        accountsLoading={accountsLoading}
                        reportLoading={areCoursesLoading || courseMainReportLoading}
                        selectedAccount={selectedAccount}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col flex="auto" className="header-col"></Col>
                <Col flex="200px" className="header-col">
                  {advisorAccess && (
                    <div className="toggle-switch">
                      <Switch
                        checked={advisorViewVisibility}
                        defaultUnChecked
                        onChange={advisorToggleStatus} 
                        disabled={advisorIsDisabled()}
                      />
                      <span>Advisors View</span>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default memo(CustomHeaderBar);