export default {
  credits: false,
  title: "",
  chart: {
    type: "column",
  },
  xAxis: {
    categories: [],
    labels: {
      style: {
        color: "black",
      },
    },
    lineWidth: 1,
    lineColor: "gray",
  },
  yAxis: {
    lineColor: "gray",
    lineWidth: 1,
    title: {
      text: "",
    },
    labels: {
      format: "{value}",
      style: {
        color: "black",
      },
    },
    reversedStacks: false,
    gridLineWidth: 0,
  },
  plotOptions: {
    column: {
      stacking: "percent",
      borderWidth: 0,
    },
  },
  legend: {
    itemMarginBottom: 10,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 2,
  },
  series: [],
};
