import React, { useState, useEffect, useRef } from 'react';
import { Menu, Tooltip } from 'antd';
import _debounce from "lodash/debounce";
import Search from '../../form/Search';
import useRoleHandlers from '../../../../../utils/innovative/rolehandlers';
import {
  getAdvisorViewStudentFilter,
  getAdvisorViewVisibility,
  makeAdvisorRoleList,
} from '../../../../../selectors/innovative/advisorView.selector';
import { useSelector } from 'react-redux';

const RoleList = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const advisorRoleList = useSelector(makeAdvisorRoleList);
  const advisorViewStudentFilter = useSelector(getAdvisorViewStudentFilter);
  const advisorViewVisibility = useSelector(getAdvisorViewVisibility);

  const { selectRole } = useRoleHandlers();

  const selectedRoleRef = useRef(null);

  const onRoleSearch = _debounce((keyword) => {
    if (keyword) {
      const filtered = advisorRoleList.filter(role => {
        return role['role_name'].toLowerCase().includes(keyword.toLowerCase());
      });
      setRoles(filtered);
    } else {
      setRoles(advisorRoleList);
    }
  }, 500);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (!isEmpty(advisorRoleList) && advisorViewVisibility) {
      setRoles(advisorRoleList);
      setSelectedRole(advisorRoleList[0]);
    }
  }, [advisorRoleList, advisorViewVisibility]);

  const onSearch = (event) => {
    const { value } = event.target;
    onRoleSearch(value);
  };

  const onItemClick = (item) => {
    const selectedRoleItem = roles.find(role => role.role_uuid === (item.key));
    if (selectedRoleItem !== undefined) {
      setSelectedRole(selectedRoleItem);
    }
  };

  useEffect(() => {
      if (selectedRole && selectedRole !== selectedRoleRef.current) {
        selectedRoleRef.current = selectedRole;
        selectRole(selectedRole);
      }
  }, [selectedRole]);

  return (
    <div className='course-listing'>
      <h5>Roles</h5>
      <Search onSearch={onSearch} />
      <div className="menuScroller">
        <Menu
          selectedKeys={[selectedRole?.role_uuid]}
          mode="inline"
        >
          {roles.map((role) => (
            <Menu.Item
              onClick={onItemClick}
              key={role.role_uuid}
            >
              <Tooltip title={role.role_name} placement='topRight'>
                <p className="select-menu-text">{role.role_name}</p>
              </Tooltip>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default RoleList;
