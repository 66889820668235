export const processWithConcurrency = async (studentArray, filterPayload, dispatchFun, concurrencyLimit) => {
  const errors = [];
  let activePromises = [];

  const timeoutPromise = (promise, ms) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error("Request timed out"));
      }, ms);

      promise
        .then((res) => {
          clearTimeout(timeoutId);
          resolve(res);
        })
        .catch((err) => {
          clearTimeout(timeoutId);
          reject(err);
        });
    });
  };

  for (const item of studentArray) {
    const payload = {
      user_id: item.studentId,
      state: filterPayload.state,
      enStart: filterPayload.enStart,
      enEnd: filterPayload.enEnd,
      terms: filterPayload.terms,
    };

    const promise = timeoutPromise(dispatchFun(payload), 60000).catch(error => {
      errors.push({ item, error });
    });

    activePromises.push(promise);

    if (activePromises.length >= concurrencyLimit) {
      await Promise.race(activePromises);
      activePromises = activePromises.filter(p => p !== promise);
    }
  }

  await Promise.all(activePromises);

  if (errors.length > 0) {
    console.error('Errors occurred while processing with concurrency:', errors);
  }
};
