import React from 'react';
import PropTypes from 'prop-types';

const AdvisorStatCellRenderContainer = ({ value, color }) => {
  return (
    <div style={{ color: color, display: 'grid', placeItems: 'center' }} className="stat">
      {value}
    </div>
  );
};

AdvisorStatCellRenderContainer.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

export default AdvisorStatCellRenderContainer;