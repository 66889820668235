import { get } from 'lodash';
import {
  DATA_PRIVACY_ACCESS_GRANTED_DATA,
  DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING,
  DATA_PRIVACY_SETTINGS_DATA,
  FETCHING_DATA_PRIVACY_SETTINGS_DATA,
  RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA,
  SET_DATA_PRIVACY_MANAGER_LOADING,
} from '../../constant/actionTypes';
import service from "../../utils/customServices/dashboardManagement";
import { onError } from '../../utils/notificationHandler';
  
  const setDataPrivacySettingLoading = payload => dispatch => {
    dispatch({
      type: SET_DATA_PRIVACY_MANAGER_LOADING,
      payload,
    });
  };
  
  const setDataPrivacySettingStatus = response => dispatch => {
    const data = {
      ...get(response, "data", {}),
      grant: get(response, "grant", false),
    };
  
    dispatch({
      type: DATA_PRIVACY_SETTINGS_DATA,
      payload: {
        isLoading: false,
        error: null,
        data
      },
    });
  };
  
  export const getDataPrivacySettingStatus = () => dispatch => {
    dispatch(
      setDataPrivacySettingLoading(true),
    );
  };
  
  export const saveDataPrivacySettingStatus = (data) => dispatch => {
    dispatch(
      setDataPrivacySettingLoading(true),
    );
  
    service.make({
      service: "insights/dataAccessSetting/dataPrivacyManagementSettingSave",
      method: "POST",
      authorized: true,
      data,
    }).then(response => {
      dispatch(setDataPrivacySettingStatus(response));
    }).catch(error => {
      onError("Action failed");
    })
  };
  
  const fetchingDataPrivacySettingData = (isLoading = true) => dispatch => {
    dispatch({
      type: FETCHING_DATA_PRIVACY_SETTINGS_DATA,
      payload: {isLoading,
      error:false,
      data:{}},
    });
  }
  
  const dataPrivacySettingData = response => dispatch => {
    const data = {
      ...get(response, "data", {}),
    };

    dispatch({
      type: DATA_PRIVACY_SETTINGS_DATA,
      payload: {
        isLoading: false,
        error: response.error,
        data
      },
    });    
  };
  
  
  export const fetchSettingData = (data) => dispatch => {
    dispatch(
      fetchingDataPrivacySettingData(true),
    );
  
    service.make({
      service: "insights/dataAccessSetting/dataPrivacyManagementSettingFetch",
      method: "POST",
      authorized: true,
      data,
    }).then(response => {
      dispatch(dataPrivacySettingData(response));
    }).catch(error => {
      dispatch(dataPrivacySettingData(error));
    });
  };

export const resetDataPrivacyAccess = () => dispatch => {
  dispatch({
    type: RESET_DATA_PRIVACY_ACCESS_GRANTED_DATA,
  });
};

const fetchingDataPrivacyAccess = () => dispatch => {
  dispatch({
    type: DATA_PRIVACY_ACCESS_GRANTED_DATA_LOADING,
    payload: {
      isLoading: true,
    },
  });
};

const dataPrivacyAccess = response => dispatch => {
  const data = {
    grant: get(response, "grant", false),
  };

  dispatch({
    type: DATA_PRIVACY_ACCESS_GRANTED_DATA,
    payload: {
      isLoading: false,
      error: get(response, "error", false),
      data,
    },
  });
};

export const fetchDataPrivacyAccess = () => dispatch => {
  dispatch(fetchingDataPrivacyAccess());

  service.make({
    service: "insights/dataAccessSetting/checkDeveloperKeys",
    method: "GET",
    authorized: true,
  }).then(response => {
    dispatch(dataPrivacyAccess(response));
  }).catch(error => {
    dispatch(dataPrivacyAccess({ error }));
  })
};
