import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const AdvisorTermFilter = ({ terms, termsLoading, advisorTermSelect }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (terms.length > 0 && !termsLoading) {
      const defaultTermId = terms[0]._id;
      setSelectedItem(defaultTermId);
      advisorTermSelect(defaultTermId);
    }
  }, [JSON.stringify(terms), termsLoading]);

  const handleChange = (value) => {
    setSelectedItem(value);
    advisorTermSelect(value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  if (termsLoading) {
    return <Spin size="small" indicator={antIcon} />;
  }

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Please select"
      value={selectedItem}
      onChange={handleChange}
      notFoundContent={termsLoading ? <Spin size="small" /> : null}
    >
      { terms.length > 0 && terms.map(item => (
        <Option
          className="select-option-dropdown"
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default AdvisorTermFilter;