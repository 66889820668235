import _get from 'lodash/get';
import { createSelector } from 'reselect';

const userManagementData = state => _get(state, "userManagementReducer", {});

export const getUserManagementRoleTable = createSelector(
  userManagementData, data => _get(data, 'roleTable.data', [])
);

export const getUserManagementRoleTableLoading = createSelector(
  userManagementData, data => _get(data, 'roleTable.isLoading', false)
);

export const getUserManagementUserTable = createSelector(
  userManagementData, data => _get(data, 'userTable.data.data', [])
);

export const getUserManagementUserTablePageSize = createSelector(
  userManagementData, data => _get(data, 'userTable.data.count', "")
);

export const getUserManagementUserTableLoading = createSelector(
  userManagementData, data => _get(data, 'userTable.isLoading', false)
);

export const getUserManagementRoleList = createSelector(
  userManagementData, data => _get(data, 'roleList.data', [])
);

export const getUserManagementRoleLoading = createSelector(
  userManagementData, data => _get(data, 'roleList.isLoading', false)
);

export const getUserManagementRoleUsersList = createSelector(
  userManagementData, data => _get(data, 'roleUsersList.data', [])
);

export const getUserManagementRoleUsersLoading = createSelector(
  userManagementData, data => _get(data, 'roleUsersList.isLoading', false)
);

export const getAccountsHierarchyList = createSelector(
  userManagementData, data => _get(data, 'accountHierarchyList.data', [])
);

export const getAccountsHierarchyListLoading = createSelector(
  userManagementData, data => _get(data, 'accountHierarchyList.isLoading', false)
);

export const getUserSearchData = createSelector(
  userManagementData, data => _get(data, 'userSearch.data', [])
);

export const getUserSearchLoading = createSelector(
  userManagementData, data => _get(data, 'userSearch.isLoading', false)
);

export const getCustomUserSearchData = createSelector(
  userManagementData, data => _get(data, 'customUserSearch.data.data', [])
);

export const getCustomUserSearchPagination = createSelector(
  userManagementData, data => _get(data, 'customUserSearch.data.pagination', [])
);

export const getCustomUserSearchLoading = createSelector(
  userManagementData, data => _get(data, 'customUserSearch.isLoading', false)
);

export const getSaveRolesStatus = createSelector(
  userManagementData, data => _get(data, 'saveRoles.data', {})
);

export const getSaveRolesLoading = createSelector(
  userManagementData, data => _get(data, 'saveRoles.isLoading', false)
);

export const getSyncRoleData = createSelector(
  userManagementData, data => _get(data, 'syncRoleData.data', [])
);

export const getSyncRoleDataLoading = createSelector(
  userManagementData, data => _get(data, 'syncRoleData.isLoading', false)
);

export const getDeleteRoleData = createSelector(
  userManagementData, data => _get(data, 'deleteRoleData.data', {})
);

export const getDeleteRoleDataLoading = createSelector(
  userManagementData, data => _get(data, 'deleteRoleData.isLoading', false)
);

export const getSavePayloadData = createSelector(
  userManagementData, data => _get(data, 'savePayload.data', {})
);

export const getEditRoleData = createSelector(
  userManagementData, data => _get(data, 'editRole.data', [])
);

export const getIndividualRoleData = createSelector(
  userManagementData, data => _get(data, 'individualRole.data.[0]', {})
);

export const getIndividualRoleLoading = createSelector(
  userManagementData, data => _get(data, 'individualRole.isLoading', false)
);

export const getUpdateRolesData = createSelector(
  userManagementData, data => _get(data, 'editRoleData.data', {})
);

export const getUpdateRolesLoading = createSelector(
  userManagementData, data => _get(data, 'editRoleData.isLoading', false)
);

export const getCourseListData = createSelector(
  userManagementData, data => _get(data, 'courseList.data', [])
);

export const getCourseListLoading = createSelector(
  userManagementData, data => _get(data, 'courseList.isLoading', false)
);

export const getSaveCoursePermissionStatus = createSelector(
  userManagementData, data => _get(data, 'saveCoursePermission.data', {})
);

export const getSaveCoursePermissionLoading = createSelector(
  userManagementData, data => _get(data, 'saveCoursePermission.isLoading', false)
);

export const getCoursePayloadData = createSelector(
  userManagementData, data => _get(data, 'coursePayload', {})
);

export const getPermissionPayloadData = createSelector(
  userManagementData, data => _get(data, 'permissionPayload.data', {})
);

export const getSpecificCoursesData = createSelector(
  userManagementData, data => _get(data, 'specificCourses.data', [])
);

export const getSpecificCoursesLoading = createSelector(
  userManagementData, data => _get(data, 'specificCourses.isLoading', false)
);

export const getCoursePermissionData = createSelector(
  userManagementData, data => _get(data, 'coursePermission.data', [])
);

export const getCoursePermissionLoading = createSelector(
  userManagementData, data => _get(data, 'coursePermission.isLoading', false)
);

export const getDeleteCoursePermissionData = createSelector(
  userManagementData, data => _get(data, 'deleteCoursePermission.data', [])
);

export const getDeleteCoursePermissionLoading = createSelector(
  userManagementData, data => _get(data, 'deleteCoursePermission.isLoading', false)
);

export const getEditCoursePermissionStatus = createSelector(
  userManagementData, data => _get(data, 'editCoursePermission.data.data', {})
);

export const getEditCoursePermissionLoading = createSelector(
  userManagementData, data => _get(data, 'editCoursePermission.isLoading', false)
);

export const getUserManageStaus = createSelector(
  userManagementData, data => _get(data, 'userManageStaus.data', {})
);

export const getUserManageStausLoading = createSelector(
  userManagementData, data => _get(data, 'userManageStaus.isLoading', false)
);

export const getUserManagementRoleStudentList = createSelector(
  userManagementData, data => _get(data, 'roleStudentList.data.data', [])
);

export const getUserManagementRoleStudentListPagination = createSelector(
  userManagementData, data => _get(data, 'roleStudentList.data.pagination', {})
);

export const getUserManagementRoleStudentLoading = createSelector(
  userManagementData, data => _get(data, 'roleStudentList.isLoading', false)
);

export const getUserManagementStudentRoles = createSelector(
  userManagementData, data => _get(data, 'studentRoles.data', [])
);

export const getUserManagementStudentRolesLoading = createSelector(
  userManagementData, data => _get(data, 'studentRoles.isLoading', false)
);

export const getUserManagementSetUserAvailability = createSelector(
  userManagementData, data => _get(data, 'userSelect.selectUsersEmpty', true)
);

export const getUserManagementPermissionsInfoLoading = createSelector(
  userManagementData, data => _get(data, 'permissionsInfo.isLoading', false)
);

export const getUserManagementPermissionsInfo = createSelector(
  userManagementData, data => _get(data, 'permissionsInfo.data', [])
);

export const getUserManagementPermissionsInfoStatus = createSelector(
  userManagementData, data => _get(data, 'permissionsInfo.status', [])
);

export const getEditCanvasStudentStatus = createSelector(
  userManagementData, data => _get(data, 'editCanvasStudent.data.data', {})
);

export const getEditCanvasStudentStatusLoading = createSelector(
  userManagementData, data => _get(data, 'editCanvasStudent.isLoading', false)
);

export const getRoleDeatails = createSelector(
  userManagementData, data => _get(data, 'roleDeatails', false)
);