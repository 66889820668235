import React, { useEffect, memo } from "react";
import { Layout, Row, Col } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import CustomPaginator from "./sidebarPartials/Pagination";
import {
  adminVerificationLoading,
  getStudentClusterDataLoading,
  getIsApplicationAdmin,
  getSelectedFilterRadioValue,
} from "../../../../selectors/innovative/course.selector";
import { getAdvisorViewVisibility } from "../../../../selectors/innovative/advisorView.selector.js";
import { updateApplicationLevelAdmin } from "../../../../actions/innovative/course.action";
import {
  fetchAdvisorFilters,
  fetchAdvisorRoles,
  fetchAdvisorTermFilters
} from "../../../../actions/innovative/advisorView.action";
import CourseList from "./sidebarPartials/CourseList";
import RoleList from "./sidebarPartials/RoleList";
import session from "../../../../utils/session";

const ADMIN_PATH = "/insights/admin/courses";
const ACCOUNT_MEMBERSHIP = "accountmembership";

const SidebarCourseView = () => {
  const isAdminVerificationLoading = useSelector(adminVerificationLoading);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const isClusterDataLoading = useSelector(getStudentClusterDataLoading);
  const radioValue = useSelector(getSelectedFilterRadioValue);
  const advisorViewVisibility = useSelector(getAdvisorViewVisibility);

  const parsedUrl = new URL(window.location.href);
  const path = parsedUrl.pathname;
  const actAs = parsedUrl.searchParams.get("actAs");
  const userId = session.get([session.keys.innovativeMeta, "token"]);

  const isAdminDashboardView = () => {
    return (path === ADMIN_PATH &&
      (
        isApplicationAdminData.role.toLowerCase() === ACCOUNT_MEMBERSHIP ||
        actAs.toLowerCase() === ACCOUNT_MEMBERSHIP
      ));
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs
    }));
  }, [actAs]);

  useEffect(() => {

    if (advisorViewVisibility) {
      dispatch(fetchAdvisorRoles({ "userId": userId }));
      dispatch(fetchAdvisorFilters({ "userId": userId }));
      dispatch(fetchAdvisorTermFilters({ "userId": userId }));
    }

  }, [advisorViewVisibility, userId, dispatch]);

  const className = isClusterDataLoading ? "in-sectionCover-disabled-course-view" : "in-sectionCover";

  return (
    <Layout.Sider
      width={250}
      breakpoint="md"
      collapsedWidth="0"
      trigger={null}
    >
      <div className={className}>
        <Row gutter={[16, 5]} justify={"center"}>
          <Col>
            {!isAdminVerificationLoading && isAdminDashboardView() && (
              advisorViewVisibility ? <RoleList /> : <CustomPaginator dateFilterKey={radioValue} />
              
            )}
          </Col>
          <Col>
            {!isAdminVerificationLoading && !isAdminDashboardView() && (
              advisorViewVisibility ? <RoleList /> : <CourseList />
            )}
          </Col>
        </Row>
      </div>
    </Layout.Sider>
  )
}

export default memo(SidebarCourseView);
