import React, { useEffect, useState } from "react";
import { Button, Input, Row, Select, Spin, Tag, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import StudentSearch from "./search";
import {
  fetchRoleStudent,
  fetchStudentRoles,
  searchUsers,
} from "../../../../../actions/userManagement/userManagement.action";
import {
  getEditRoleData,
  getUserManagementRoleStudentList,
  getUserManagementRoleStudentListPagination,
  getUserManagementRoleStudentLoading,
  getUserManagementStudentRoles,
  getUserSearchData,
} from "../../../../../selectors/userManagement/userManagement.selector";
import { BY_ROLE, BY_USER_NAME } from "../../../../../utils/userManagement/constant";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const InputGroup = Input.Group;
const { Title } = Typography;

const Student = ({ studentType, students, defaultStudents, setStudentRoleType, defaultStudentsRoleType }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultStudentsRoleType) {
      const payload = {
        id: defaultStudentsRoleType,
        base_role_type: "StudentEnrollment",
        pagination: { next: true, currentPage: 0 },
      };
      dispatch(fetchRoleStudent(payload));
    }
  }, [defaultStudentsRoleType]);


  const [selectedUsers, setSelectedUsers] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [studentRole, setStudentRole] = useState([]);
  const [pageNum, setPagenum] = useState(1);

  const userSearchData = useSelector(getUserSearchData);
  const editRoleData = useSelector(getEditRoleData);
  const userManagementRoleStudentList = useSelector(
    getUserManagementRoleStudentList
  );
  const userManagementRoleStudentLoading = useSelector(
    getUserManagementRoleStudentLoading
  );
  const [selectedOption, setSelectedOption] = useState(
    editRoleData ? (defaultStudents.length ? BY_USER_NAME : BY_ROLE) : BY_USER_NAME
  );
  const userManagementStudentRoles = useSelector(getUserManagementStudentRoles);
  const userManagementRoleStudentListPagination = useSelector(getUserManagementRoleStudentListPagination);

  useEffect(() => {
    if (defaultStudentsRoleType &&
      Object.keys(userManagementStudentRoles).length
    && editRoleData) {
      const foundRole = userManagementStudentRoles.find(role => role.roleId === defaultStudentsRoleType);
      setStudentRole(foundRole);
    }
  }, [userManagementStudentRoles, defaultStudentsRoleType])

  useEffect(() => {
    dispatch(fetchStudentRoles())
  }, [])

  useEffect(() => {
    students(studentList);
  }, [studentList]);

  useEffect(() => {
    setSelectedOption(
      editRoleData ? (defaultStudents.length ? BY_USER_NAME : BY_ROLE) : BY_USER_NAME
    );
  }, [editRoleData, defaultStudents]);

  useEffect(() => {
    if (defaultStudents && defaultStudents.length > 0) {
      setSelectedUsers(defaultStudents);
      const newArray = defaultStudents.map(({ name, ...rest }) => rest);
      setStudentList(newArray);
    }
  }, [defaultStudents]);

  const handleSearch = (value) => {
    if (value.length > 2) {
      const payload = {
        searchValue: value,
      };
      dispatch(searchUsers(payload));
    }
  };

  const selectSudent = (value) => {
    const filteredItem = userSearchData.find((item) => item.userId == value);
    const output = {
      id: filteredItem.userId,
      sisId: filteredItem.sis_id,
    };
    const displayOutput = {
      id: filteredItem.userId,
      name: filteredItem.name,
      sisId: filteredItem.sis_id,
    };
    if (!studentList.find((element) => element.id == value)) {
      setStudentList([...studentList, output]);
      setSelectedUsers([...selectedUsers, displayOutput]);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    studentType(value);
  };

  const handleRemoveTag = (tag) => {
    const upadateDisplayOutput = selectedUsers.filter(
      (user) => user.id !== tag.id
    );
    const upadateOutput = studentList.filter((user) => user.id !== tag.id);
    setStudentList(upadateOutput);
    setSelectedUsers(upadateDisplayOutput);
  };



  const handleRoleChange = (value) => {
    const data = JSON.parse(value);
    setStudentRoleType(data);
    setStudentRole(data)
    const payload = {
      id: data.roleId,
      base_role_type: data.base_role_type,
      pagination: { next: true, currentPage: 0 }
    };
    dispatch(fetchRoleStudent(payload));
  };

  const previousClick = () => {
    if (userManagementRoleStudentListPagination.previous) {
      setPagenum((prevCount) => prevCount - 1);
      const Payload = {
        id: studentRole.roleId,
        base_role_type: studentRole.base_role_type,
        pagination: {
          currentPage: pageNum,
          previous: userManagementRoleStudentListPagination.previous,
        }
      }
      dispatch(fetchRoleStudent(Payload));
    }
  };

const nextClick = () => {
  if (userManagementRoleStudentListPagination.next) {
    setPagenum((prevCount) => prevCount + 1);
    const Payload = {
      id: studentRole.roleId,
      base_role_type: studentRole.base_role_type,
      pagination: {
        currentPage: pageNum,
        next: userManagementRoleStudentListPagination.next,
      },
    };
    dispatch(fetchRoleStudent(Payload));
  }
};
const onClickHandlerFirst = () => {
  if (userManagementRoleStudentListPagination.previous) {
    setPagenum(0);
    const Payload = {
      id: studentRole.roleId,
      base_role_type: studentRole.base_role_type,
      pagination: {
        currentPage: 0,
        previous: false,
      },
    };
    dispatch(fetchRoleStudent(Payload));
  }
};

  return (
    <div>
      <Row>
        <Title level={5}>Students</Title>
      </Row>
      <Row>
        <InputGroup compact>
          <Select
            defaultValue={selectedOption}
            onChange={handleOptionChange}
            style={{ width: "25%" }}
            disabled={editRoleData}
          >
            <Option value="ByUsername">By Username</Option>
            <Option value="ByRole">By Role</Option>
          </Select>
          {selectedOption === BY_USER_NAME ? (
            <StudentSearch
              style={{ width: "75%" }}
              selectSudent={selectSudent}
              handleSearch={handleSearch}
              users={userSearchData}
            />
          ) : (
            <Select
              onChange={handleRoleChange}
              style={{ width: "75%" }}
              value={studentRole.label}
            >
              {userManagementStudentRoles.map(role => (
                <Option
                  key={role.roleId}
                  value={JSON.stringify({
                    roleId: role.roleId,
                    base_role_type: role.base_role_type,
                    label: role.label
                  })
                  }>
                  {role.label}
                </Option>
              ))}
            </Select>
          )}
        </InputGroup>
      </Row>
      <div className="user-manage-scroll-container">
        <div className="user-manage-spinner">
          <Spin
            indicator={<LoadingOutlined spin />}
            size="small"
            spinning={userManagementRoleStudentLoading} />
        </div>
        {selectedOption === BY_USER_NAME &&
          selectedUsers &&
          selectedUsers.map((user, index) => (
            <Tag
              color="#108ee9"
              key={user.id}
              closable
              onClose={() => handleRemoveTag(user)}
              style={{ margin: "3px", borderRadius: "10px", marginTop: 16 }}
            >
              {user.name}
              {user.sisId ? ` (${user.sisId})` : ""}
            </Tag>
          ))}
        {selectedOption === BY_ROLE &&
          userManagementRoleStudentList.length > 0 &&
          userManagementRoleStudentList.map((user, index) => (
            <Tag
              color="#108ee9"
              key={user.sis_user_id}
              style={{ margin: "3px", borderRadius: "10px", marginTop: 16 }}
            >
              {user.name}
              {user.sis_user_id ? ` (${user.sis_user_id})` : ""}
            </Tag>
          ))
          }
        {selectedOption === BY_ROLE && userManagementRoleStudentList.length > 0 && (
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Button className='studentBox-navigation'
              onClick={onClickHandlerFirst}
              disabled={!userManagementRoleStudentListPagination.previous}
            >First
            </Button>
            <Button
              className='studentBox-navigation'
              onClick={previousClick}
              disabled={!userManagementRoleStudentListPagination.previous}>
              Prev
            </Button>
            <Button
              className='studentBox-navigation'
              onClick={nextClick}
              disabled={!userManagementRoleStudentListPagination.next}
            >Next
            </Button>
          </div>
        )}         
      </div>
    </div>
  );
};

export default Student;
