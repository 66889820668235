export default {
    VET_GEN_001: {
      description:
        'Vettrak Risk Analysis app will assist you to identify students at risk in your institute.',
      showActive: true,
      settingsUrl: null,
    },
    AXL_GEN_001: {
      description: 'Axcelerate Risk Analysis app will assist you to identify students at risk in your institute.',
      showActive: true,
      settingsUrl: null,
    },
    DEFAULT: {
      description: 'Risk Analysis app will assist you to identify students at risk in your institute.',
      showActive: false,
      settingsUrl: null,
    },
  };
  