import React from "react";
import UserManagementTable from "./userManagement.UserTable";
import { useSelector } from 'react-redux';
import {
  getUserManagementUserTable,
  getUserManagementUserTableLoading,
} from "../../../../selectors/userManagement/userManagement.selector";

const UserManagementTableView = ({ searchValue }) => {

  const userManagementUserTable = useSelector(getUserManagementUserTable);
  const userManagementUserTableLoading = useSelector(getUserManagementUserTableLoading);

  return (
    <div>
      <UserManagementTable
        userList={userManagementUserTable}
        userListLoading={userManagementUserTableLoading}
        searchValue={searchValue}
      />
    </div>
  );
}

export default UserManagementTableView;
