import React, { memo, useEffect, useState } from "react";
import { Col, DatePicker, Form, Row } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCourses,
  setDateFilterForFetchCourse,
  updateApplicationLevelAdmin
} from "../../../../actions/innovative/course.action";
import session from "../../../../utils/session";
import {
  getIsApplicationAdmin,
  getSelectedAccount,
  makeInnovativeCourseStatus
} from "../../../../selectors/innovative/course.selector";
import { get as _get} from "lodash";

const CourseDateFilter = () => {

  const dispatch = useDispatch();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const [isEndDatePickerVisible, setIsEndDatePickerVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const selectedAccount = useSelector(getSelectedAccount);
  const selectedState = useSelector(makeInnovativeCourseStatus);
  const isApplicationAdminData = useSelector(getIsApplicationAdmin);
  const parsedUrl = new URL(window.location.href);
  const actAs = parsedUrl.searchParams.get("actAs") ?? isApplicationAdminData.role;
  const dashboardManagementData = session.get(_get(session, "keys.dashboardManageMeta", ""));
  const applicationAdmin =  _get(dashboardManagementData, "is_admin", false);

  const adminPayload = applicationAdmin ? {
    applicationAdmin: applicationAdmin,
    actAs,
  } : {
    actAs,
  };

  const dateFormat = 'DD/MM/YYYY';

  useEffect(() => {
    dispatch(updateApplicationLevelAdmin({
      role: actAs
    }));
  }, [actAs]);

  const dispatchAction = (startDate, endDate) => {
          dispatch(fetchCourses({
        existingRequest: false,
        requestParams: {
          "user_id": session.get([
            session.keys.innovativeMeta,
            "token"
          ]),
          accountId: selectedAccount,
          filterCourseStates: selectedState,
          start_before: startDate,
          ends_after: endDate,
          selectedToggle: "period",
          ...adminPayload
        }
      }));
      }

  const onEndAfterChange = (_, dateString) => {
    const getEndDate = () => {
      if (isEndDatePickerVisible) {
        if (dateString) {
          setEndDate(dateString);
          dispatch(setDateFilterForFetchCourse({ ends_after: dateString }));
          return dateString;
        }
      }
      if (!isEndDatePickerVisible) {
        if (!dateString) {
          setEndDate('');
          dispatch(setDateFilterForFetchCourse({ ends_after: '' }));
          return '';
        }
      }
      dispatch(setDateFilterForFetchCourse({ ends_after: endDate }));
      return endDate;
    }

    dispatchAction(startDate, getEndDate());
    setIsEndDatePickerVisible(false);
  }

  const onStartBeforeChange = (_, dateString) => {
    const getStartDate = () => {
      if (isDatePickerVisible) {
        if (dateString) {
          setStartDate(dateString);
          dispatch(setDateFilterForFetchCourse({ start_before: dateString }));
          return dateString;
        }
      }
      if (!isDatePickerVisible) {
        if (!dateString) {
          setStartDate('');
          dispatch(setDateFilterForFetchCourse({ start_before: '' }));
          return '';
        }
      }
      dispatch(setDateFilterForFetchCourse({ start_before: startDate }));
      return startDate;
    }
    dispatchAction(getStartDate(), endDate);
    setIsDatePickerVisible(false);
  }

  const onStartDateOpenChange = (open) => {
    if (open) {
      setIsDatePickerVisible(true);
    } else {
      setIsDatePickerVisible(false);
    }
  }

  const onEndDateOpenChange = (open) => {
    if (open) {
      setIsEndDatePickerVisible(true);
    } else {
      setIsEndDatePickerVisible(false);
    }
  }

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          colon={false}
          label="Starts before"
          style={{
            marginRight: "7.5px",
          }}
        >
          <DatePicker
            open={isDatePickerVisible}
            onChange={onStartBeforeChange}
            placeholder={"Starts Before"}
            format={dateFormat}
            onOpenChange={onStartDateOpenChange}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          colon={false}
          label="Ends after"
          style={{
            marginLeft: "7.5px",
          }}
        >
          <DatePicker
            open={isEndDatePickerVisible}
            onChange={onEndAfterChange}
            placeholder={"Ends After"}
            format={dateFormat}
            onOpenChange={onEndDateOpenChange}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

CourseDateFilter.defaultProps = {
  wrapperId: 'innovative-term-generic-select',
};

export default memo(CourseDateFilter);
