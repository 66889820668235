export const CUSTOM_ROLE = "custom-role";
export const CANVAS_ROLE = "canvas-role";
export const ADMIN = "Admin";
export const ADVISOR = "Advisor";
export const BY_ROLE = "ByRole";
export const BY_USER_NAME = "ByUsername";
export const CUSTOM = "custom";
export const CANVAS = "canvas";
export const ADD="add";
export const REMOVE="remove";
export const SUCCESS = "success";
export const SAVE_SUCCESS = "Success";
export const TYPE_ADD="add";
export const TYPE_REMOVE="remove";