import {
    DELETE_CONSUMER,
    DELETE_CONSUMER_LOADING,
    FETCH_CONSUMER,
    FETCH_CONSUMER_LOADING,
    FETCH_CONSUMERS,
    FETCH_CONSUMERS_LOADING,
    SAVE_CONSUMER,
    SAVE_CONSUMER_LOADING,
    UPDATE_CONSUMER,
    UPDATE_CONSUMER_LOADING,
    REGEN_KEY,
    REGEN_KEY_LOADING,
    REGEN_KEY_RESET,
    LICENSE_MANAGER_PASSWORD_RESET_LOADING,
    LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING,
    LICENSE_MANAGER_SEND_VERIFICATION_CODE,
    LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE,
    LICENSE_MANAGER_UPDATE_STATUS
} from "../constant/actionTypes";
import ApiServices from "../services/onboarding";
import {reactLocalStorage} from 'reactjs-localstorage';

const consumerReducerDefaultState = {
    selected: {
        isLoading: false,
        error: false,
        response: undefined,
    },
    list: {
        isLoading: false,
        error: false,
        response: {
            data: [],
        },
    },
    created: {
        isLoading: false,
        error: false,
        response: undefined,
    },
    updated: {
        isLoading: false,
        error: false,
        response: undefined,
    },
    deleted: {
        isLoading: false,
        error: false,
        response: undefined
    },
    keygen: {
        isLoading: false,
        response: {
            consumerKey: undefined,
            secretKey: undefined
        }
    },
    reset: {
        isLoading: false,
        response: {
            data: [],
        },
    },
    resetLink: {
        isLoading: false,
        response: {
            data: [],
        },
    },
    sendCode: {
        isLoading: false,
    },
    validateCode: {
        isLoading: false,
    },
    licenseManagerUpdate: {
        isLoading: false,
        tokenValidity: true,
    }
};

const ConsumerReducer = (state = consumerReducerDefaultState, action) => {
    switch (action.type) {
        case FETCH_CONSUMER:
            return {
                ...state,
                selected: action.payload
            };
        case FETCH_CONSUMER_LOADING:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    isLoading: action.payload.isLoading
                }
            };
        case FETCH_CONSUMERS:
            return {
                ...state,
                list: action.payload
            };
        case FETCH_CONSUMERS_LOADING:
            return {
                ...state,
                list: {
                    ...consumerReducerDefaultState.list,
                    isLoading: action.payload.isLoading
                }
            };
        case SAVE_CONSUMER:
            return {
                ...state,
                created: action.payload
            };
        case SAVE_CONSUMER_LOADING:
            return {
                ...state,
                created: {
                    ...state.created,
                    isLoading: action.payload.isLoading
                }
            };
        case UPDATE_CONSUMER:
            return {
                ...state,
                updated: action.payload
            };
        case UPDATE_CONSUMER_LOADING:
            return {
                ...state,
                updated: {
                    ...state.updated,
                    isLoading: action.payload.isLoading
                }
            };
        case DELETE_CONSUMER:
            return {
                ...state,
                deleted: action.payload
            };
        case DELETE_CONSUMER_LOADING:
            return {
                ...state,
                deleted: {
                    ...state.deleted,
                    isLoading: action.payload.isLoading
                }
            };
        case REGEN_KEY:
            return {
                ...state,
                keygen: action.payload
            };
        case REGEN_KEY_LOADING:
            return {
                ...state,
                keygen: {
                    ...state.keygen,
                    isLoading: action.payload.isLoading
                }
            };
        case REGEN_KEY_RESET:
            return {
                ...state,
                keygen: {
                    ...state.keygen,
                    response: action.payload.response
                }
            };
        case LICENSE_MANAGER_PASSWORD_RESET_EMAIL_LOADING:
            return {
                ...state,
                resetLink: {
                    ...state.resetLink,
                    isLoading: action.payload.isLoading
                }
            };
        case LICENSE_MANAGER_PASSWORD_RESET_LOADING:
            reactLocalStorage.remove("subUserVerified");
            reactLocalStorage.remove("subUser");
            return {
                ...state,
                reset: {
                    ...state.reset,
                    isLoading: action.payload.isLoading
                }
            };
        case LICENSE_MANAGER_SEND_VERIFICATION_CODE:
            return {
                ...state,
                sendCode: {
                    ...state.sendCode,
                    isLoading: action.payload.isLoading,
                    verificationId: action.payload.verificationId
                }
            };
        case LICENSE_MANAGER_VALIDATE_VERIFICATION_CODE:
            if(action.payload.data.verified) {
                reactLocalStorage.setObject('subUserVerified', {
                    verified: action.payload.data.verified,
                })
            }
            if(action.payload.data.policy) {
                reactLocalStorage.setObject('subUserPolicy', {
                    policy: action.payload.data.policy,
                })
            }
            return {
                ...state,
                validateCode: {
                    ...state.validateCode,
                    isLoading: action.payload.isLoading
                }
            };
        case LICENSE_MANAGER_UPDATE_STATUS:
            return {
                ...state,
                licenseManagerUpdate: {
                    ...state.licenseManagerUpdate,
                    tokenValidity: action.payload.tokenValidity
                }
            };
        default:
            return state;
    }
};

export default ConsumerReducer;