import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  Row,
  Switch,
  Spin,
  Button,
  Space,
} from 'antd';
import { InfoCircleFilled, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  setMenuSelected,
  setBreadcrumb,
} from '../../../actions/dashboardManagement/layout.action';
import {
    fetchDataPrivacyAccess,
  fetchSettingData,
  resetDataPrivacyAccess,
  saveDataPrivacySettingStatus,
} from '../../../actions/dashboardManagement/dataPrivacyManagement.action';
import _get from 'lodash/get';
import {
  dataPrivacyAccessGranted,
  dataPrivacyAccessLoading,
  makeSettingsDataPrivacyManagerLoading,
  makeSettingsDataPrivacyManagerStatus
} from '../../../selectors/dashboardManagement/dataPrivacyManager.selector';
import menuData from '../../layout/dashboardManagement/menuData';
import ConfirmationModal from './partials/confirmationModal';
import session from '../../../utils/session';

const sectionKey = _get(menuData, 'dataPrivacyManagement.key', '');
const sectionName = _get(menuData, 'dataPrivacyManagement.name', '');

const breadcrumbItems = [
  {
    key: 'admin',
    section: "Admin",
  },
  {
    key: sectionKey,
    section: sectionName,
  },
];

const { Title, Paragraph } = Typography;
const loadingIcon =
  <LoadingOutlined className='dataPrivacyManagement-setting-loading' />;

const DataPrivacyManagementSettings=()=>{
    const [DataPrivacyManager, setDataPrivacyManager] = useState(false);
    const [isDataPrivacyManagerPopupOpen, setIsDataPrivacyManagerPopupOpen] = useState(false);   

    const dispatch = useDispatch();
    const history = useHistory();

    const dataPrivacySettingEnable = useSelector(makeSettingsDataPrivacyManagerStatus);
    const accessGranted = useSelector(dataPrivacyAccessGranted);   
    const isDataLoaded = useSelector(makeSettingsDataPrivacyManagerLoading);
    const accessGrantedLoading= useSelector(dataPrivacyAccessLoading);

    const onRedirectToCredentialManagement = () => {
      history.push("/tenant-admin/dashboard/credentialManagement");
    };

  useEffect(() => {
    dispatch(fetchDataPrivacyAccess());
    dispatch(setMenuSelected(sectionKey));
    dispatch(setBreadcrumb(breadcrumbItems));

    return (() => {
      dispatch(resetDataPrivacyAccess());
    });
  }, []);

  useEffect(() => {
    if (accessGranted && !isDataLoaded) {
      dispatch(fetchSettingData({
        "user_id": session.get([
          session.keys.dashboardManageMeta,
          "token"
        ]),
      }));
    }
  }, [accessGranted]);

    useEffect(() => {
      setDataPrivacyManager(dataPrivacySettingEnable);      
    }, [dataPrivacySettingEnable]);

  const saveSelection = () => {
    dispatch(saveDataPrivacySettingStatus({
      dataPrivacyManagerEnable: DataPrivacyManager
    }));
  };

    const onChangeDataPrivacyManager = checked => {  
      if (accessGranted) {
        setDataPrivacyManager(checked);
        setIsDataPrivacyManagerPopupOpen(checked === true ? true : false);

        if (checked === false) {
          dispatch(saveDataPrivacySettingStatus({
            dataPrivacyManagerEnable: false,
          }));
        }
        return;
      }      
      setDataPrivacyManager(false);
    };

  const renderContent = () => {
    if (accessGranted) {
      return (
        <Card className='switch-card'>

          <Paragraph className='switch-card-text'>
            Grant access to all courses for teachers
          </Paragraph>
          <Row className='switch-section'>
            <span>Disable</span>
            <Switch
              size="small"
              className='status-switch'
              checked={DataPrivacyManager}
              onChange={onChangeDataPrivacyManager}
            />
            <span>Enable</span>
          </Row>
        </Card>
      );
    };

    return (
      <Card>
        <Row justify={"space-between"}>
          <Space>
            <InfoCircleOutlined />
            Enable developer keys in credential management to access data privacy enhancements.
          </Space>

          <Button
          type={"primary"}
          onClick={onRedirectToCredentialManagement}
          >
            Enable developer keys
          </Button>
        </Row>
      </Card>
    );
  };

    return (
      <Spin
        spinning={isDataLoaded || accessGrantedLoading}
        indicator={loadingIcon}
      >
          <div className='student-cluster-settings in-container'>
          <Title level={4}>Data Privacy Management</Title>
          {!accessGrantedLoading && renderContent()}
          <ConfirmationModal
              modalStatus={isDataPrivacyManagerPopupOpen}
              saveSelection={saveSelection}
              closeModal={setIsDataPrivacyManagerPopupOpen}
              setToggle={setDataPrivacyManager}
              title={"Please Confirm"}
            >
              <Paragraph>
                <Row className='info-row'>
                  <InfoCircleOutlined />
                  <Paragraph className='info-row-description'>
                    You are updating data privacy settings in the application.
                  </Paragraph>
                </Row>
              </Paragraph>
            </ConfirmationModal>
          </div>
        </Spin>
      );
}

export default DataPrivacyManagementSettings;
