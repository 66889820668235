import {
  FETCHING_ROLES_TABLE,
  ROLES_TABLE_DATA,
  FETCHING_USER_TABLE,
  USER_TABLE_DATA,
  FETCHING_ROLES,
  ROLES_DATA,
  FETCHING_ROLE_USERS,
  ROLE_USERS_DATA,
  FETCHING_ACCOUNT_HIERARCHY,
  ACCOUNT_HIERARCHY_DATA,
  USER_SEARCH_LOADING,
  USER_SEARCH,
  CUSTOM_USER_SEARCH_LOADING,
  CUSTOM_USER_SEARCH,
  SAVE_ROLES_LOADING,
  SAVE_ROLES,
  ROLE_DATA_SYNCING,
  SYNC_ROLE_DATA,
  ROLE_DATA_DELETING,
  DELETE_ROLE_DATA,
  SET_SAVE_PAYLOAD,
  INDUVIDUAL_ROLE_FETCHING,
  INDUVIDUAL_ROLE_DATA,
  EDIT_ROLE,
  EDIT_ROLE_DATA_LOADING,
  EDIT_ROLE_DATA,
  RESET_PAYLOAD_DATA,
  RESET_INDIVIDUAL_ROLE_DATA,
  RESET_SAVE_DATA,
  FETCHING_COURSE_LIST,
  COURSE_LIST_DATA,
  SAVE_COURSE_PERMISSION_LOADING,
  SAVE_COURSE_PERMISSION,
  SET_COURSE_PAYLOAD,
  RESET_COURSE_PAYlOD_DATA,
  SET_PERMISSION_PAYLOAD,
  FETCHING_COURSE_PERMISSION,
  COURSE_PERMISSION_DATA,
  DELETING_COURSE_PERMISSION,
  DELETE_COURSE_PERMISSION,
  FETCHING_SPECIFIC_COURSES,
  SPECIFIC_COURSES_DATA,
  RESET_PERMISSION_PAYLOAD,
  EDIT_COURSE_PERMISSION_LOADING,
  EDIT_COURSE_PERMISSION,
  USER_MANAGE_STATUS,
  USER_MANAGE_STATUS_LOADING,
  FETCHING_STUDENT_USERS,
  ROLE_STUDENT_DATA,
  RESET_COURSE_PERMISSION_DATA,
  RESET_COURSE_PERMISSION_PAYLOAD,
  CLEAR_SAVE_RESPONCE,
  CLEAR_EDIT_RESPONCE,
  REMOVING_ITEMS_PAYLOAD,
  CLEAR_ROLE_DATA,
  RESET_ROLE_USERS,
  RESET_CUSTOM_SEARCH,
  FETCHING_STUDENT_ROLES,
  ROLE_STUDENT_ROLES_DATA,
  SET_USER_AVAILABILITY,
  PERMISSION_INFO_DATA,
  FETCHING_PERMISSION_INFO,
  CLEAR_PERMISSON_INFO,
  EDIT_CANVAS_STUDENT_DATA,
  EDITING_CANVAS_STUDENT,
  CLEAR_EDIT_CANVAS_STUDENTS,
  SET_ROLE_DEATAILS,
  CLEAR_STUDENT_ROLE_LIST,
} from "../../constant/actionTypes";

const userManagementDefaultState = {
  userTable: {
    isLoading: false,
    error: false,
    data: [],
  },
  roleTable: {
    isLoading: false,
    error: false,
    data: [],
  },
  roleList: {
    isLoading: false,
    error: false,
    data: [],
  },
  roleUsersList: {
    isLoading: false,
    error: false,
    data: [],
  },
  accountHierarchyList: {
    isLoading: false,
    error: false,
    data: [],
  },
  userSearch: {
    isLoading: false,
    error: false,
    data: [],
  },
  customUserSearch: {
    isLoading: false,
    error: false,
    data: [],
  },
  saveRoles: {
    isLoading: false,
    error: false,
    data: {},
  },
  syncRoleData: {
    isLoading: false,
    error: false,
    data: [],
  },
  deleteRoleData: {
    isLoading: false,
    error: false,
    data: {},
  },
  savePayload: {
    data: [],
  },
  individualRole: {
    isLoading: false,
    error: false,
    data: [],
  },
  editRole: {
    data: false,
  },
  editRoleData: {
    isLoading: false,
    error: false,
    data: null,
  },
  courseList: {
    isLoading: false,
    error: false,
    data: [],
  },
  saveCoursePermission: {
    isLoading: false,
    error: false,
    data: {},
  },
  coursePayload: {},
  permissionPayload: {
    data: {},
  },
  coursePermission: {
    isLoading: false,
    error: false,
    data: {},
  },
  deleteCoursePermission: {
    isLoading: false,
    error: false,
    data: "",
  },
  specificCourses: {
    isLoading: false,
    error: false,
    data: [],
  },
  editCoursePermission: {
    isLoading: false,
    error: false,
    data: {},
  },
  userManageStaus: {
    isLoading: false,
    error: false,
    data: {},
  },
  roleStudentList: {
    isLoading: false,
    error: false,
    data: [],
  },
  studentRoles: {
    isLoading: false,
    error: false,
    data: [],
  },
  userSelect: {
    selectUsersEmpty: true,
  },
  permissionsInfo: {
    isLoading: false,
    error: false,
    data: [],
  },
  editCanvasStudent: {
    isLoading: false,
    error: false,
    data: {},
  },
  roleDeatails: {
    role:"",
    roleType:"",
    customName:"",
    student:[],
  },
};

const userManagementReducer = (state = userManagementDefaultState, action) => {
  switch (action.type) {
    case FETCHING_ROLES_TABLE:
      return {
        ...state,
        roleTable: {
          ...state.roleTable,
          ...action.payload,
        },
      };
    case ROLES_TABLE_DATA:
      return {
        ...state,
        roleTable: {
          ...action.payload,
        },
      };
    case FETCHING_USER_TABLE:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          ...action.payload,
        },
      };
    case USER_TABLE_DATA:
      return {
        ...state,
        userTable: {
          ...action.payload,
        },
      };
    case FETCHING_ACCOUNT_HIERARCHY:
      return {
        ...state,
        accountHierarchyList: {
          ...state.accountHierarchyList,
          ...action.payload,
        },
      };
    case ACCOUNT_HIERARCHY_DATA:
      return {
        ...state,
        accountHierarchyList: {
          ...action.payload,
        },
      };
    case FETCHING_ROLES:
      return {
        ...state,
        roleList: {
          ...state.roleList,
          ...action.payload,
        },
      };
    case ROLES_DATA:
      return {
        ...state,
        roleList: {
          ...action.payload,
        },
      };
    case FETCHING_ROLE_USERS:
      return {
        ...state,
        roleUsersList: {
          ...state.roleUsersList,
          ...action.payload,
        },
      };
    case ROLE_USERS_DATA:
      return {
        ...state,
        roleUsersList: {
          ...action.payload,
        },
      };
    case RESET_ROLE_USERS:
      return {
        ...state,
        roleUsersList: {},
      };
    case USER_SEARCH_LOADING:
      return {
        ...state,
        userSearch: {
          ...state.userSearch,
          ...action.payload,
        },
      };
    case USER_SEARCH:
      return {
        ...state,
        userSearch: {
          ...action.payload,
        },
      };
    case CUSTOM_USER_SEARCH_LOADING:
      return {
        ...state,
        customUserSearch: {
          ...state.customUserSearch,
          ...action.payload,
        },
      };
    case CUSTOM_USER_SEARCH:
      return {
        ...state,
        customUserSearch: {
          ...action.payload,
        },
      };
    case RESET_CUSTOM_SEARCH:
      return {
        ...state,
        customUserSearch: {},
      };
    case SAVE_ROLES_LOADING:
      return {
        ...state,
        saveRoles: {
          ...state.saveRoles,
          ...action.payload,
        },
      };
    case SAVE_ROLES:
      return {
        ...state,
        saveRoles: {
          ...action.payload,
        },
      };
    case RESET_SAVE_DATA:
      return {
        ...state,
        saveRoles: {
          isLoading: false,
          error: false,
          data: {},
        },
      };
    case ROLE_DATA_SYNCING:
      return {
        ...state,
        syncRoleData: {
          ...state.syncRoleData,
          ...action.payload,
        },
      };
    case SYNC_ROLE_DATA:
      return {
        ...state,
        syncRoleData: {
          ...action.payload,
        },
      };
    case ROLE_DATA_DELETING:
      return {
        ...state,
        deleteRoleData: {
          ...state.deleteRoleData,
          ...action.payload,
        },
      };
    case DELETE_ROLE_DATA:
      return {
        ...state,
        deleteRoleData: {
          ...action.payload,
        },
      };
    case SET_SAVE_PAYLOAD:
      return {
        ...state,
        savePayload: {
          ...action.payload,
        },
      };
    case RESET_PAYLOAD_DATA:
      return {
        ...state,
        savePayload: {
          data: [],
        },
      };
    case INDUVIDUAL_ROLE_FETCHING:
      return {
        ...state,
        individualRole: {
          ...state.individualRole,
          ...action.payload,
        },
      };
    case INDUVIDUAL_ROLE_DATA:
      return {
        ...state,
        individualRole: {
          ...action.payload,
        },
      };
    case RESET_INDIVIDUAL_ROLE_DATA:
      return {
        ...state,
        individualRole: {
          isLoading: false,
          error: false,
          data: [],
        },
      };
    case EDIT_ROLE:
      return {
        ...state,
        editRole: {
          ...action.payload,
        },
      };
    case EDIT_ROLE_DATA_LOADING:
      return {
        ...state,
        editRoleData: {
          ...state.editRoleData,
          ...action.payload,
        },
      };
    case EDIT_ROLE_DATA:
      return {
        ...state,
        editRoleData: {
          ...action.payload,
        },
      };
    case CLEAR_ROLE_DATA:
      return {
        ...state,
        editRoleData: {},
      };
    case FETCHING_COURSE_LIST:
      return {
        ...state,
        courseList: {
          ...state.courseList,
          ...action.payload,
        },
      };
    case COURSE_LIST_DATA:
      return {
        ...state,
        courseList: {
          ...action.payload,
        },
      };
    case SAVE_COURSE_PERMISSION_LOADING:
      return {
        ...state,
        saveCoursePermission: {
          ...state.saveCoursePermission,
          ...action.payload,
        },
      };
    case SAVE_COURSE_PERMISSION:
      return {
        ...state,
        saveCoursePermission: {
          ...action.payload,
        },
      };
    case SET_COURSE_PAYLOAD:
      return {
        ...state,
        coursePayload: {
          ...state.coursePayload,
          [action.payload.data.account_id]: action.payload.data.data,
        },
      };
    case REMOVING_ITEMS_PAYLOAD:
      const newCoursePayload = { ...state.coursePayload };
      delete newCoursePayload[action.payload];
      return {
        ...state,
        coursePayload: newCoursePayload,
      };
    case SET_PERMISSION_PAYLOAD:
      return {
        ...state,
        permissionPayload: {
          ...action.payload,
        },
      };
    case RESET_PERMISSION_PAYLOAD:
      return {
        ...state,
        permissionPayload: {
          data: {},
        },
      };
    case FETCHING_COURSE_PERMISSION:
      return {
        ...state,
        coursePermission: {
          ...state.coursePermission,
          ...action.payload,
        },
      };
    case COURSE_PERMISSION_DATA:
      return {
        ...state,
        coursePermission: {
          ...action.payload,
        },
      };
    case DELETING_COURSE_PERMISSION:
      return {
        ...state,
        deleteCoursePermission: {
          ...state.deleteCoursePermission,
          ...action.payload,
        },
      };
    case DELETE_COURSE_PERMISSION:
      return {
        ...state,
        deleteCoursePermission: {
          ...action.payload,
        },
      };
    case FETCHING_SPECIFIC_COURSES:
      return {
        ...state,
        specificCourses: {
          ...state.specificCourses,
          ...action.payload,
        },
      };
    case SPECIFIC_COURSES_DATA:
      return {
        ...state,
        specificCourses: {
          ...action.payload,
        },
      };
    case EDIT_COURSE_PERMISSION_LOADING:
      return {
        ...state,
        editCoursePermission: {
          ...state.saveCoursePermission,
          isLoading: action.payload.isLoading,
        },
      };
    case EDIT_COURSE_PERMISSION:
      return {
        ...state,
        editCoursePermission: {
          ...state.saveCoursePermission,
          data: action.payload,
        },
      };
    case USER_MANAGE_STATUS_LOADING:
      return {
        ...state,
        userManageStaus: {
          ...state.userManageStaus,
          ...action.payload,
        },
      };
    case USER_MANAGE_STATUS:
      return {
        ...state,
        userManageStaus: {
          ...action.payload,
        },
      };
    case FETCHING_STUDENT_USERS:
      return {
        ...state,
        roleStudentList: {
          ...state.userManageStaus,
          ...action.payload,
        },
      };
    case ROLE_STUDENT_DATA:
      return {
        ...state,
        roleStudentList: {
          ...action.payload,
        },
      };
    case CLEAR_STUDENT_ROLE_LIST:
      return {
        ...state,
        roleStudentList: {
          isLoading: false,
          error: false,
          data: [],
        },
      };
    case RESET_COURSE_PAYlOD_DATA:
      return {
        ...state,
        coursePayload: {},
      };
    case RESET_COURSE_PERMISSION_DATA:
      return {
        ...state,
        coursePermission: {},
      };
    case RESET_COURSE_PERMISSION_PAYLOAD:
      return {
        ...state,
        permissionPayload: {},
      };
    case CLEAR_SAVE_RESPONCE:
      return {
        ...state,
        saveCoursePermission: {},
      };
    case CLEAR_EDIT_RESPONCE:
      return {
        ...state,
        editCoursePermission: {},
      };
    case FETCHING_STUDENT_ROLES:
      return {
        ...state,
        studentRoles: {
          ...state.studentRoles,
          ...action.payload,
        },
      };
    case ROLE_STUDENT_ROLES_DATA:
      return {
        ...state,
        studentRoles: {
          ...action.payload,
        },
      };
    case SET_USER_AVAILABILITY:
      return {
        ...state,
        userSelect: {
          ...state.studentRoles,
          selectUsersEmpty: action.payload.data,
        },
      };
    case FETCHING_PERMISSION_INFO:
      return {
        ...state,
        permissionsInfo: {
          ...state.permissionsInfo,
          ...action.payload,
        },
      };
    case PERMISSION_INFO_DATA:
      return {
        ...state,
        permissionsInfo: {
          isLoading: action.payload.isLoading,
          data: action.payload.data.data,
          status: action.payload.data.status,
        },
      };
    case CLEAR_PERMISSON_INFO:
      return {
        ...state,
        permissionsInfo: {
          data: {},
          status: ""
        },
      };
    case EDITING_CANVAS_STUDENT:
      return {
        ...state,
        editCanvasStudent: {
          ...state.saveCoursePermission,
          isLoading: action.payload.isLoading,
        },
      };
    case EDIT_CANVAS_STUDENT_DATA:
      return {
        ...state,
        editCanvasStudent: {
          ...state.saveCoursePermission,
          data: action.payload,
        },
      };
    case CLEAR_EDIT_CANVAS_STUDENTS:
      return {
        ...state,
        editCanvasStudent: {
          isLoading: false,
          error: false,
          data: {},
        },
      };
    case SET_ROLE_DEATAILS:
      return {
        ...state,
        roleDeatails: {
          role: action.payload.role,
          roleType: action.payload.roleType,
          customName: action.payload.customName,
          student: action.payload.student,
        },
      };
    default:
      return state;
  }
};

export default userManagementReducer;
