import { notification } from 'antd';
export function compareUpdateUsers(inputArray, outputArray) {

  const inputMap = new Map(inputArray.map((item) => [item.user_id, item]));
  const outputMap = new Map(outputArray.map((item) => [item.id, item]));
  const resultArray = [];

  for (const [id, item] of outputMap) {
    if (!inputMap.has(id)) {
      resultArray.push({
        id: item.id,
        sis_user_id: item.sis_user_id,
        from_base_role: item.from_base_role,
        user_action: "IN",
      });
    }
  }

  for (const [id, item] of inputMap) {
    if (!outputMap.has(id)) {
      resultArray.push({
        id: item.user_id,
        sis_user_id: item.user_sis_id,
        from_base_role: item.from_base_role,
        user_action: "OUT",
      });
    }
  }

  return resultArray;
}

export function getUniqueRoles(data) {
  return [
    "All",
    ...new Set(
      data.map((item) => item.from_base_role.replace("Enrollment", ""))
    ),
  ];
}

export function PemissionFormat(input, consumerId, roleUuid) {
  return {
    consumer_id: consumerId,
    role_uuid: roleUuid,
    account_data: Object.values(input).map((account) => ({
      selectedAccount: account.account_id,
      accountName: account.accountName,
      selectAll: account.selectAll,
      deSelectAll: account.deSelectAll,
      blockedCourse: account.blockedCourse && account.blockedCourse.length
        ? account.blockedCourse.map((course) => ({
          course_id: parseInt(course.course_id),
          course_name: course.course_name,
          account_id: parseInt(course.account_id),
        }))
        : [],
    })),
  };
}

export function filterValidAccounts(data, validAccounts) {
  const validAccountIds = new Set(validAccounts.map((account) => account.id));
  data.account_data = data.account_data.filter((account) =>
    validAccountIds.has(account.selectedAccount)
  );
  return data;
}

export function getChanges(firstArray, secondArray) {
  const firstMap = new Map(firstArray.map((item) => [item.student_id, item]));
  const result = [];
  firstArray.forEach((item) => {
    if (!secondArray.some((el) => el.id === item.student_id)) {
      result.push({ id: item.student_id, removed: true });
    }
  });
  secondArray.forEach((item) => {
    if (!firstMap.has(item.id)) {
      result.push({ id: item.id, removed: false });
    }
  });

  return result;
}

export function transformData(inputData) {
  return inputData.map(item => {
    return {
      title: item.accountName,
      id: item.selectedAccount,
      deSelectAll: item.deSelectAll,
      selectAll: item.selectAll,
      course_data: item.course_data,
    };
  });
}

export const addUniqueItems = (currentItems, newItems) => {
  const itemIds = new Set(currentItems.map(item => item.id));
  const uniqueNewItems = newItems.filter(item => !itemIds.has(item.id));
  return [...currentItems, ...uniqueNewItems];
};

export function filterAndReturnRemovedItems(array1, array2) {
  const idSet = new Set(array2.map(item => item.id));
  const removedItems = array1.filter(item => !idSet.has(item.id));
  const newArray1 = array1.filter(item => idSet.has(item.id));
  return { newArray1, removedItems };
}

export function filterDataBySelectedAccount(selectedAccount, data) {
  const account = data.find(item => item.selectedAccount === selectedAccount);
  return account ? account.course_data : [];
}

export function identifyCourseContradictions(accountHierarchy, accountList) {
  const contradictions = [];

  function checkContradictions(account, parentAccount) {
    const accountId = account.id;
    const currentAccount = accountList[accountId];

    if (!currentAccount) {
      return;
    }

    const parentAccountId = parentAccount ? parentAccount.id : null;
    const parent = parentAccountId ? accountList[parentAccountId] : null;

    if (parent) {
      currentAccount.restricted_courses.forEach((course) => {
        if (
          !parent.restricted_courses.some(
            (pc) => pc.course_id === course.course_id
          )
        ) {
          contradictions.push({
            accountId: currentAccount.account_id,
            courseId: course.course_id,
            courseName: course.course_name,
            type: "Sub account restricted course not in parent restricted courses",
          });
        }
      });

      currentAccount.allowed_courses.forEach((course) => {
        if (
          parent.restricted_courses.some(
            (pc) => pc.course_id === course.course_id
          )
        ) {
          contradictions.push({
            accountId: currentAccount.account_id,
            courseId: course.course_id,
            courseName: course.course_name,
            type: "Sub account allowed course in parent restricted courses",
          });
        }
      });

      parent.allowed_courses.forEach((course) => {
        if (
          currentAccount.restricted_courses.some(
            (rc) => rc.course_id === course.course_id
          )
        ) {
          contradictions.push({
            accountId: parent.account_id,
            courseId: course.course_id,
            courseName: course.course_name,
            type: "Parent account allowed course in sub account restricted courses",
          });
        }
      });
    }

    account.children.forEach((child) => checkContradictions(child, account));
  }

  accountHierarchy.forEach((rootAccount) =>
    checkContradictions(rootAccount, null)
  );

  return contradictions;
}

export function showContradictionsNotification(contradictions) {
  const description = contradictions.map(contradiction => (
    `Account ID: ${contradiction.accountId}, Course ID: ${contradiction.courseId}, Course Name: ${contradiction.courseName}, Type: ${contradiction.type}`
  )).join('\n');

  notification.info({
    message: "Courses Conflict",
    description
  });
}

export function getAccountChanges(initialData, updatedData) {
  const initialIds = Object.keys(initialData).map((key) => parseInt(key));
  const updatedIds = updatedData.map((item) => item.id);

  const addedItems = updatedData.filter(
    (item) => !initialIds.includes(item.id)
  );
  const removedItems = initialIds.filter((id) => !updatedIds.includes(id));

  return {
    addedItems,
    removedItems,
  };
}

export function checkCoursesStatus(accounts) {
  for (const accountKey in accounts) {
    const account = accounts[accountKey];
    if (
      account.restricted_courses.length === 0 &&
      account.allowed_courses.length === 0 &&
      account.selectAll === false &&
      account.deSelectAll === false) {
      return false;
    }
  }
  return true;
}
