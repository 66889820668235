import React, { Component, Fragment } from 'react';
import { DollarSign, Tag, ShoppingBag, MessageCircle, MinusCircle, ThumbsUp, Briefcase, MoreHorizontal, Send, Activity, Anchor, Compass, Cpu, Slack, Umbrella, Box, Book } from 'react-feather';
import Modal from 'react-responsive-modal';
import seven from '../../../assets/images/user-card/7.jpg';
import Breadcrumb from '../../common/breadcrumb';
import { Calendar, Users, MessageSquare, Navigation, Layers, Server, ArrowDown, ArrowUp } from 'react-feather';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import one from '../../../assets/images/user-card/1.jpg';
import three from '../../../assets/images/user-card/3.jpg';
import anglicanimage from '../../../assets/images/anglican.png';
import accimage from '../../../assets/images/acclogo.png';
import two from '../../../assets/images/user-card/2.jpg';
import sixteen from '../../../assets/images/avtar/16.jpg';
import eleven from '../../../assets/images/avtar/11.jpg';
import four from '../../../assets/images/avtar/4.png';
import url from '../../../assets/images/user/7.jpg';
import Datatable from './datatable';
import ApiServices from './../../../services/tentacle';

import './../../../assets/css/class-profile.css';
import helper from '../common/helper';

import addHeatmap from 'highcharts/modules/heatmap'
import addStock from "highcharts/modules/stock";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
var moment = require('moment-timezone');

addHeatmap(Highcharts)
addStock(Highcharts)
HC_more(Highcharts);

const preloaderGif = `${process.env.REACT_APP_S3_BUCKET_URL}/tentacle/public/tentacle_loader.gif`;

class AccClassProfile extends Component {
  /**
   * Comment to trigger the node
   */
  constructor(props) {
    super(props);
    var queryx = new URLSearchParams(this.props.location.search);
    this.toggle = this.toggle.bind(this);
    this.setModelopen = this.setModelopen.bind(this);
    this.state = {
      dropdownOpen: false,
      isFilterCoursesLoaded: false,
      isOpen: false,
      url: "",
      isLoaded: true,
      items: [],
      isSubChartLoaded: false,
      subChartItem: [],
      isLateSubChartLoaded: false,
      lateSubmission: [],
      tempStudentName: "",
      studentName: "",
      avatar: four,
      isFilterTermsLoaded: false,
      isFilterYearLevelsLoaded: false,
      isFilterStudentsLoaded: false,
      isFilterPatLoaded: false,
      isFilterNaPlanLoaded: false,
      filterTermValue: queryx.get('year') ? queryx.get('year')  : 0,
      filterCourseValue: 0,
      filterStudentValue: 0,
      filterYearLevelValue: 0,
      filterActivityByTimeMode: 'M',
      filterTerms: [],
      filterYearLevels: [],
      filterStudents: [],
      filterCourses: [],
      filterPats: [],
      filterNaPlan: [],
      pat: [],
      naplan: [],
      isFeedbacksLoaded: false,
      isStudentStatsLoaded: false,
      readyToSend: [],
      needAttention: [],
      feedbacks: [],
      isMarksByAssignmentsLoaded: false,
      marksByAssignments: [],
      isStudentNameLoaded: false,
      loader: 'Loading...',
      twentiethPercentile: 0,
      isWorkloadLoaded: false,
      workLoadItem: [],
      ninetiethPercentile: 0,
      logged_in: queryx.get('token'),
      token: 'Bearer ' + queryx.get('t'),
      isActivityByTimeLoaded: false,
      activityByTimeData: [],
      isFirstTimeLoad: true,
      // isFirstTimePatLoad:true,
      // isFirstTimeNaplanLoad:true,
      isEngagementPerformanceLoaded: false,
      engagementPerformanceData: [],
      SubmissionModelOpen: false,
      onCloseModal: false,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: [],
      activity_by_time_colors: ["#219653", "#FF5C5C", "#0D1D71", "#d43504"],
      colors: ["#164a90", "#3b3b8a", "#a80c03", "#d43504"],
      subjectassignmentComparisonColors: ["#1d6a38", "#33a759", "#47dd7c", "#0d1d71", "#295790", "#5267db", "#f43737", "#f55c5d", "#f78d8d", "#f67706", "#f68e33", "#f0a767", "#5b5a5a", "#787878", "#aba1a1"],
      submission_colors: ["#ff8800", "#228a3b", "#db043a", "#d43504"],

      isSubChartLoading: true,
      isStudentStatsLoading: true,
      isLateSubChartLoading: true,
      isMarksByAssignmentsLoading: true,
      isStudentNameLoading: true,
      isStudentNameLoading: true,
      isFeedbacksLoading: true,
      isWorkloadLoading: true,
      isActivityByTimeLoading: true,
      isEngagementPerformanceLoading: true,
      isPatLoading: true,
      isNaPlanLoading: true,
      isSubChartDetailsLoading: true,

      numberOfPatCheked: 0,
      numberOfNaplanChecked: 0,
      engagementPerformanceMergedData: [],
      role_access: queryx.get('access')
    };
  }

  readUrl(event) {
  }

  filterTerms() {
    this.setState({
      isFilterCoursesLoaded: false,
      isGoButtonDisabled: true
    });

    const params = { logged_in: this.state.logged_in, observer: false, access: this.state.role_access };
    ApiServices.AccFilterService.classProfileFilters(params, this.state.token).then(res => res.json()).then((result) => {
      this.setState({
        filterData: result.data
      }, () => {
        if (result.data.years.length > 0) {
          this.setState({
            isFilterTermsLoaded: true,
            filterTermValue: result.data.years[0].id,
            filterTerms: result.data.years
          }, () => {
            this.changeTermValue(this.state.filterTermValue);
          });
        } else {
          this.handleNoDataInFilters();
        }
      });
    }, (error) => {
      this.setState({
        isFilterTermsLoaded: true,
        error
      });
    });
  }

  handleNoDataInFilters() {
    this.setState({
      isFilterTermsLoaded: true,
      isFilterSemestersLoaded: true,
      isFilterCoursesLoaded: true,
      isStudentNameLoaded: true,
      filterTerms: [{ name: "No Data", id: "" }],
      filterSemesters: [{ name: "No Data", id: "" }],
      filterCourses: [{ name: "No Data", id: "" }],
    }, () => {
      this.setState({
        isSubChartLoading: false,
        isStudentStatsLoading: false,
        isLateSubChartLoading: false,
        isMarksByAssignmentsLoading: false,
        isStudentNameLoading: false,
        isStudentNameLoading: false,
        isFeedbacksLoading: false,
        isWorkloadLoading: false,
        isActivityByTimeLoading: false,
        isEngagementPerformanceLoading: false,
        isPatLoading: false,
        isNaPlanLoading: false,
        isSubChartDetailsLoading: false,
      });
    });
  }

  changeSemesterValue(value) {
    this.setState({ filterSemesterValue: value }, () => {
      this.filterCourses();
    });
  }

  changeTermValue(value) {
    this.setState({ filterTermValue: value }, () => {
      this.filterSemestersFunc();
    });
  }

  changeCourseValue(value) {
    this.setState({
      filterCourseValue: value,
      filterPatValue: '',
      filterNaPlanValue: ''
    }, () => {
      this.filterPatFunc();
      this.filterNaPlanFunc();

      if (this.state.isFirstTimeLoad) {
        this.setState({
          isFirstTimeLoad: false
        });
        this.redrawCharts();
      }
    });
  }

  changePatValue(value) {
    this.setState({
      isPatLoaded: false,
      filterPatValue: value
    }, () => {
      this.chart_pat();
    });
  }

  changeNaPlanValue(value) {
    this.setState({
      isNaPlanLoaded: false,
      filterNaPlanValue: value
    }, () => {
      this.chart_naplan();
    });
  }

  activityByTimeMode = (mode) => {
    this.setState({
      isActivityByTimeLoaded: false,
      filterActivityByTimeMode: mode
    }, () => {
      this.chart_activity_by_time();
    });
  }

  redrawCharts() {
    this.setState({
      isSubChartLoaded: false,
      isStudentStatsLoaded: false,
      isLateSubChartLoaded: false,
      isMarksByAssignmentsLoaded: false,
      isStudentNameLoaded: false,
      isStudentNameLoaded: false,
      isFeedbacksLoaded: false,
      isWorkloadLoaded: false,
      isActivityByTimeLoaded: false,
      isEngagementPerformanceLoaded: false,
      isPatLoaded: false,
      isNaPlanLoaded: false,

      isSubChartLoading: true,
      isStudentStatsLoading: true,
      isLateSubChartLoading: true,
      isMarksByAssignmentsLoading: true,
      isStudentNameLoading: true,
      isStudentNameLoading: true,
      isFeedbacksLoading: true,
      isWorkloadLoading: true,
      isActivityByTimeLoading: true,
      isEngagementPerformanceLoading: true,
      isPatLoading: true,
      isNaPlanLoading: true,
      isSubChartDetailsLoading: true,

    });

    this.charts_submissiondata();
    this.table_studentStats();
    this.chart_marks_by_assignment();
    this.basic_info();
    this.charts_workload();
    //this.table_lateSubmissions();
    this.chart_activity_by_time();
    this.engagement_performance_chart();

    this.setState({
      intervalPATID: setInterval(() => {
        if (this.state.filterPatValue || this.state.numberOfPatCheked >= 2) {
          clearInterval(this.state.intervalPATID);
          this.chart_pat();
          this.setState({
            numberOfPatCheked: 0
          });
        }
        this.setState({
          numberOfPatCheked: this.state.numberOfPatCheked + 1
        });
      }, 1500),
      intervalNAPLanID: setInterval(() => {
        if (this.state.filterNaPlanValue || this.state.numberOfNaplanChecked >= 2) {
          clearInterval(this.state.intervalNAPLanID);
          this.chart_naplan();
          this.setState({
            numberOfNaplanChecked: 0
          });
        }
        this.setState({
          numberOfNaplanChecked: this.state.numberOfNaplanChecked + 1
        })
      }, 1500)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalPATID);
    clearInterval(this.state.intervalNAPLanID);
  }

  basic_info() {
    const params = { logged_in: this.state.logged_in, course: this.state.filterCourseValue, access: this.state.role_access };
    ApiServices.AccClassProfileService.profile(params, this.state.token).then(res => res.json()).then((result) => {
      if(helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result){
        this.setState({
          isStudentNameLoaded: true,
          studentName: result.data.result.name,
          avatar: result.data.result.avatar_url
        });
      }
    }, (error) => {
      this.setState({
        isStudentNameLoaded: true,
        studentName: "Student Nme",
        avatar: four,
        error
      });
    });
  }

  // filterYearLevel(term=null){
  //   const requestOptions = {
  //     credentials: "include",
  //     method: 'GET',
  //     headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' }
  //   };

  //   if(term==null){
  //     var term = this.state.filterTermValue;
  //   }

  //   fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/yearlevel/"+ this.state.logged_in +"/"+term,requestOptions)
  //     .then(res => res.json())
  //     .then((result) => {
  //       this.setState({
  //         isFilterYearLevelsLoaded: true,
  //         filterYearLevels:result.data.result 
  //       });

  //       if(result.length >0){
  //         this.setState({ 
  //           filterYearLevelValue:result.data.result[0].id
  //         });
  //         this.filterStudents(result.data.result[0].id);
  //       }
  //     }, (error) => {
  //       this.setState({
  //         isFilterYearLevelsLoaded: true,
  //         error
  //       });
  //     });
  // }

  filterSemestersFunc() {
    this.setState({
      filterYearLevels: [],
      ilterCourseValue: 0,
      filterCourses: [],
      filterStudentValue: 0,
      filterStudents: [],
      studentName: "",
      filterYearLevelValue: 0,
      isGoButtonDisabled: true
    }, () => {
      this.setState({
        filterSemesters: this.state.filterData.semesters,
        filterSemesterValue: 1
      }, () => {
        this.setState({
          isFilterSemestersLoaded: true,
        }, () => {
          this.changeSemesterValue(this.state.filterSemesterValue);
        })
      });
    });
  }

  //  filterStudents(yearlevel=null){
  //     const requestOptions = {
  //       credentials: "include",
  //       method: 'GET',
  //       headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' } 

  //   };

  //   if(!yearlevel==null){
  //     const yearlevel = this.state.filterYearLevelValue;
  //   }

  //   const term = this.state.filterTermValue;

  //   //getting filter data
  //   fetch(process.env.REACT_APP_APIURL+"studentprofile/filters/teachers/"+ this.state.logged_in +"/"+term+"/"+yearlevel,requestOptions)
  //   .then(res => res.json())
  //   .then(
  //     (result) => {
  //       this.setState({
  //         isFilterStudentsLoaded: true,
  //         filterStudents:result.data.result, 

  //       });


  //       if(result.length >0){
  //         this.setState({filterStudentValue:result.data.result[0].id,
  //           studentName:result.data.result[0].name});
  //         this.filterCourses(result.data.result[0].id);
  //       }


  //     },

  //     (error) => {
  //       this.setState({
  //         isFilterStudentsLoaded: true,
  //         error
  //       });
  //     }
  //     ) ;

  //  }

  filterCourses(term = null) {
    this.setState({
      isFilterCoursesLoaded: false
    });

    this.setState({
      isGoButtonDisabled: true
    });

    var courses = this.state.filterData.courses[this.state.filterTermValue][this.state.filterSemesterValue];
    if (courses && courses.length > 0) {
      this.setState({
        isFilterCoursesLoaded: true,
        filterCourses: courses,
        filterCourseValue: courses[0].id,
        isGoButtonDisabled: false
      }, () => {
        this.changeCourseValue(this.state.filterCourseValue);
      });
    } else {
      this.setNoCourses();
    }
  }

  setNoCourses = () => {
    this.setState({
      isFilterCoursesLoaded: true,
      filterCourses: [{ id: "", name: "No Data" }],
      isGoButtonDisabled: true
    }, () => {
      this.setState({
        isSubChartLoading: false,
        isStudentStatsLoading: false,
        isLateSubChartLoading: false,
        isMarksByAssignmentsLoading: false,
        isStudentNameLoading: false,
        isStudentNameLoading: false,
        isFeedbacksLoading: false,
        isWorkloadLoading: false,
        isActivityByTimeLoading: false,
        isEngagementPerformanceLoading: false,
        isPatLoading: false,
        isNaPlanLoading: false,
        isSubChartDetailsLoading: false,
      })
    });
  }

  filterPatFunc = () => {
    const params = { courseId: this.state.filterCourseValue, year: this.state.filterTermValue, access: this.state.role_access }
    ApiServices.AccClassProfileService.latestPatFilters(params, this.state.token).then(res => res.json()).then((result) => {
      if (helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result.length > 0) {
        this.setState({
          isFilterPatLoaded: true,
          filterPats: result.data.result,
          filterPatValue: result.data.result[0]
        }, () => {
          // if(this.state.isFirstTimePatLoad){
          //   this.setState({
          //     isFirstTimePatLoad: false
          //   })
          //   this.chart_pat();
          // }
        });
      } else {
        this.setState({
          isFilterPatLoaded: true,
          filterPats: ["No Data"],
          filterPatValue: "",
          isPatLoaded: true,
          // isFirstTimePatLoad: false,
          pat: []
        }, () => {
          this.drawPatChart();
        });
      }
    });
  }

  filterNaPlanFunc = () => {
    const params = { courseId: this.state.filterCourseValue, year: this.state.filterTermValue, access: this.state.role_access }
    ApiServices.AccClassProfileService.latestNaPlanFilters(params, this.state.token).then(res => res.json()).then((result) => {
      if (helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.result) && result.data.result.length > 0) {
        this.setState({
          isFilterNaPlanLoaded: true,
          filterNaPlan: result.data.result,
          filterNaPlanValue: result.data.result[0]
        }, () => {
          // if(this.state.isFirstTimeNaplanLoad){
          //   this.setState({
          //     isFirstTimeNaplanLoad: false
          //   })
          //   this.chart_naplan();
          // }
        });
      } else {
        this.setState({
          isFilterNaPlanLoaded: true,
          filterNaPlan: ["No Data"],
          filterNaPlanValue: "",
          isNaPlanLoaded: true,
          // isFirstTimeNaplanLoad: false,
          naplan: [],
        }, () => {
          this.drawNaPlanChart();
        });
      }
    });
  }

  // table_lateSubmissions(){
  //   const courseId  = this.state.filterCourseValue;
  // //////alert("don");
  //   const requestOptions = {
  //     credentials: "include",
  //     method: 'POST',
  //     headers:{  'Authorization': this.state.token,'Content-Type': 'application/json' },
  //     body: JSON.stringify({ xxxX:"",courseId: courseId,studentId : this.state.filterStudentValue})
  // };
  //   //getting submission data
  //   fetch(process.env.REACT_APP_APIURL+"teacherprofile/late_submission_table/data/",requestOptions)
  //   .then(res => res.json())
  //   .then(
  //     (result) => {
  //       this.setState({
  //         isFeedbacksLoaded: true,
  //         feedbacks: result.data.result  
  //       });
  //     },
  //     (error) => {
  //       this.setState({
  //         isFeedbacksLoaded: true,
  //         error
  //       });
  //     }
  //   ) ;
  //  }

  engagement_performance_chart() {
    const courseIds = [this.state.filterCourseValue];

    const params = { courseId: this.state.filterCourseValue, term: this.state.filterTermValue, semester: this.state.filterSemesterValue, access: this.state.role_access };
    ApiServices.AccClassProfileService.engagementPerformance(params, this.state.token).then(res => res.json()).then((result) => {
      if (result.data.response && result.data.response.status !== 0) {
        this.setState({
          engagementPerformanceData: result.data.response.body,
        }, () => {
          var mergedArray = [];
          this.state.engagementPerformanceData.data.map(point => {
            var itemIndex = mergedArray.findIndex(item => point.x === item.x && point.y === item.y);
            if (itemIndex <= -1) {
              mergedArray.push(point);
            } else {
              mergedArray[itemIndex].name = mergedArray[itemIndex].name.concat(', ', point.name);
            }
          });

          this.setState({
            engagementPerformanceMergedData: mergedArray,
          }, this.drawEngagementPerformanceChart);
        });
      } else {
        this.setState({
          isEngagementPerformanceLoading: false
        });
      }
    }, (error) => {
      this.setState({
        isEngagementPerformanceLoaded: true,
        error
      });
    });
  }

  drawEngagementPerformanceChart = () => {
    this.setState({
      engagement_performance: {
        chart: {
          type: 'scatter',
          zoomType: 'xy'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'ENGAGEMENT SCORE'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          title: {
            text: 'PERFORMANCE SCORE'
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)'
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            }
          }
        },
        tooltip: {
          formatter: function (tooltip) {
            // if(gState.isEngagementPerformanceLoaded){
            if (this.point.name != undefined) {
              //  return "Time Slot : <b>" + this.point.category + "<b/><br/>Count : <b>" + this.y + " ";
              return "Student Name : " + this.point.name + "<br/>Engagement : " + this.point.x + "<br/>Performance : " + this.point.y;
              //return "Student Name : "+gState.engagementPerformanceData.categories[this.series.data.indexOf( this.point )-1]+"<br/>Engagement : "+this.point.x+"<br/>Performance : "+this.point.y;
            } else {
              return false;
            }
            // }else{
            // return "";
            // }
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          type: 'line',
          name: '',
          color: '#E46E6E',
          data: this.state.engagementPerformanceData.regression,
          marker: {
            enabled: false
          }
        }, {
          name: '',
          color: this.state.colors[0],
          data: this.state.engagementPerformanceMergedData
        }]
      }
    }, () => {
      this.setState({
        isEngagementPerformanceLoaded: true
      });
    });
  }

  chart_marks_by_assignment() {
    const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, term: this.state.filterTermValue, semester: this.state.filterSemesterValue, access: this.state.role_access };
    ApiServices.AccClassProfileService.gradeComparisanByAssignment(params, this.state.token).then(res => res.json()).then((result) => {
      if (result.data.response && result.data.response.status !== 0) {
        this.setState({
          marksByAssignments: result.data.response.body,
        }, () => {
          this.drawGradeComparisonByAssignment();
        });
      } else {
        this.setState({
          isMarksByAssignmentsLoading: false
        });
      }
    }, (error) => {
      this.setState({
        isMarksByAssignmentsLoaded: true,
        error
      });
    });
  }

  drawGradeComparisonByAssignment = () => {
    this.setState({
      gradesComparisonChartOptions: {
        title: {
          text: ''
        },
        colors: this.state.subjectassignmentComparisonColors,
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 2000);
            }
          },
          type: "column",
          animation: true,
          style: {
            // fontFamily: "Roboto",
            color: "#444444"
          }
        },
        plotOptions: {
          series: {
            stacking: "percent",
            linewidth: 6,
            borderRadius: 1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.marksByAssignments.categories,
          title: {
            text: 'ASSIGNMENT NAME'
            //align: 'center'
          },
          min: 0,
          max: this.state.marksByAssignments.categories.length > 16 ? 16 : this.state.marksByAssignments.categories.length - 1,
          scrollbar: {
            enabled: true
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'STUDENT PERCENTAGE'
            //align: 'center'
          }
        },
        legend: {
          enabled: true
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
          shared: true
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        series: this.state.marksByAssignments.data
      }
    }, () => {
      this.setState({
        isMarksByAssignmentsLoaded: true
      });
    });
  }

  chart_pat() {
    if (!this.state.filterPatValue) {
      this.setState({
        isPatLoaded: true,
        pat: []
      }, () => {
        this.drawPatChart();
        return false;
      });
    }

    const params = {
      courseId: this.state.filterCourseValue, //3787 
      year: this.state.filterTermValue,
      testId: this.state.filterPatValue,
      access: this.state.role_access
    };

    //getting submission data
    ApiServices.AccClassProfileService.latestPatMarks(params, this.state.token).then(res => res.json()).then((result) => {
      if (helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.response)) {
        this.setState({
          pat: result.data.response.body,
        }, () => {
          this.drawPatChart();
        });
      } else {
        this.setState({
          isPatLoaded: true,
          pat: []
        });
      }
    }, (error) => {
      this.setState({
        isPatLoaded: true,
        error
      });
    });
  }

  drawPatChart = () => {
    this.setState({
      patchartOptions: {
        chart: {
          type: 'boxplot'
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.state.pat.categories,
          title: {
            text: 'SKILL'
          }
        },
        plotOptions: {
          boxplot: {
            lineWidth: 2
            // medianColor: '#0C5DA5',
            //  medianDashStyle: 'ShortDot',
            //  medianWidth: 3,
            // stemColor: '#A63400',
            //  stemDashStyle: 'dot',
            //  stemWidth: 1,
            //whiskerColor: '#3D9200',
            //  whiskerLength: '20%',
            //  whiskerWidth: 3
          }
        },
        yAxis: {
          title: {
            text: 'SCALED SCORE'
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'PAT',
          color: "#002b80",
          data: this.state.pat.data
        }, {
          name: 'Class Average',
          color: "#801100",
          type: 'scatter',
          data: this.state.pat.scatterData,
          tooltip: {
            pointFormat: 'Class Average: {point.y}'
          }
        }]
      }
    }, () => {
      this.setState({
        isPatLoaded: true
      });
    });
  }

  chart_naplan() {
    if (!this.state.filterNaPlanValue) {
      this.setState({
        isNaplanLoaded: true,
        naplan: []
      }, () => {
        this.drawNaPlanChart();
        return false;
      });
    }

    const params = {
      courseId: this.state.filterCourseValue, //3787
      year: this.state.filterTermValue, //2019
      testId: this.state.filterNaPlanValue,
      access: this.state.role_access
    };

    //getting submission data
    ApiServices.AccClassProfileService.latestNaPlanMarks(params, this.state.token).then(res => res.json()).then((result) => {
      if (helper.isObject(result) && helper.isObject(result.data) && helper.isObject(result.data.response)) {
        this.setState({
          naplan: result.data.response.body
        }, () => {
          this.drawNaPlanChart();
        });
      } else {
        this.setState({
          isNaplanLoaded: true,
          naplan: [],
        });
      }
    }, (error) => {
      this.setState({
        isNaPlanLoaded: true,
        error
      });
    });
  }

  drawNaPlanChart = () => {
    let gThis = this.state;
    this.setState({
      naPlanchartOptions: {
        chart: {
          type: 'boxplot'
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.state.naplan.categories,
          title: {
            text: 'SKILL'
          }
        },
        plotOptions: {
          boxplot: {
            lineWidth: 2
            // medianColor: '#0C5DA5',
            //  medianDashStyle: 'ShortDot',
            //  medianWidth: 3,
            // stemColor: '#A63400',
            //  stemDashStyle: 'dot',
            //  stemWidth: 1,
            //whiskerColor: '#3D9200',
            //  whiskerLength: '20%',
            //  whiskerWidth: 3
          }
        },
        yAxis: {
          title: {
            text: 'SCALED SCORE'
          },
          min: 0,
          max: 1100
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'NAPLAN',
          color: "#002b80",
          data: this.state.naplan.data,
          tooltip: {
            headerFormat: '<em>{point.key}</em><br/>',
            pointFormatter: function () {
                return `<span style="color:
                    ${this.series.name} </b><br/>
                    Maximum: ${(this.high)} <br/>
                    Upper quartile: ${(this.q3)}<br/>
                    Median: ${(this.median)} <br/>
                    Lower quartile: ${(this.q1)} <br/>
                    Minimum: ${(this.low)} <br/>
                    Total Student: ${(gThis.naplan.studentCount[this.category])}`
            }
          }
        }, {
          name: 'Class Average',
          color: "#801100",
          type: 'scatter',
          data: this.state.naplan.scatterData,
          tooltip: {
            pointFormat: 'Class Average: {point.y}'
          }
        }]
      }
    }, () => {
      this.setState({
        isNaPlanLoaded: true
      });
    });
  }

  chart_activity_by_time() {
    const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, year: this.state.filterTermValue, mode: this.state.filterActivityByTimeMode, semester: this.state.filterSemesterValue, access: this.state.role_access };
    ApiServices.AccClassProfileService.activityByTime(params, this.state.token).then(res => res.json()).then((result) => {
      if (result.data.response && result.data.response.status !== 0) {
        this.setState({
          activityByTimeData: result.data.response.body,
        }, () => {
          this.drawActivityByTimeChart();
        });
      } else {
        this.setState({
          isActivityByTimeLoading: false
        });
      }
    }, (error) => {
      this.setState({
        isActivityByTimeLoaded: true,
        error
      });
    });
  }

  drawActivityByTimeChart() {
    this.setState({
      activity_by_time: {
        title: {
          text: ''
        },
        colors: this.state.activity_by_time_colors,
        chart: {
          type: "column",
          animation: true,
          style: {
            // fontFamily: "Roboto",
            color: "#444444"
          }
        },
        legend: {
          enabled: true
        },
        plotOptions: {
          column: {
            //   stacking: 'percent',
            //   linewidth: 6,
            //   borderRadius: 1,
            //   pointPadding: 0,
            //   groupPadding: 0.2,
            // pointWidth: 20,
            //   padding: 5,
          },
          series: {
            stacking: 'normal',
            linewidth: 6,
            borderRadius: 1,
            // pointWidth: 40,
            marker: {
              radius: 9
            },
            lineWidth: 5
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.activityByTimeData.categories,
          title: { text: "TIME SLOT" },
          // min: 6,
          min: 0,
          max: this.state.activityByTimeData.categories.length > 5 ? 5 : this.state.activityByTimeData.categories.length - 1,
          scrollbar: {
            enabled: true
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          // formatter: function (tooltip) {
          //   console.log(this);
          //   // return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during " + this.state.activityByTimeData.categories[this.point.x] + " days";
          // }
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          shared: true
        },
        yAxis: {
          tickInterval: 1,
          minRange: 1,
          allowDecimals: false,
          startOnTick: true,
          endOnTick: true,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: { text: "NUMBER OF ACTIVITIES" }
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        // series: [{
        //   name: "Count",
        //   data: this.state.activityByTimeData.data,
        //   animation: {
        //     duration: 2000
        //     // Uses Math.easeOutBounce
        //     // easing: 'easeOutBounce'
        //   }
        // }
        // ]
        series: this.state.activityByTimeData.data,
      }
    }, () => {
      this.setState({
        isActivityByTimeLoaded: true
      });
    });
  }

  table_studentStats() {
    const courseIds = [];

    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds[i] = this.state.filterCourses[i].id;
    }

    const params = { courseId: this.state.filterCourseValue, studentId: this.state.filterStudentValue, term: this.state.filterTermValue, semester: this.state.filterSemesterValue, access: this.state.role_access };
    ApiServices.AccClassProfileService.studentStats(params, this.state.token).then(res => res.json()).then((result) => {
      if (helper.isObject(result) && helper.isObject(result.data)) {
        this.setState({
          isStudentStatsLoaded: true,
          readyToSend: result.data.result.readyToSend,
          needAttention: result.data.result.needAttention,
          twentiethPercentile: result.data.twenty,
          ninetiethPercentile: result.data.ninety,
          tableHeaderColumns: [
            { width: 80, Header: <b>ID</b>, accessor: "id", Cell: false, style: { "fontSize": '12px' } },
            { width: 150, Header: <b>Student Name</b>, accessor: "name", Cell: false, style: { "fontSize": '12px' } },
            { width: 160, Header: <b>Course</b>, accessor: "course", Cell: false, style: { "fontSize": '12px' } },
            { Header: <b>Performance</b>, accessor: "finalscore", Cell: false, style: { "fontSize": '12px', textAlign: 'center' } },
            { Header: <b>Engagement</b>, accessor: "engagement", Cell: false, style: { "fontSize": '12px', textAlign: 'center' } },
          ]
        });
      }
    }, (error) => {
      this.setState({
        isStudentStatsLoaded: true,
        error
      });
    });
  }

  charts_workload() {
    this.setState({ workLoadItem: [] });
    const courseIds = [];

    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds[i] = this.state.filterCourses[i].id;
    }

    const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.logged_in, yearLevel: this.state.filterStudentValue, access: this.state.role_access };
    try {
      ApiServices.AccClassProfileService.workLoad(params, this.state.token)
        .then(res => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(result => {
          if (result.data.result && result.data.result.data.hasOwnProperty('series') && result.data.result.data.series.length > 0) {
            this.setState({
              workLoadItem: result.data.result
            }, () => {
              this.drawWorkLoadChart(this.state.workLoadItem);
            });
          }
        })
        .catch(error => {
          console.error('Error fetching workload data:', error);
        });
    } catch (error) {
      console.error('Error in charts_workload:', error);
    }
  }


  drawWorkLoadChart = (workLoadItem) => {
    this.setState({
      workloadChartOptions: {
        title: {
          text: ''
        },
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },
        colorAxis: {
          min: 0,
          minColor: "#ffffff",
          maxColor: this.state.colors[0]
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.state.workLoadItem.data["x-categories"],
          title: {
            text: "NEXT FOUR WEEKS"
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.workLoadItem.data["y-categories"],
          title: null,
          reversed: true,
          title: {
            text: "COURSE NAME"
          }
        },
        credits: {
          enabled: false
        },
        accessibility: {
          point: {
            descriptionFormatter: function (point) {

            }
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },
        tooltip: {
          formatter: function () {
            if (this.point.value > 0) {
              return `${workLoadItem.data["x-categories"][this.point.x]}<br/>${workLoadItem.data["y-categories"][this.point.y]}<br/>${workLoadItem["tooltip"][this.point.y][this.point.x].toString()}`;
            } else {
              return false;
            }
          }
        },
        series: [{
          name: 'Work Load',
          borderWidth: 1,
          data: this.state.workLoadItem.data["series"],
          dataLabels: {
            enabled: true,
            color: '#000000'
          }
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 400
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter: function () {
                    return this.value.charAt(0);
                  }
                }
              }
            }
          }]
        }
      }
    }, () => {
      this.setState({
        isWorkloadLoaded: true
      });
    });
  }

  charts_submissiondata() {
    const courseIds = [];

    for (var i = 0; i < this.state.filterCourses.length; i++) {
      courseIds[i] = this.state.filterCourses[i].id;
    }

    const params = { term: this.state.filterTermValue, courseIds: courseIds, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue, semester: this.state.filterSemesterValue, access: this.state.role_access }
    ApiServices.AccClassProfileService.submissionData(params, this.state.token).then(res => res.json()).then((result) => {
      if (result.data.response && result.data.response.status !== 0) {
        this.setState({
          subChartItem: result.data.response.body,
          isLateSubChartLoaded: true,
        }, () => {
          this.drawSubmissionDataChart(this.state.subChartItem.categoryIds, this);
        });
      } else {
        this.setState({
          isSubChartLoading: false
        });
      }
    }, (error) => {
      this.setState({
        isSubChartLoaded: true,
        error
      });
    });
  }

  drawSubmissionDataChart = (categoryIds, gThis) => {
    this.setState({
      submissionsDataChartOptions: {
        title: {
          text: ''
        },
        colors: this.state.submission_colors,
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 1000);
            }
          },
          type: "column",
          animation: true,
          style: {
            // fontFamily: "Roboto",
            color: "#444444"
          }
        },
        plotOptions: {
          series: {
            stacking: "percent",
            linewidth: 6,
            borderRadius: 1
          },
          column: {
            events: {
              click: function (event) {
                var index = event.point.index;
                // alert(gState.subChartItem.categoryIds[index]);
                gThis.setModelopen(categoryIds[index]);
              }
            }
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.subChartItem.categories,
          title: {
            text: "COURSE NAME"
          },
          min: 0,
          max: this.state.subChartItem.categories.length > 5 ? 5 : this.state.subChartItem.categories.length - 1,
          scrollbar: {
            enabled: true
          }
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: 'SUBMISSION PERCENTAGE'
            //align: 'center'
          },
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
          shared: true
        },
        credits: {
          enabled: false
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        series: this.state.subChartItem.data
      }
    }, () => {
      this.setState({
        isSubChartLoaded: true
      });
    });
  }

  charts_submissiondetails(courseId = 0) {
    this.setState({
      SubmissionModelOpen: true,
      isSubChartDetailsLoaded: false,
      subDetailsChartItems: [],
      isSubChartDetailsLoading: true
    }, () => {
      const params = { term: this.state.filterTermValue, courseId: courseId, studentId: this.state.filterStudentValue, yearLevel: this.state.filterStudentValue, semester: this.state.filterSemesterValue, access: this.state.role_access };
      ApiServices.AccClassProfileService.submissionDetails(params, this.state.token).then(res => res.json()).then((result) => {
        if (result.data.response && result.data.response.status !== 0) {
          this.setState({
            isSubChartLoaded: true,
            subDetailsChartItems: result.data.response.body,
            isSubChartDetailsLoaded: true,
            isSubChartDetailsLoading: false
          }, () => {
            this.drawSubmissionDetailsChart();
          });
        } else {
          this.setState({
            isSubChartDetailsLoading: false
          });
        }
      }, (error) => {
        this.setState({
          isSubChartDetailsLoaded: true,
          error
        });
      });
    });
  }

  drawSubmissionDetailsChart = () => {
    this.setState({
      submissionDetailsChartOptions: {
        title: {
          text: ''
        },
        colors: this.state.submission_colors,
        chart: {
          events: {
            load() {
              this.showLoading();
              setTimeout(this.hideLoading.bind(this), 1000);
            }
          },
          type: "column",
          animation: true,
          style: {
            // fontFamily: "Roboto",
            color: "#444444"
          }
        },
        plotOptions: {
          series: {
            stacking: "percent",
            linewidth: 6,
            borderRadius: 1
          }
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          categories: this.state.subDetailsChartItems.categories,
          title: {
            text: "ASSIGNMENT NAME"
          },
          min: 0,
          max: this.state.subDetailsChartItems.categories.length > 5 ? 5 : this.state.subDetailsChartItems.categories.length - 1,
          scrollbar: {
            enabled: true
          }
        },
        credits: {
          enabled: false
        },
        yAxis: {
          gridLineColor: "#F3F3F3",
          lineColor: "#F3F3F3",
          minorGridLineColor: "#F3F3F3",
          tickColor: "#F3F3F3",
          tickWidth: 1,
          title: {
            text: "SUBMISSIONS AS A PERCENTAGE"
          }
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
          shared: true
        },
        legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
        background2: "#505053",
        dataLabelsColor: "#B0B0B3",
        textColor: "#C0C0C0",
        contrastTextColor: "#F0F0F3",
        maskColor: "rgba(255,255,255,0.3)",
        series: this.state.subDetailsChartItems.data
      }
    });
  }

  setModelopen = (index) => {
    this.charts_submissiondetails(index);
  }

  closeModal = () => {
    this.setState({
      SubmissionModelOpen: false
    });
  };

  componentDidMount() {
    this.filterTerms();
    //this.redrawCharts();
    //this.table_feedbacks();
    //const response = await fetch('http://api.tentacle.com:801/api/1/health/check', requestOptions);  
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  generatepdf = () => {
    const input = document.getElementById('collddd');
    html2canvas(input)
      .then((canvas) => {

        var pdf = new jsPDF("p", "mm", "a4", true);

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        var marg = width - 2 * 2;
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 2, 0, marg, height, '', 'FAST');
        pdf.save("Class_Teacher_Profile_" + moment().tz('Australia/Lindeman').format('YYYY-MM-DD hh:mm:ss'));
      });
  }

  render() {
    const gState = this.state;
    const gThis = this;

    // var setModelopen =  function(index){
    //   gThis.charts_submissiondetails(index);
    //  };
    //  var closeModal = function(){
    //    gThis.setState({
    //      SubmissionModelOpen:false
    //     });
    //  };

    var easeOutBounce = function (pos) {
      if ((pos) < (1 / 2.75)) {
        return (7.5625 * pos * pos);
      }
      if (pos < (2 / 2.75)) {
        return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
      }
      if (pos < (2.5 / 2.75)) {
        return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
      }
      return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
    };

    Math.easeOutBounce = easeOutBounce;

    // const colors1 = ["#0266C8", "#F90101", "#F2B50F", "#00933B"];
    // let chart1 = {
    //   title: {
    //     text: ''
    //   },

    //     colors:subjectassignmentComparisonColors ,
    //     chart: {
    //       events: {
    //         load() {
    //           this.showLoading();
    //           setTimeout(this.hideLoading.bind(this), 2000);
    //         }
    //       },
    //       type:"column",
    //       animation: true,
    //       style: {
    //        // fontFamily: "Roboto",
    //         color: "#444444"
    //       }
    //     },
    //     plotOptions: {
    //       series: {
    //         stacking: "percent",
    //         linewidth:6,
    //         borderRadius: 1
    //       }
    //   },
    //     xAxis: {
    //       gridLineWidth: 1,
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       categories:this.state.marksByAssignments.categories,
    //       title: {
    //         text: 'ASSIGNMENT NAME'
    //         //align: 'center'
    //       },
    //       // min: 6,
    //       max: 20,
    //       scrollbar: {
    //         enabled: true
    //       }
    //     },
    //     yAxis: {
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       title: {
    //         text: 'MARKS AS A PERCENTAGE'
    //         //align: 'center'
    //       }

    //     },
    //     legend:{
    //       enabled:true
    //     },
    //     tooltip: {
    //       pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
    //       shared: true
    //     },
    //     credits: {
    //       enabled: false
    //     },
    //     legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //     background2: "#505053",
    //     dataLabelsColor: "#B0B0B3",
    //     textColor: "#C0C0C0",
    //     contrastTextColor: "#F0F0F3",
    //     maskColor: "rgba(255,255,255,0.3)",

    //     series: this.state.marksByAssignments.data
    // };



    // let chart2 = {
    //   title: {
    //     text: ''
    //   },

    //     colors:submission_colors ,
    //     chart: {
    //       events: {
    //         load() {
    //           this.showLoading();
    //           setTimeout(this.hideLoading.bind(this), 1000);
    //         }
    //       },
    //       type:"column",
    //       animation: true,
    //       style: {
    //        // fontFamily: "Roboto",
    //         color: "#444444"
    //       }
    //     },
    //     plotOptions: {
    //       series: {
    //         stacking:"percent",
    //           linewidth:6,
    //           borderRadius: 1
    //       },
    //       column: {
    //         events: {
    //            click:function(event) {
    //               var index=  event.point.index ;

    //              // alert(gState.subChartItem.categoryIds[index]);
    //              setModelopen(gState.subChartItem.categoryIds[index]);
    //            }
    //        }
    //    }
    //   },
    //     xAxis: {
    //       gridLineWidth: 1,
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       categories:this.state.subChartItem.categories,
    //       title: {
    //         text: "COURSE NAME"
    //       },
    //       min: 1, 
    //       max: 5,
    //       scrollbar: {
    //         enabled: true
    //       }
    //     },
    //     yAxis: {
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       title: {
    //         text: 'SUBMISSION PERCENTAGE'
    //         //align: 'center'
    //     },
    //   },
    //     tooltip: {
    //       pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
    //       shared: true
    //     },

    //     credits: {
    //       enabled: false
    //     },
    //     legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //     background2: "#505053",
    //     dataLabelsColor: "#B0B0B3",
    //     textColor: "#C0C0C0",
    //     contrastTextColor: "#F0F0F3",
    //     maskColor: "rgba(255,255,255,0.3)",

    //     series: this.state.subChartItem.data
    // };


    // let submission_details_chart = {
    //   title: {
    //     text: ''
    //   },

    //   colors: this.state.submission_colors,
    //   chart: {
    //     events: {
    //       load() {
    //         this.showLoading();
    //         setTimeout(this.hideLoading.bind(this), 1000);
    //       }
    //     },
    //     type: "column",
    //     animation: true,
    //     style: {
    //       // fontFamily: "Roboto",
    //       color: "#444444"
    //     }
    //   },
    //   plotOptions: {
    //     series: {
    //       stacking: "percent",
    //       linewidth: 6,
    //       borderRadius: 1
    //     },

    //   },
    //   xAxis: {
    //     gridLineWidth: 1,
    //     gridLineColor: "#F3F3F3",
    //     lineColor: "#F3F3F3",
    //     minorGridLineColor: "#F3F3F3",
    //     tickColor: "#F3F3F3",
    //     tickWidth: 1,
    //     categories: this.state.subDetailsChartItems.categories,
    //     title: {
    //       text:"ASSIGNMENT NAME"
    //     },         
    //     scrollbar: {
    //       enabled: true
    //     }
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   yAxis: {
    //     gridLineColor: "#F3F3F3",
    //     lineColor: "#F3F3F3",
    //     minorGridLineColor: "#F3F3F3",
    //     tickColor: "#F3F3F3",
    //     tickWidth: 1,
    //     title: {
    //       text:"SUBMISSIONS AS A PERCENTAGE"
    //     }
    //    },
    //   tooltip: {
    //     pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.2f}%)<br/>',
    //     shared: true
    //   },
    //   legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //   background2: "#505053",
    //   dataLabelsColor: "#B0B0B3",
    //   textColor: "#C0C0C0",
    //   contrastTextColor: "#F0F0F3",
    //   maskColor: "rgba(255,255,255,0.3)",

    //   series: this.state.subDetailsChartItems.data
    // };

    // let late_submission = {
    //   title: {
    //     text: ''
    //   },

    //     colors:colors ,
    //     chart: {
    //       events: {
    //         load() {
    //           this.showLoading();
    //           setTimeout(this.hideLoading.bind(this), 1000);
    //         }
    //       },
    //       type:"column",
    //       animation: true,
    //       style: {
    //        // fontFamily: "Roboto",
    //         color: "#444444"
    //       }
    //     },
    //     credits: {
    //       enabled: false
    //     },
    //     plotOptions: {
    //       series: {

    //           linewidth:6,
    //           borderRadius: 1
    //       }
    //   },
    //     xAxis: {
    //       gridLineWidth: 1,
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //       categories:this.state.subChartItem.categories
    //     },
    //     yAxis: {
    //       gridLineColor: "#F3F3F3",
    //       lineColor: "#F3F3F3",
    //       minorGridLineColor: "#F3F3F3",
    //       tickColor: "#F3F3F3",
    //       tickWidth: 1,
    //         title: {
    //         text: 'SUBMISSIONS PERCENTAGE'
    //         //align: 'center'
    //     }

    //     },
    //     legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //     background2: "#505053",
    //     dataLabelsColor: "#B0B0B3",
    //     textColor: "#C0C0C0",
    //     contrastTextColor: "#F0F0F3",
    //     maskColor: "rgba(255,255,255,0.3)",

    //     series: this.state.subChartItem.data2
    // };

    // let activity_by_time = {
    //   title: {
    //     text: ''
    //   },

    //   colors: colors,
    //   chart: {
    //     type: "line",
    //     animation: true,
    //     style: {
    //       // fontFamily: "Roboto",
    //       color: "#444444"
    //     }
    //   },
    //   legend: {
    //     enabled: false
    //   },
    //   plotOptions: {
    //     series: {
    //       marker: {
    //         radius: 9
    //       },
    //       lineWidth: 5
    //     }
    //   },
    //   xAxis: {
    //     gridLineWidth: 1,
    //     gridLineColor: "#F3F3F3",
    //     lineColor: "#F3F3F3",
    //     minorGridLineColor: "#F3F3F3",
    //     tickColor: "#F3F3F3",
    //     tickWidth: 1,
    //     categories: this.state.activityByTimeData.categories,
    //     title: { text: "TIME SLOT" }
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   tooltip: {
    //     formatter: function (tooltip) {



    //       return "Time Slot : <b>" + this.point.category + "<b/><br/>Number of Activities : <b>" + this.y + " during "+gState.activityByTimeData.data[this.point.x]+" days";


    //     }
    //   },
    //   yAxis: {
    //     gridLineColor: "#F3F3F3",
    //     lineColor: "#F3F3F3",
    //     minorGridLineColor: "#F3F3F3",
    //     tickColor: "#F3F3F3",
    //     tickWidth: 1,
    //     title: { text: "NUMBER OF ACTIVITIES" }

    //   },
    //   legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
    //   background2: "#505053",
    //   dataLabelsColor: "#B0B0B3",
    //   textColor: "#C0C0C0",
    //   contrastTextColor: "#F0F0F3",
    //   maskColor: "rgba(255,255,255,0.3)",

    //   series: [{
    //     name: "Count",
    //     data: this.state.activityByTimeData.data,
    //     animation: {
    //       duration: 2000
    //       // Uses Math.easeOutBounce
    //       // easing: 'easeOutBounce'
    //     }
    //   }
    //   ]
    // };

    // let workload = {
    //   title: {
    //     text: ''
    //   },


    //   chart: {
    //     type: 'heatmap',
    //     marginTop: 40,
    //     marginBottom: 80,
    //     plotBorderWidth: 1
    //   },

    //   colorAxis : {
    //     min: 0,
    //     minColor: "#ffffff",
    //     maxColor: colors[0]
    //  },
    //   title: {
    //     text: ''
    //   },

    //   xAxis: {
    //     categories: ["WEEK 1", "THIS WEEK", "NEXT WEEK", "2 WEEKS FROM NOW", "3 WEEKS FROM NOW"],
    //     title:{
    //       text:"NEXT FOUR WEEKS"
    //     }

    //   },
    //   yAxis: {
    //     gridLineColor: "#F3F3F3",
    //     lineColor: "#F3F3F3",
    //     minorGridLineColor: "#F3F3F3",
    //     tickColor: "#F3F3F3",
    //     tickWidth: 1,
    //     categories: this.state.workLoadItem.categories,
    //     title: null,
    //     reversed: true,
    //     title:{
    //       text:"COURSE NAME"
    //     }
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   accessibility: {
    //     point: {
    //       descriptionFormatter: function (point) {

    //       }
    //     }
    //   },

    //   legend: {
    //     align: 'right',
    //     layout: 'vertical',
    //     margin: 0,
    //     verticalAlign: 'top',
    //     y: 25,
    //     symbolHeight: 280
    //   },

    //   tooltip: {
    //     /*
    //                           formatter: function () {                    
    //                         return '<b>' + this.series.yAxis.categories[this.point.y] + '</b> Yearly Average: <b>$' +	
    //                           Highcharts.numberFormat(this.series.chart.yAxis[1].categories[this.point.y],2) + '</b><br>' + 
    //                           'Average Labour Rate: <b>' + '$'+Highcharts.numberFormat(this.point.value, 2) + '</b><br>' + 
    //                           '<b>' + this.series.xAxis.categories[this.point.x];
    //                     }

    //    */
    //     formatter: function (tooltip) {         

    //       if(gState.isWorkloadLoaded){


    //         if(this.point.value >0){

    //           return gState.workLoadItem.allData[this.point.y].tooltips[this.point.x-1];

    //         }else{

    //           return false;
    //         }


    //       }else{

    //         return "";
    //       }
    //     }
    // },


    //   series: [{
    //     name: 'Work Load',
    //     borderWidth: 1,
    //     data: this.state.workLoadItem.data,
    //     dataLabels: {
    //       enabled: true,
    //       color: '#000000'
    //     }
    //   }],



    //   responsive: {
    //     rules: [{
    //       condition: {
    //         maxWidth: 500
    //       },
    //       chartOptions: {
    //         yAxis: {
    //           labels: {
    //             formatter: function () {
    //               return this.value.charAt(0);
    //             }
    //           }
    //         }
    //       }
    //     }]
    //   }
    // };

    // let naplanchart = {

    //   chart: {
    //     type: 'boxplot'
    // },

    // title: {
    //     text: ''
    // },

    // legend: {
    //     enabled: false
    // },

    // xAxis: {
    //     categories: this.state.naplan.categories,
    //     title: {
    //         text: 'SKILL'
    //     }
    // },
    // plotOptions: {
    //   boxplot: {


    //       lineWidth: 2
    //      // medianColor: '#0C5DA5',
    //     //  medianDashStyle: 'ShortDot',
    //     //  medianWidth: 3,
    //      // stemColor: '#A63400',
    //     //  stemDashStyle: 'dot',
    //     //  stemWidth: 1,
    //    //   whiskerColor: '#3D9200',
    //     //  whiskerLength: '20%',
    //     //  whiskerWidth: 3
    //   }
    // },
    // yAxis: {
    //     title: {
    //         text: 'SCALED SCORE'
    //     } 

    // },
    // credits: {
    //   enabled: false
    // },
    // series: [{
    //     name: 'NAPLAN',
    //     color:"#002b80",
    //     data: this.state.naplan.data

    // }, {
    //     name: 'STUDENT',
    //     color: "#801100",
    //     type: 'scatter',
    //     data: this.state.naplan.scatterData,

    //     tooltip: {
    //         pointFormat: 'STUDENT: {point.y}'
    //     }
    // }]

    // };


    //   let engagement_performance =  {
    //     chart: {
    //         type: 'scatter',
    //         zoomType: 'xy'
    //     },
    //     title: {
    //         text: ''
    //     },
    //     subtitle: {
    //         text: ''
    //     },
    //     xAxis: {
    //         title: {
    //             enabled: true,
    //             text: 'ENGAGEMENT SCORE'
    //         },
    //         startOnTick: true,
    //         endOnTick: true,
    //         showLastLabel: true
    //     },
    //     yAxis: {
    //         title: {
    //             text: 'PERFORMANCE SCORE'
    //         }
    //     },
    //     legend:{
    //       enabled:false
    //     },
    //     plotOptions: {
    //         scatter: {
    //             marker: {
    //                 radius: 5,
    //                 states: {
    //                     hover: {
    //                         enabled: true,
    //                         lineColor: 'rgb(100,100,100)'
    //                     }
    //                 }
    //             },
    //             states: {
    //                 hover: {
    //                     marker: {
    //                         enabled: false
    //                     }
    //                 }
    //             } 
    //         }
    //     },
    //     tooltip: {

    //       formatter: function (tooltip) {         

    //         if(gState.isEngagementPerformanceLoaded){


    //           if(this.point.name!=undefined){

    //             //  return "Time Slot : <b>" + this.point.category + "<b/><br/>Count : <b>" + this.y + " ";
    //             return "Student Name : "+ this.point.name +"<br/>Engagement : "+this.point.x+"<br/>Performance : "+this.point.y;

    //             //return "Student Name : "+gState.engagementPerformanceData.categories[this.series.data.indexOf( this.point )-1]+"<br/>Engagement : "+this.point.x+"<br/>Performance : "+this.point.y;

    //           }else{

    //             return false;
    //           }


    //         }else{

    //           return "";
    //         }
    //       }
    //   },
    //     credits:{
    //       enabled:false
    //     },
    //     series: [ {
    //         name: '',
    //         color: this.state.colors[0],
    //         data:  this.state.engagementPerformanceData.data
    //     }]
    // };      

    const settings = {
      className: "center",
      centerMode: true,
      speed: 500,
      arrows: false,
      centerPadding: "5px",
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Fragment>
        <div id="collddd">
        <div className="container-fluid " >
          <div className="card custom-card  " style={{ backgroundColor: "#e0e0e0", marginTop: '90px' }}>
            <div className="row ">
              <div className="col-md-10 col-sm-12 col-xs-12 col-lg-10">
                <div className="row "  >
                  <div className="col-sm-4 col-md-4  col-xs-12 col-xl-4 col-lg-4" >
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                        <div className="media static-top-widget">
                          <div className="media-body">
                            <span className="m-0">YEAR</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits " value={this.state.filterTermValue} onChange={(e) => this.changeTermValue(e.target.value)}>
                                {this.state.isFilterTermsLoaded ?
                                  this.state.filterTerms.map((filterTerm) => <option key={filterTerm.id} value={filterTerm.id}>{filterTerm.name}</option>)
                                  : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3  col-xs-12 col-xl-3 col-lg-3">
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                        <div className="media static-top-widget">
                          <div className="media-body"><span className="m-0">SEMESTER</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits" value={this.state.filterSemesterValue} onChange={(e) => this.changeSemesterValue(e.target.value)}>
                                {this.state.isFilterSemestersLoaded
                                  ? this.state.filterSemesters.map((semester) => <option key={semester.id} value={semester.id}>{semester.name}</option>)
                                  : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-xs-12 col-xl-4 col-lg-4">
                    <div className="card o-hidden custom-cardx">
                      <div className=" card-body" style={{ backgroundColor: "#e0e0e0" }}>
                        <div className="media static-top-widget">
                          <div className="media-body"><span className="m-0">COURSE</span>
                            <h4 className="mb-0 counter">
                              <select className="form-control digits" value={this.state.filterCourseValue} onChange={(e) => this.changeCourseValue(e.target.value)}>
                                {this.state.isFilterCoursesLoaded ?
                                  this.state.filterCourses.map((filterCourses) => <option key={filterCourses.id} value={filterCourses.id}>{filterCourses.name}</option>)
                                  : <option>Loading...</option>
                                }
                              </select>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xs-12 col-xl-2 col-md-2 col-lg-2" >
                <div className="">
                  <button disabled={this.state.isGoButtonDisabled} readOnly={this.state.isGoButtonDisabled} style={{ marginTop: '24px', width: "100%" }} className="btn btn-secondary btn-lg" onClick={(e) => this.redrawCharts()}>GO</button>
                </div>
              </div>
            </div>
          </div>
          {/* <button type="button" className="btn btn-secondary btn-lg" onClick={this.generatepdf}>Generate pdf</button> */}

          <div id="collddd">
            <div className="class-profile">
              <div className="row">
                <div className="col-md-12 col-sm-12   col-lg-12 col-xl-12">
                  <div className="card custom-card  ">
                    <div className="row">
                      <div className="col-2 col-sm-2">
                        <img src={accimage}></img>
                      </div>
                      <div className="col-9 col-sm-9 with-border">
                        <div className="student-name">
                          <h5><span className="counter">CLASS TEACHER PROFILE</span></h5>
                          <h4>{this.state.isStudentNameLoaded
                            ? this.state.studentName
                            : this.state.loader}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-md-6 col-sm-6 col-xs-6 col-lg-6 ">
                  <div className="card">
                    <div className="card-header bg-custom-dark bg-danger">
                      <h5>STUDENTS NEEDING ADDITIONAL SUPPORT</h5>
                    </div>
                    <div className="card-body datatable-react">
                      {this.state.isStudentStatsLoaded ?
                        <Datatable
                          myData={this.state.needAttention}
                          headerColumns={this.state.tableHeaderColumns}
                          pageSize={5}
                          pagination={true}
                          myClass="-striped -highlight"
                        />
                        : this.state.isStudentStatsLoading ?
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-md-6 col-sm-6 col-xs-6 col-lg-6">
                  <div className="card">
                    <div className="card-header bg-custom-dark bg-success">
                      <h5>STUDENTS READY TO BE EXTENDED</h5>
                    </div>
                    <div className="card-body datatable-react">
                      {this.state.isStudentStatsLoaded ?
                        <Datatable
                          myData={this.state.readyToSend}
                          headerColumns={this.state.tableHeaderColumns}
                          pageSize={5}
                          pagination={true}
                          myClass="-striped -highlight"
                        />
                        : this.state.isStudentStatsLoading ?
                          <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-xl-12  col-lg-12 col-sm-12 col-md-12 ">
                  <div className="card">
                    <div className="card-header bg-custom-dark">
                      <h5>GRADES COMPARISON BY ASSIGNMENT</h5>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive">
                        <div height=" ">
                          {this.state.isMarksByAssignmentsLoaded ?
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.gradesComparisonChartOptions} />
                            : this.state.isMarksByAssignmentsLoading ?
                              <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark">
                      <h5>SUBMISSIONS BY COURSE</h5>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive ">
                        <div>
                          {this.state.isSubChartLoaded ?
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.submissionsDataChartOptions}
                            />
                            : this.state.isSubChartLoading ?
                              <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark">
                      <h5>Activity By Time Of The Day</h5>
                    </div>
                    <div className="card-body">
                      { this.state.isActivityByTimeLoading ?
                          <div className="row">
                            <div className="col-xl-9">
                              <div className="user-status table-responsive">
                                {this.state.isActivityByTimeLoaded ?
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={this.state.activity_by_time}
                                  />
                                  : this.state.isActivityByTimeLoading ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                                    : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                                }
                              </div>
                            </div>
                            <div className="col-xl-3 d-flex flex-column justify-content-start stp-activity-filter">
                              <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'M' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('M')}><span>MONTH WISE</span></div>{' '}
                              <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'W' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('W')}><span>WEEK WISE</span></div>{' '}
                              <div className={`stp-activity-filter-btn ${this.state.filterActivityByTimeMode == 'D' ? 'active' : ''}`} onClick={() => this.activityByTimeMode('D')}><span>DAY WISE</span></div>{' '}
                            </div>
                          </div>
                          : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark custom-top-bottom-padding">
                      <Fragment>
                        <div className="row">
                          <div className="col-8"><h5>Latest Naplan</h5></div>
                          <div className="col-4 pat-filter-dropdown">
                            <select className="form-control digits" value={this.state.filterNaPlanValue} onChange={(e) => this.changeNaPlanValue(e.target.value)}>
                              {this.state.isFilterNaPlanLoaded
                                ? this.state.filterNaPlan.map((naplan, key) => <option key={key} value={naplan}>{naplan}</option>)
                                : <option>Loading...</option>
                              }
                            </select>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive">
                        {this.state.isNaPlanLoaded ?
                          <Fragment>
                            <h6>Class average is represented by the dot</h6>
                            <div style={{ height: 5 * 90 }}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.naPlanchartOptions}
                                containerProps={{ style: { height: 5 * 90 } }}
                              />
                            </div>
                          </Fragment>
                          : this.state.isNaPlanLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark custom-top-bottom-padding">
                      <Fragment>
                        <div className="row">
                          <div className="col-8"><h5>Latest Pat</h5></div>
                          <div className="col-4 pat-filter-dropdown">
                            <select className="form-control digits" value={this.state.filterPatValue} onChange={(e) => this.changePatValue(e.target.value)}>
                              {this.state.isFilterPatLoaded
                                ? this.state.filterPats.map((pat, key) => <option key={key} value={pat}>{pat}</option>)
                                : <option>Loading...</option>
                              }
                            </select>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive">
                        {this.state.isPatLoaded ?
                          <Fragment>
                            <h6>Class average is represented by the dot</h6>
                            <div style={{ height: 5 * 90 }}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.patchartOptions}
                                containerProps={{ style: { height: 5 * 90 } }}
                              />
                            </div>
                          </Fragment>
                          : this.state.isPatLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark">
                      <h5>Workload By Week</h5>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive">
                        {this.state.isWorkloadLoaded ?
                          <div>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.workloadChartOptions}
                              containerProps={{ style: { height: this.state.workLoadItem.data["y-categories"].length < 5 ? this.state.workLoadItem.data["y-categories"].length * 100 : this.state.workLoadItem.data["y-categories"].length * 50 } }}
                            />
                          </div>
                          : this.state.isWorkloadLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 xl-100">
                  <div className="card">
                    <div className="card-header bg-custom-dark">
                      <h5>Engagement VS Performance</h5>
                    </div>
                    <div className="card-body">
                      <div className="user-status table-responsive">
                        {this.state.isEngagementPerformanceLoaded ?
                          <div>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={this.state.engagement_performance}
                            />
                          </div>
                          : this.state.isEngagementPerformanceLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal open={this.state.SubmissionModelOpen} onClose={this.closeModal} classNames={{
          modal: 'student-profile-sbmissions-customModal',
        }}>
          <div className="modal-body">
            <div className="product-modal row">
              <div className="class-profile cust-model-size">
                <div className="row">
                  <div className="col-xl-12 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSION BY  ASSIGNMENT</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive">
                          <div>
                            {this.state.isSubChartDetailsLoaded
                              ?
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.submissionDetailsChartOptions}
                              />
                              : this.state.isSubChartDetailsLoading ?
                                <div style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></div>
                                : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 xl-100">
                    <div className="card">
                      <div className="card-header bg-custom-dark">
                        <h5>SUBMISSIONS DETAILS</h5>
                      </div>
                      <div className="card-body">
                        <div className="user-status table-responsive custom-table-class">
                          {this.state.isSubChartDetailsLoaded ?
                            <Fragment>
                              <div className="table-responsive" style={{ display: this.state.isSubChartDetailsLoaded ? "block" : "none" }}>
                                <table className="table table-bordered submission-details-table">
                                  <thead>
                                    <tr>
                                      <th scope="col">SIS ID</th>
                                      <th scope="col">STUDENT NAME</th>
                                      <th scope="col">ASSIGNMENT</th>
                                      <th scope="col">STATUS</th>
                                      <th scope="col">DUE DATE</th>
                                      <th scope="col">SUBMITTED DATE</th>
                                      <th scope="col">DAYS LATE</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.isSubChartDetailsLoaded ?
                                      this.state.subDetailsChartItems.table.map((subDetails) => <tr><td>{subDetails.studentSisId ? subDetails.studentSisId : subDetails.id}</td><td>{subDetails.student_name}</td><td>{subDetails.assignment}</td><td>{subDetails.status}</td><td>{subDetails.dueDate}</td><td>{subDetails.submitted}</td><td>{subDetails.delay}</td></tr>)
                                      : "NO DATA AVAILABLE"
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </Fragment>
                            : this.state.isSubChartDetailsLoading ?
                              <tr style={{ display: 'flex', justifyContent: 'center' }} ><img src={preloaderGif} height="100px"></img></tr>
                              : <div style={{ display: 'flex', justifyContent: 'center' }} ><h5>{"NO DATA AVAILABLE"}</h5></div>
                          }
                          <div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        </div>        
      </Fragment>
    );
  }
}

export default AccClassProfile;