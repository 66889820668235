import {
  FETCHING_ROLES_TABLE,
  ROLES_TABLE_DATA,
  FETCHING_USER_TABLE,
  USER_TABLE_DATA,
  FETCHING_ROLES,
  ROLES_DATA,
  FETCHING_ROLE_USERS,
  ROLE_USERS_DATA,
  SAVE_ROLES_LOADING,
  SAVE_ROLES,
  FETCHING_ACCOUNT_HIERARCHY,
  ACCOUNT_HIERARCHY_DATA,
  USER_SEARCH_LOADING,
  USER_SEARCH,
  CUSTOM_USER_SEARCH_LOADING,
  CUSTOM_USER_SEARCH,
  ROLE_DATA_SYNCING,
  SYNC_ROLE_DATA,
  ROLE_DATA_DELETING,
  DELETE_ROLE_DATA,
  SET_SAVE_PAYLOAD,
  INDUVIDUAL_ROLE_FETCHING,
  INDUVIDUAL_ROLE_DATA,
  EDIT_ROLE,
  EDIT_ROLE_DATA,
  EDIT_ROLE_DATA_LOADING,
  RESET_PAYLOAD_DATA,
  RESET_INDIVIDUAL_ROLE_DATA,
  RESET_SAVE_DATA,
  COURSE_LIST_DATA,
  FETCHING_COURSE_LIST,
  SAVE_COURSE_PERMISSION,
  SAVE_COURSE_PERMISSION_LOADING,
  SET_COURSE_PAYLOAD,
  SET_PERMISSION_PAYLOAD,
  FETCHING_COURSE_PERMISSION,
  COURSE_PERMISSION_DATA,
  DELETE_COURSE_PERMISSION,
  DELETING_COURSE_PERMISSION,
  FETCHING_SPECIFIC_COURSES,
  SPECIFIC_COURSES_DATA,
  RESET_PERMISSION_PAYLOAD,
  EDIT_COURSE_PERMISSION,
  EDIT_COURSE_PERMISSION_LOADING,
  USER_MANAGE_STATUS,
  USER_MANAGE_STATUS_LOADING,
  FETCHING_STUDENT_USERS,
  ROLE_STUDENT_DATA,
  RESET_COURSE_PAYlOD_DATA,
  RESET_COURSE_PERMISSION_DATA,
  RESET_COURSE_PERMISSION_PAYLOAD,
  CLEAR_SAVE_RESPONCE,
  CLEAR_EDIT_RESPONCE,
  REMOVING_ITEMS_PAYLOAD,
  CLEAR_ROLE_DATA,
  RESET_ROLE_USERS,
  RESET_CUSTOM_SEARCH,
  ROLE_STUDENT_ROLES_DATA,
  FETCHING_STUDENT_ROLES,
  SET_USER_AVAILABILITY,
  FETCHING_PERMISSION_INFO,
  PERMISSION_INFO_DATA,
  CLEAR_PERMISSON_INFO,
  EDIT_CANVAS_STUDENT_DATA,
  EDITING_CANVAS_STUDENT,
  CLEAR_EDIT_CANVAS_STUDENTS,
  SET_ROLE_DEATAILS,
  CLEAR_COURSE_PAYLOAD,
  CLEAR_STUDENT_ROLE_LIST,
} from "../../constant/actionTypes";
import service from "../../utils/customServices/dashboardManagement";
import { showNotification } from "../../utils/general";
import { get as _get } from 'lodash';

// Fetch roles table
const fetchingRolesTable = () => ({
  type: FETCHING_ROLES_TABLE,
  payload: { isLoading: true },
});

const setRolesTableData = (data) => {
  const { error, response } = data;
  return {
    type: ROLES_TABLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const fetchRolesTable = () => async (dispatch) => {
  dispatch(fetchingRolesTable());

  const requestOptions = {
    service: "insights/userManage/fetch_roles",
    method: "GET",
    authorized: true,
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setRolesTableData({ response }));
  } catch (error) {
    dispatch(setRolesTableData({ error: error.message }));
  }
};

// Fetch user table
const fetchingUserTable = () => ({
  type: FETCHING_USER_TABLE,
  payload: { isLoading: true },
});

const setUserTableData = (data) => {
  const { error, response } = data;
  return {
    type: USER_TABLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const fetchUserTable = (payload) => async (dispatch) => {
  dispatch(fetchingUserTable());

  const requestOptions = {
    service: "insights/userManage/fetch_users",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setUserTableData({ response }));
  } catch (error) {
    dispatch(setUserTableData({ error: error.message }));
  }
};

// Fetch all roles
const fetchingRoleDetails = () => ({
  type: FETCHING_ROLES,
  payload: { isLoading: true },
});

const rolesData = (data) => {
  const { error, response } = data;
  return {
    type: ROLES_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const fetchAllRoles = () => async (dispatch) => {
  dispatch(fetchingRoleDetails());

  const requestOptions = {
    service: "insights/userManage/fetch_account_roles",
    method: "GET",
    authorized: true,
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(rolesData({ response }));
  } catch (error) {
    dispatch(rolesData({ error: error.message }));
  }
};

// Fetch role users
const fetchingRoleUsers = () => ({
  type: FETCHING_ROLE_USERS,
  payload: { isLoading: true },
});

const roleUsersData = (data) => {
  const { error, response } = data;
  return {
    type: ROLE_USERS_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const fetchRoleUsers = (payload) => async (dispatch) => {
  dispatch(fetchingRoleUsers());

  const requestOptions = {
    service: "insights/userManage/fetch_enroll_users",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(roleUsersData({ response }));
  } catch (error) {
    dispatch(roleUsersData({ error: error.message }));
  }
};

// Fetch account hierarchy
const fetchingAccountHierarchy = () => ({
  type: FETCHING_ACCOUNT_HIERARCHY,
  payload: { isLoading: true },
});

const setAccountHierarchyData = (data) => {
  const { error, response } = data;
  return {
    type: ACCOUNT_HIERARCHY_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data.data
    },
  };
};

export const fetchAccountHierarchy = () => async (dispatch) => {
  dispatch(fetchingAccountHierarchy());

  const requestOptions = {
    service: "insights/userManage/account_hierarchy",
    method: "GET",
    authorized: true,
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setAccountHierarchyData({ response }));
  } catch (error) {
    dispatch(setAccountHierarchyData({ error: error.message }));
  }
};

// Save roles
const roleDataSaving = () => ({
  type: SAVE_ROLES_LOADING,
  payload: { isLoading: true },
});

const saveRoleData = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Role saved successfully",
    });
  }
  return {
    type: SAVE_ROLES,
    payload: {
      isLoading: false,
      error,
      data: response.data,
    },
  };
};

export const saveUsers = (payload) => async (dispatch) => {
  dispatch(roleDataSaving());

  const requestOptions = {
    service: "insights/userManage/save_users",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(saveRoleData({ response }));
  } catch (error) {
    dispatch(saveRoleData({ error: error.message }));
  }
};

// User search
const userSearching = () => ({
  type: USER_SEARCH_LOADING,
  payload: { isLoading: true },
});

const userSearchData = (data) => {
  const { error, response } = data;
  return {
    type: USER_SEARCH,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const searchUsers = (payload) => async (dispatch) => {
  dispatch(userSearching());

  const requestOptions = {
    service: "insights/userManage/search_users",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(userSearchData({ response }));
  } catch (error) {
    dispatch(userSearchData({ error: error.message }));
  }
};

// Custom user search
const customUserSearching = () => ({
  type: CUSTOM_USER_SEARCH_LOADING,
  payload: { isLoading: true },
});

const customUserSearchData = (data) => {
  const { error, response } = data;
  return {
    type: CUSTOM_USER_SEARCH,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const customSearchUsers = (payload) => async (dispatch) => {
  dispatch(customUserSearching());

  const requestOptions = {
    service: "insights/userManage/custom_user_search",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(customUserSearchData({ response }));
  } catch (error) {
    dispatch(customUserSearchData({ error: error.message }));
  }
};

// Sync role data
const roleDataSyncing = () => ({
  type: ROLE_DATA_SYNCING,
  payload: { isLoading: true },
});

const syncRoleData = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status === 200) {
    showNotification({
      message: "Success",
      description: "Role Sync successfully",
    });
  }
  return {
    type: SYNC_ROLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const roleDataSync = (payload) => async (dispatch) => {
  dispatch(roleDataSyncing());

  const requestOptions = {
    service: "insights/userManage/sync_role_data",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(syncRoleData({ response }));
  } catch (error) {
    dispatch(syncRoleData({ error: error.message }));
  }
};

// Delete role data
const roleDataDeleting = () => ({
  type: ROLE_DATA_DELETING,
  payload: { isLoading: true },
});

const deleteRoleData = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Role delete successfully",
    });
  }
  return {
    type: DELETE_ROLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const roleDataDelete = (payload) => async (dispatch) => {
  dispatch(roleDataDeleting());

  const requestOptions = {
    service: "insights/userManage/delete_role_data",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(deleteRoleData({ response }));
  } catch (error) {
    dispatch(deleteRoleData({ error: error.message }));
  }
};

// Fetch individual role
const individualRoleFetching = () => ({
  type: INDUVIDUAL_ROLE_FETCHING,
  payload: { isLoading: true },
});

const fetchIndividualData = (data) => {
  const { error, response } = data;
  return {
    type: INDUVIDUAL_ROLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };

};

export const individualRoleFetch = payload => async (dispatch) => {
  dispatch(individualRoleFetching());

  const requestOptions = {
    service: "insights/userManage/fetch_individual_role",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(fetchIndividualData({ response }));
  } catch (error) {
    dispatch(fetchIndividualData({ error: error.message }));
  }
};

 // set payload to save or update
export const setSavePayload = data => dispatch => {
  dispatch({
    type: SET_SAVE_PAYLOAD,
    payload: {
      data: data,
    },
  });
};

// set state to edit
export const editRole = data => dispatch => {
  dispatch({
    type: EDIT_ROLE,
    payload: {
      data: data,
    },
  });
};

// Edit role data
const roleDataEditing = () => ({
  type: EDIT_ROLE_DATA_LOADING,
  payload: { isLoading: true },
});

const editRoleData = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Role edited successfully",
    });
  }
  return {
    type: EDIT_ROLE_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const editRoleDataAction = (payload) => async (dispatch) => {
  dispatch(roleDataEditing());

  const requestOptions = {
    service: "insights/userManage/edit_role_data",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(editRoleData({ response }));
  } catch (error) {
    dispatch(editRoleData({ error: error.message }));
  }
};

export const resetSaveData = () => dispatch => {
  dispatch({
    type: RESET_SAVE_DATA,
  });
};

export const resetIndividualRoleData = () => dispatch => {
  dispatch({
    type: RESET_INDIVIDUAL_ROLE_DATA,
  });
};

export const resetPayloadData = () => dispatch => {
  dispatch({
    type: RESET_PAYLOAD_DATA,
  });
};

// Fetch course list
const fetchingCourseList = () => ({
  type: FETCHING_COURSE_LIST,
  payload: { isLoading: true },
});

const setCourseListData = (data) => {
  const { error, response } = data;
  return {
    type: COURSE_LIST_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const fetchCourseList = (payload) => async (dispatch) => {
  dispatch(fetchingCourseList());

  const requestOptions = {
    service: "insights/userManage/fetch_course_list",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setCourseListData({ response }));
  } catch (error) {
    dispatch(setCourseListData({ error: error.message }));
  }
};

// Save course permissions
const saveCoursePermissionLoading = () => ({
  type: SAVE_COURSE_PERMISSION_LOADING,
  payload: { isLoading: true },
});

const saveCoursePermission = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Course permissions saved successfully",
    });
  }
  return {
    type: SAVE_COURSE_PERMISSION,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const saveCoursePermissions = (payload) => async (dispatch) => {
  dispatch(saveCoursePermissionLoading());

  const requestOptions = {
    service: "insights/userManage/save_course_permission",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(saveCoursePermission({ response }));
  } catch (error) {
    dispatch(saveCoursePermission({ error: error.message }));
  }
};

export const setCoursePayload = data => dispatch => {
  dispatch({
    type: SET_COURSE_PAYLOAD,
    payload: {
      data: data,
    },
  });
};

export const sendPermissionPayload = data => dispatch => {
  dispatch({
    type: SET_PERMISSION_PAYLOAD,
    payload: {
      data: data,
    },
  });
};

// fetch course permission
const fetchingCoursePermission = () => ({
  type: FETCHING_COURSE_PERMISSION,
  payload: { isLoading: true },
});

const setCoursePermissionData = (data) => {
  const { error, response } = data;
  return {
    type: COURSE_PERMISSION_DATA,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const fetchCoursePermission = (payload) => async (dispatch) => {
  dispatch(fetchingCoursePermission());

  const requestOptions = {
    service: "insights/userManage/fetch_specific_course_permission",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setCoursePermissionData({ response }));
  } catch (error) {
    dispatch(setCoursePermissionData({ error: error.message }));
  }
};

// delete course permission
const deletingCoursePermission = () => ({
  type: DELETING_COURSE_PERMISSION,
  payload: { isLoading: true },
});

const deleteCoursePermissionData = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Course permission deleted successfully",
    });
  }
  return {
    type: DELETE_COURSE_PERMISSION,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const deleteCoursePermission = (payload) => async (dispatch) => {
  dispatch(deletingCoursePermission());

  const requestOptions = {
    service: "insights/userManage/delete_course_permission",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(deleteCoursePermissionData({ response }));
  } catch (error) {
    dispatch(deleteCoursePermissionData({ error: error.message }));
  }
};

//fetching SpecificCourses
const fetchingSpecificCourses = () => ({
  type: FETCHING_SPECIFIC_COURSES,
  payload: { isLoading: true },
});

const setSpecificCoursesData = (data) => {
  const { error, response } = data;
  return {
    type: SPECIFIC_COURSES_DATA,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const fetchSpecificCourses = (payload) => async (dispatch) => {
  dispatch(fetchingSpecificCourses());

  const requestOptions = {
    service: "insights/userManage/fetch_specific_course_access",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(setSpecificCoursesData({ response }));
  } catch (error) {
    dispatch(setSpecificCoursesData({ error: error.message }));
  }
};

export const resetPermissionPayload = () => dispatch => {
  dispatch({
    type: RESET_PERMISSION_PAYLOAD,
  });
};

const editCoursePermissionLoading = () => ({
  type: EDIT_COURSE_PERMISSION_LOADING,
  payload: { isLoading: true },
});

// edit Course permission
const editCoursePermission = (data) => {
  const { error, response } = data;
  const status = _get(response, 'data.status');
  if (status == 200) {
    showNotification({
      message: "Success",
      description: "Course permissions edited successfully",
    });
  }
  return {
    type: EDIT_COURSE_PERMISSION,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const editCoursePermissions = (payload) => async (dispatch) => {
  dispatch(editCoursePermissionLoading());

  const requestOptions = {
    service: "insights/userManage/edit_course_permission",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(editCoursePermission({ response }));
  } catch (error) {
    dispatch(editCoursePermission({ error: error.message }));
  }
};

//  status loading
const userManageStatusLoading = () => ({
  type: USER_MANAGE_STATUS_LOADING,
  payload: { isLoading: true },
});

const statusUserManage = (data) => {
  const { error, response } = data;
  return {
    type: USER_MANAGE_STATUS,
    payload: {
      isLoading: false,
      error,
      data: response ? response.data : null,
    },
  };
};

export const userManageStatus = (payload) => async (dispatch) => {
  dispatch(userManageStatusLoading());

  const requestOptions = {
    service: "insights/userManage/status_update",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(statusUserManage({ response }));
  } catch (error) {
    dispatch(statusUserManage({ error: error.message }));
  }
};

// Fetch student users
const fetchingRoleStudent = () => ({
  type: FETCHING_STUDENT_USERS,
  payload: { isLoading: true },
});

const roleStudentsData = (data) => {
  const { error, response } = data;
  return {
    type: ROLE_STUDENT_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const fetchRoleStudent = (payload) => async (dispatch) => {
  dispatch(fetchingRoleStudent());

  const requestOptions = {
    service: "insights/userManage/fetch_student_by_role",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(roleStudentsData({ response }));
  } catch (error) {
    dispatch(roleStudentsData({ error: error.message }));
  }
};

export const ResetCoursePermissionData = () => dispatch => {
  dispatch({
    type: RESET_COURSE_PERMISSION_DATA,
  });
};

export const ResetRoleUsers = () => dispatch => {
  dispatch({
    type: RESET_ROLE_USERS,
  });
};

export const resetCoursePermissionPayload = () => dispatch => {
  dispatch({
    type: RESET_COURSE_PERMISSION_PAYLOAD,
  });
};

export const ResetCoursePayload = () => (dispatch) => {
  dispatch({
    type: RESET_COURSE_PAYlOD_DATA,
  });
};

export const clearSaveStatus=() => (dispatch) => {
  dispatch({
    type: CLEAR_SAVE_RESPONCE,
  });
};

export const clearEditSaveStatus = () => (dispatch) => {
  dispatch({
    type: CLEAR_EDIT_RESPONCE,
  });
};

export const removeItemPayload = (data) => ({
  type: REMOVING_ITEMS_PAYLOAD,
  payload: data
});

export const clearRoleData= (data) => ({
  type: CLEAR_ROLE_DATA,
  payload: data
});

export const ResetCustomSearch = () => dispatch => {
  dispatch({
    type: RESET_CUSTOM_SEARCH,
  });
};

// Fetch student Roles
const fetchingStudentRole = () => ({
  type: FETCHING_STUDENT_ROLES,
  payload: { isLoading: true },
});

const studentRolesData = responseData => {
  const { error, response } = responseData;
  return {
    type: ROLE_STUDENT_ROLES_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data?.data,
    },
  };
};

export const fetchStudentRoles = payload => async dispatch => {
  dispatch(fetchingStudentRole());

  const requestOptions = {
    service: "insights/userManage/fetch_student_roles",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(studentRolesData({ response }));
  } catch (error) {
    dispatch(studentRolesData({ error: error.message }));
  }
};

export const setUserAvailability = data => dispatch => {
  dispatch({
    type: SET_USER_AVAILABILITY,
    payload: {
      data: data,
    },
  });
};


const fetchingPermissionInfo = () => ({
  type: FETCHING_PERMISSION_INFO,
  payload: { isLoading: true },
});

const permissionInfoData = responseData => {
  const { error, response } = responseData;
  return {
    type: PERMISSION_INFO_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const fetchPermissionInfo = payload => async dispatch => {
  dispatch(fetchingPermissionInfo());

  const requestOptions = {
    service: "insights/userManage/check_course_validity",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(permissionInfoData({ response }));
  } catch (error) {
    dispatch(permissionInfoData({ error: error.message }));
  }
};

export const clearPermissionInfo = () => (dispatch) => {
  dispatch({
    type: CLEAR_PERMISSON_INFO,
  });
};

// canvas student edit
const editingCanvasStudents = () => ({
  type: EDITING_CANVAS_STUDENT,
  payload: { isLoading: true },
});

const editCanvasStudentsData = responseData => {
  const { error, response } = responseData;
  const status = _get(response, 'data.status');
  if (status === 200) {
    showNotification({
      message: "Success",
      description: "Canvas Students edited successfully",
    });
  }
  return {
    type: EDIT_CANVAS_STUDENT_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
    },
  };
};

export const editCanvasStudents = payload => async dispatch => {
  dispatch(editingCanvasStudents());

  const requestOptions = {
    service: "insights/userManage/edit_canvas_students",
    method: "POST",
    authorized: true,
    body: JSON.stringify(payload),
  };

  try {
    const response = await service.make(requestOptions);
    dispatch(editCanvasStudentsData({ response }));
  } catch (error) {
    dispatch(editCanvasStudentsData({ error: error.message }));
  }
};

export const clearEditCanvasStudents = () => (dispatch) => {
  dispatch({
    type: CLEAR_EDIT_CANVAS_STUDENTS
  });
};

export const setRoleDeatails = data => dispatch => {
  dispatch({
    type: SET_ROLE_DEATAILS,
    payload: {
      role: data.role,
      roleType: data.roleType,
      customName: data.customName,
      student: data.student
    },
  });
};

export const clearCoursePayload = () => (dispatch) => {
  dispatch({
    type: CLEAR_COURSE_PAYLOAD,
  });
};

export const clearStudentRoleData = () => (dispatch) => {
  dispatch({
    type: CLEAR_STUDENT_ROLE_LIST,
  });
};