import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectUser = ({
  list,
  loading,
  defaultValue,
  onChange,
  style,
  radioValue,
  disabled
}) => {
  const handleChange = (value) => {
    onChange(JSON.parse(value));
  };
  return (
    <Select
      loading={loading}
      placeholder={defaultValue}
      onChange={handleChange}
      style={style}
      disabled={disabled}
    >
      {list.map((item) => (
        <Option
          key={item.roleId}
          value={JSON.stringify({
            roleId: item.roleId,
            label: item.label,
            role: item.role,
            base_role_type:item.base_role_type,
          })}
        >
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectUser;
