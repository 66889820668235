import React, { useEffect, useState } from "react";
import { Table, Input, Typography } from "antd";

const columns = [
  {
    title: "User ID",
    dataIndex: "user_id",
    key: "user_id",
    className: "table-header",
  },
  {
    title: "User Name",
    dataIndex: "user_profile_name",
    key: "user_profile_name",
    className: "table-header",
  },
];

const EditGroupMembers = ({ list, loading }) => {
  const { Title } = Typography;

  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value ? event.target.value.toLowerCase() : '';
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue.length && list.length) {
      const filtered = list.filter((item) => {
        const roleName = item.user_profile_name.toLowerCase();
        return roleName.includes(searchValue);
      });
      setFilteredData(filtered);
      return;
    }
    setFilteredData(list);
  }, [searchValue, list]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Title level={5} style={{ marginRight: "auto", marginBottom: 16 }}>
          Group Members
        </Title>
        <div style={{ marginRight: 16 }}>
          <Input.Search placeholder="Search Users" onChange={handleChange} />
        </div>
      </div>
      <Table
        style={{ marginBottom: "1px" }}
        loading={loading}
        dataSource={filteredData}
        columns={columns}
        pagination={{ pageSize: 8, total: filteredData.length }}
        className="roleManagement-table"
        rowClassName="roleManagement-row"
      />
    </>
  );
};

export default EditGroupMembers;
