import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'antd';

const { Option } = AutoComplete;

const StudentSearch = ({ selectSudent, handleSearch, users, style }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleSelect = (value, option) => {
    setSelectedStudent(option.key);
    setInputValue('');
  };

  const handleSearchChange = (value) => {
    setInputValue(value);
    handleSearch(value);
  };

  useEffect(() => {
    if (selectedStudent !== null) {
      selectSudent(selectedStudent);
      setSelectedStudent(null);
    }
  }, [selectedStudent, selectSudent]);

  return (
    <AutoComplete
      value={inputValue}
      onSelect={handleSelect}
      onSearch={handleSearchChange}
      style={style}
      placeholder="Search by username or user ID"
    >
      {users.map(user => (
        <Option key={user.userId} value={user.name}>
          {user.name}
        </Option>
      ))}
    </AutoComplete>
  );
};

export default StudentSearch;
