import React, { useState, useEffect } from 'react';
import { Card, Checkbox, List, Row, Divider, Pagination, Col, Select, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import Loader from '../../../../../innovative_student/common/layout/Loader';
import { useSelector } from 'react-redux';
import { getCustomUserSearchPagination } from '../../../../../../selectors/userManagement/userManagement.selector';

const { Option } = Select;
const TransferBoxInput = ({
  inputData,
  inputDataLoading,
  roleList,
  roleListLoading,
  setSelectRole,
  setOutput,
  setPage,
  setSearchValue,
}) => {
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pagination, setPagination] = useState({ next: true, currentPage: 0 });
  const [pageNum, setPagenum] = useState(1);

  const paginationData = useSelector(getCustomUserSearchPagination);

  useEffect(() => {
    setSelectedItems([]);
  }, [inputData]);

  useEffect(() => {
    if (roleList.length > 0) {
      const payload = {
        roleId: roleList[0].roleId,
        label: roleList[0].label,
        role: roleList[0].role,
        base_role_type:roleList[0].base_role_type,
      };
      setSelectedRole(payload);
    }
  }, [roleList]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(e.target.value);
    if (value.length > 2) {
      setPagination({ next: true, currentPage: 0 });
    }
    else if (!value.length){
      setPagination({ next: true, currentPage: 0 });
    }
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const isItemSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );
      if (isItemSelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else return [...prevSelectedItems, item];
    });
  };

  const selectAllCheck = () => {
    return inputData.length > 0 && selectedItems.length === inputData.length;
  };

  const selectAllChange = () => {
    if (selectAllCheck()) {
      setSelectedItems([]);
      return;
    } else {
      const transformedData = inputData.map((item) => ({
        id: item.id,
        name: item.name,
        sis_user_id: item.sis_user_id,
        from_base_role: item.from_base_role,
      }));
      setSelectedItems(transformedData);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedRole(JSON.parse(value));
  };

  useEffect(() => {
    setSelectRole(selectedRole);
  }, [selectedRole]);

  useEffect(() => {
    setOutput(selectedItems);
  }, [selectedItems]);

  const previousClick = () => {
    if (paginationData.previous) {
      setPagenum((prevCount) => prevCount - 1);
      const PageData = {
        currentPage: pageNum,
        previous: paginationData.previous,
      };
      setPagination(PageData);
    }
  };

  const nextClick = () => {
    if (paginationData.next) {
      setPagenum((prevCount) => prevCount + 1);
      const PageData = {
        currentPage: pageNum,
        next: paginationData.next,
      };
      setPagination(PageData);
    }
  };
  const onClickHandlerFirst = () => {
    if (paginationData.previous) {
      setPagenum(0);
      const PageData = {
        currentPage: 0,
        previous: false,
      };
      setPagination(PageData);
    }
  }

  useEffect(() => {
    setPage(pagination);
  }, [pagination]);

  return (
    <div>
      <Card className="transfer-box-card">
        <Row gutter={[8, 8]}>
          <Checkbox
            style={{ marginTop: '-10px' }}
            checked={selectAllCheck()}
            onChange={selectAllChange}
          >
            Select All
          </Checkbox>
        </Row>
        <Divider className="transfer-box-divider" />
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              onChange={handleCategoryChange}
              value={selectedRole.label}
              loading={roleListLoading}
            >
              {roleList.map((item) => (
                <Option
                  key={item.roleId}
                  value={JSON.stringify({
                    roleId: item.roleId,
                    label: item.label,
                    role: item.role,
                    base_role_type:item.base_role_type
                  })}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Search list items"
              onChange={handleSearch}
            />
          </Col>
        </Row>
        <Divider className="transfer-box-divider" />
        <Row gutter={[8, 8]}>
          <Loader isLoading={inputDataLoading} />
          <List
            style={{ height: 400 }}
            className="transfer-box-list"
            dataSource={inputData}
            renderItem={(item) => (
              <List.Item className="transfer-box-list-item">
                <Checkbox
                  value={item.id}
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id
                  )}
                  onChange={() => handleItemClick(item)}
                >
                  {item.name}
                </Checkbox>
              </List.Item>
            )}
          />
        </Row>
        <Divider className="transfer-box-divider" />
        <div style={{ textAlign: 'right' }}>
          <Button className='transferBox-navigation'
            onClick={onClickHandlerFirst}
            disabled={!paginationData.previous}
          >First
          </Button>
          <Button
            className='transferBox-navigation'
            onClick={previousClick}
            disabled={!paginationData.previous}>
            Prev
          </Button>
          <Button
            className='transferBox-navigation'
            onClick={nextClick}
            disabled={!paginationData.next}
          >Next
          </Button>
        </div>
      </Card>
    </div>
  );
};

TransferBoxInput.propTypes = {
  inputData: PropTypes.array.isRequired,
};

export default TransferBoxInput;
