import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const SelectRoleType = ({ list, defaultValue, onChange, style, disabled }) => {

  return (
    <Select
      defaultValue={defaultValue}
      onChange={onChange}
      style={style}
      disabled={disabled}
    >
      {list.map((item, index) => (
        <Option
          key={index}
          value={item}
        >
          {item}
        </Option>
      ))}
    </Select>
  );
};

export default SelectRoleType;

