import React, { useState, useEffect, useMemo } from 'react';
import { Card, Checkbox, Row, Divider, Pagination, Col, Select, Input } from 'antd';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { getEditRoleData } from '../../../../../../selectors/userManagement/userManagement.selector';


const { Option } = Select;

const ALL = "All";

const TransferBoxOutPut = ({ inputData, setOutput, roleList }) => {

  const editRoleData = useSelector(getEditRoleData);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [finalItems, setFinalItems] = useState([]);
  const [Role, setRole] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRole, setSelectedRole] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  useEffect(() => {
    if (Array.isArray(inputData) && inputData.length) {
      const uniqueLabels = Array.from(
        new Map(inputData.map(enrollment => [`${enrollment.from_base_role}|${enrollment.label}`, {
          from_base_role: enrollment.from_base_role,
          label: enrollment.label
        }])).values()
      );
      setRole([{
        from_base_role: "All",
        label: "All"
      }, ...uniqueLabels]);
    }
  }, [inputData, editRoleData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = value => {
    setSelectedRole(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRole === ALL) {
      setFilteredData(inputData);
      return;
    }
    if (!editRoleData) {
      const dropDown = inputData.filter(item => (item.label === selectedRole));
      setFilteredData(dropDown);
      return;
    }
    else {
      const filteredUsers = inputData.filter(user => user.from_base_role.includes(selectedRole));
      setFilteredData(filteredUsers);
      return;
    }
  }, [inputData, selectedRole]);

  useEffect(() => {
    const finalData = filteredData.filter((item) => {
      const name = _get(item, 'name', '').toLowerCase();
      return name.includes(searchValue.toLowerCase());
    });
    setFinalItems(finalData);
  }, [filteredData, searchValue]);

  const handleItemClick = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const isItemSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );
      if (isItemSelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else
        return [...prevSelectedItems, item];
    });
  };

  const selectAllCheck = () => {
    return finalItems.length > 0 && selectedItems.length === finalItems.length;
  };

  const selectAllChange = () => {
    if (selectAllCheck()) {
      setSelectedItems([]);
    } else {
      const transformedData = finalItems.map(item => ({
        id: item.id,
        name: item.name,
        sis_user_id: item.sis_user_id
      }));
      setSelectedItems(transformedData);
    }
  };

  const paginatedData = useMemo(() => {
    return finalItems.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [finalItems, currentPage, pageSize]);

  useEffect(() => {
    setOutput(selectedItems);
  }, [selectedItems]);

  return (
    <div>
      <Card className="transfer-box-card">
        <Row gutter={[8, 8]}>
          <Checkbox
            style={{ marginTop: '-10px' }}
            checked={selectAllCheck()}
            onChange={selectAllChange}
          >
            Select All
          </Checkbox>
        </Row>
        <Divider className="transfer-box-divider" />
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Role"
              onChange={handleCategoryChange}
              value={selectedRole}
            >
              {!editRoleData && Role.map((role, index) => (
                <Option key={role.label} value={role.label}>{role.label}</Option>
              ))}
              {editRoleData && Role.map((role, index) => (
                <Option key={role.from_base_role} value={role.from_base_role}>{role.label}</Option>
              ))}
            </Select>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Search list items"
              onChange={handleSearch}
            />
          </Col>
        </Row>
        <Divider className="transfer-box-divider" />
        <Row gutter={[8, 8]}>
          <div style={{ height: 400, overflow: 'auto', width: '100%' }}>
            {paginatedData.map(item => (
              <div key={item.id} className="transfer-box-list-item">
                <Checkbox
                  value={item.id}
                  checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                  onChange={() => handleItemClick(item)}
                >
                  {item.name}
                </Checkbox>
              </div>
            ))}
          </div>
        </Row>
        <Divider className="transfer-box-divider" />
        <div style={{ textAlign: 'right' }}>
          <Pagination
            className="transfer-box-pagination"
            current={currentPage}
            pageSize={pageSize}
            total={finalItems.length}
            onChange={handlePageChange}
          />
        </div>
      </Card>
    </div>
  );
};

TransferBoxOutPut.propTypes = {
  inputData: PropTypes.array.isRequired,
};

export default TransferBoxOutPut;
