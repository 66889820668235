import React, { useState, useEffect } from "react";
import { Table, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/layout/Loader";
import {
  getUserManagementUserTableLoading,
  getUserManagementUserTablePageSize,
} from "../../../../selectors/userManagement/userManagement.selector";
import { fetchUserTable } from "../../../../actions/userManagement/userManagement.action";
import RawStatus from "../common/statusTableCompornet";

const pageSize = 10;

const UserManagementTable = ({ userList, userListoading, searchValue }) => {
  const dispatch = useDispatch();

  const userManagementUserTableLoading = useSelector(
    getUserManagementUserTableLoading
  );
  const userManagementUserTablePageSize = useSelector(
    getUserManagementUserTablePageSize
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(
      fetchUserTable({
        searchValue: "",
        pageNo: page,
      })
    );
  };

  useEffect(() => {
    if (searchValue.length > 2) {
      dispatch(
        fetchUserTable({
          searchValue: searchValue,
          pageNo: "",
        })
      );
    }
    if (!searchValue.length) {
      dispatch(
        fetchUserTable({
          searchValue: "",
          pageNo: 1,
        })
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setCurrentPage(1);
      setSearchData(userList);
    }
  }, [userList]);

  const paginationConfig = {
    pageSize: pageSize,
    current: currentPage,
    total:
      searchValue.length > 2
        ? searchData.length
        : userManagementUserTablePageSize,
    onChange: handlePageChange,
    style: { marginRight: "20px" },
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "User Name",
      dataIndex: "profileName",
      key: "userId",
    },
    {
      title: "Groups",
      dataIndex: "roleData",
      key: "userId",
      className: "user-manage-col-groups",
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      key: "userId",
      className: "user-manage-col-status",
      render: (value, record) => (
        <RawStatus
          statusValue={value}
          roleUuId={""}
          userId={record.userId}
          tableName={"user"}
        />
      ),
    },
  ];

  return (
    <Card className="userManagement-table">
      <Loader isLoading={userManagementUserTableLoading} />
      <Table
        loading={userListoading}
        dataSource={searchValue.length > 2 ? searchData : userList}
        columns={columns}
        pagination={paginationConfig}
        rowClassName="custom-row"
        scroll={{ x: 1000 }}
      />
    </Card>
  );
};

export default UserManagementTable;
