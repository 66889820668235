import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import _get from "lodash/get";
import _has from "lodash/has";
import _isEmpty from "lodash/isEmpty";
import MetaCellRenderer from "../../common/table/MetaCellRenderer";
import {
  getAdvisorViewStudentFilter,
  makeAdvisorMainDataLoading,
  makeAdvisorMainDataResponse,
  makeAdvisorStudentRoleUuId,
  makeAdvisorStudentSearchValue,
  makeAdvisorStudents,
  makeAdvisorStudentsCount,
  makeAdvisorStudentsLoading,
  makeAdvisorStudentsSearch,
} from '../../../../selectors/innovative/advisorView.selector';
import {
  fetchAdvisorMainData,
  resetAdvisorMainData,
  setStudentPageNumber,
} from '../../../../actions/innovative/advisorView.action';
import AdvisorStatCellRenderContainer from './cellrenderers/AdvisorStatCellRenderContainer';
import CellWithLoader from './cellrenderers/CellWithLoader';
import RiskIndicator from './cellrenderers/RiskIndicator';
import { Link } from "react-router-dom";
import { processWithConcurrency } from '../../../../utils/innovative/advisorHelper';
import AdvisorStudentTable from './advisorStudentTable';

const ADMIN_PATH = "/insights/admin/courses";
const pageSize = 10;
const concurrencyLimit = 1;
const searchLength =2;

const AdvisorView = ({ courseId, selectedTerm, setSearchFunc }) => {

  const parsedUrl = new URL(window.location.href);
  const isAdminPath = parsedUrl.pathname === ADMIN_PATH;
  const dispatch = useDispatch();

  const advisorStudents = useSelector(makeAdvisorStudents);
  const advisorStudentsLoading = useSelector(makeAdvisorStudentsLoading);
  const advisorViewStudentFilter = useSelector(getAdvisorViewStudentFilter);
  const studentData = useSelector(makeAdvisorMainDataResponse);
  const advisorStudentsCount = useSelector(makeAdvisorStudentsCount);
  const advisorStudentSearchValue = useSelector(makeAdvisorStudentSearchValue);
  const advisorStudentsSearch = useSelector(makeAdvisorStudentsSearch);
  const advisorStudentRoleUuId = useSelector(makeAdvisorStudentRoleUuId);

  const [currentPage, setCurrentPage] = useState(1);
  const [studentIds, setStudentIds] = useState([]);
  const [pageCount, setPageCount] = useState('');

  useEffect(() => {
    if (advisorStudentSearchValue.length > 2) {
      setCurrentPage(1);
    }
  }, [advisorStudentSearchValue]);

  useEffect(() => {
    if (advisorStudentRoleUuId) {
      setCurrentPage(1);
    }
  }, [advisorStudentRoleUuId]);

  useEffect(() => {
    const payload = {
      "pageNo": currentPage,
    }
    dispatch(setStudentPageNumber(payload))
  }, [currentPage]);

  const getAdvisorStudentCell = (studentId, data, type) => {
    const average = _get(data, [studentId, type, 'average'], null);
    const score = _get(data, [studentId, type, 'score'], null);
    const color = _get(data, [studentId, type, 'color'], null);
    const isNotLoaded = _get(data, [studentId, "error"], false);
    const isDataAvailable = _has(data, [studentId, type]);
  
    if (isDataAvailable) {
      return {
        id:studentId,
        score: average !== null ? average : score,
        color: color,
        isLoading: false,
        column: type,
        error: false,
      };
    }

    if (!isDataAvailable && !isNotLoaded) {
      return {
        id:studentId,
        score: null,
        color: null,
        isLoading: true,
        column: type,
        error: false,
      };
    }

    if (isNotLoaded) {
      return {
        id:studentId,
        score: null,
        color: null,
        isLoading: true,
        column: type,
        error: true,
      };
    }
  };

  const getColumnDefinition = useCallback(() => {
    return [
      {
        title: "Student Details",
        dataIndex: "studentName",
        render: (value, row) => (
          <MetaCellRenderer
            title={value}
            subtitle={row.studentSisid}
            avatar={row.studentAvatarUrl}
            url={`/insights/teacher/student-profile/${row.studentId}/${courseId}/${selectedTerm}`}
          />
        ),
      },
      {
        title: "",
        render: (value, row) => (
          <Button htmlType="button">
            <Link to={
              isAdminPath
                ? `/insights/admin/teacher/student-profile/${row["studentId"]}/${courseId}/${selectedTerm}`
                : `/insights/teacher/student-profile/${row["studentId"]}/${courseId}/${selectedTerm}`
            }>
              View
            </Link>
          </Button>
        )
      },
      {
        title: "Performance Score",
        dataIndex: 'avgPerformanceScore',
        render: (data, row) => <CellWithLoader
          component={AdvisorStatCellRenderContainer}
          {...getAdvisorStudentCell(row.studentId, studentData, "avgPerformanceScore")}
        />
      },
      {
        title: "Engagement Score",
        dataIndex: 'avgEngagementScore',
        render: (data, row) => <CellWithLoader
          component={AdvisorStatCellRenderContainer}
          {...getAdvisorStudentCell(row.studentId, studentData, "avgEngagementScore")}
        />
      },
      {
        title: "Late Submission",
        dataIndex: 'lateSubmission',
        render: (data, row) => <CellWithLoader
          component={AdvisorStatCellRenderContainer}
          {...getAdvisorStudentCell(row.studentId, studentData, "lateSubmission")}
        />
      },
      {
        title: "Missing Submissions",
        dataIndex: 'missingSubmissions',
        render: (data, row) => <CellWithLoader
          component={AdvisorStatCellRenderContainer}
          {...getAdvisorStudentCell(row.studentId, studentData, "missingSubmissions")}
        />
      },
      {
        title: 'Risk Indicator',
        dataIndex: 'riskIndicator',
        key: 'riskIndicator',
        render: (data, row) => <CellWithLoader
          component={RiskIndicator}
          {...getAdvisorStudentCell(row.studentId, studentData, "riskIndicator")}
        />
      }
    ];
  }, [courseId, selectedTerm, isAdminPath, studentData]);

  useEffect(() => {
    if (
      advisorStudentSearchValue.length > searchLength &&
      advisorStudentsSearch.length > searchLength &&
      advisorStudentsSearch === advisorStudentSearchValue
    ) {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const itemsOnCurrentPage = advisorStudents.slice(startIndex, endIndex);
      setStudentIds(itemsOnCurrentPage);
      setPageCount(advisorStudentsCount);
      const dispatchFetchAdvisorMainData = (payload) => dispatch(fetchAdvisorMainData(payload));
      dispatch(resetAdvisorMainData());
      processWithConcurrency(itemsOnCurrentPage, advisorViewStudentFilter, dispatchFetchAdvisorMainData, concurrencyLimit);
    }
  }, [currentPage, advisorStudents, advisorStudentSearchValue, advisorStudentsSearch, JSON.stringify(advisorViewStudentFilter)]);

  useEffect(() => {
    const dispatchFetchAdvisorMainData = (payload) => dispatch(fetchAdvisorMainData(payload));
    if (!_isEmpty(advisorViewStudentFilter) &&
      studentIds.length > 0 &&
      !advisorStudentSearchValue.length &&
      !advisorStudentsSearch.length) {
      dispatch(resetAdvisorMainData());
      processWithConcurrency(studentIds, advisorViewStudentFilter, dispatchFetchAdvisorMainData, concurrencyLimit);
    }
  }, [JSON.stringify(advisorViewStudentFilter), studentIds, advisorStudentSearchValue, advisorStudentsSearch]);

  useEffect(() => {
    if (
      !advisorStudentSearchValue.length &&
      !advisorStudentsSearch.length
    ) {
      setStudentIds(advisorStudents);
      setPageCount(advisorStudentsCount)
    }
  }, [advisorStudentSearchValue, advisorStudents, advisorStudentsSearch]);

  return (
    <AdvisorStudentTable
      isLoading={advisorStudentsLoading}
      dataSource={studentIds}
      columns={getColumnDefinition()}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: pageCount,
        onChange: (page) => setCurrentPage(page)
      }}
      rowKey="studentId"
    />
  );
};

AdvisorView.propTypes = {
  courseId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedTerm: PropTypes.string,
};

export default AdvisorView;