import React, { useEffect, useState } from "react";
import { Card, Col, Form, Radio, Row, Tabs, Tag ,Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import SelectUser from "./select";
import GroupMembers from "./groupMembers";
import Student from "./student";
import GroupName from "./groupName";
import {
  getEditRoleData,
  getIndividualRoleData,
  getSavePayloadData,
  getSaveRolesLoading,
  getUserManagementRoleList,
  getUserManagementRoleLoading,
  getUserManagementRoleUsersList,
  getUserManagementRoleUsersLoading,
  getIndividualRoleLoading,
  getUpdateRolesLoading,
  getEditCanvasStudentStatusLoading,
} from "../../../../../selectors/userManagement/userManagement.selector";
import {
  fetchRoleUsers,
  setRoleDeatails,
  setSavePayload,
  setUserAvailability,
} from "../../../../../actions/userManagement/userManagement.action";
import SelectRoleType from "./selectRoleType";
import { getBaseInfo } from "../../../../../selectors/dashboardManagement/dashboard.selector";
import ItemTransferBox from "./customTransferComponent/itemTransferBox";
import {
  compareUpdateUsers,
  getChanges,
} from "../../../../../utils/userManagement/userManagement.helper";
import Loader from "../../../../innovative_student/common/layout/Loader";
import {
  ADMIN,
  ADVISOR,
  BY_ROLE,
  BY_USER_NAME,
  CANVAS,
  CANVAS_ROLE,
  CUSTOM,
  CUSTOM_ROLE,
} from "../../../../../utils/userManagement/constant";
import EditGroupMembers from "./editCanvasGroupMembers";

const Details = () => {
  const dispatch = useDispatch();

  const [radioValue, setRadioValue] = useState("canvas-role");
  const [selectedGroupName, setSelectedGroupName] = useState("Select Group");
  const [selectedRoleType, setSelectedRoleType] = useState("Admin");
  const [studentType, setStudentType] = useState("ByUsername");
  const [students, setStudents] = useState([]);
  const [transferBox, setTransferBox] = useState([]);
  const [name, setName] = useState("");
  const [customRoles, setCoustomRoles] = useState([]);
  const [updateRoleUsers, setUpdateRoleUsers] = useState([]);
  const [editStudents, setEditStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [roleName, setRoleName] = useState("");
  const [defaultStudents, setDefaultStudents] = useState([]);
  const [customRoleType, setCustomRoleType] = useState([]);
  const [defaultStudentsRoleType, setDefaultStudentsRoleType] = useState([]);
  const [studentRoleType, setStudentRoleType] = useState([]);
  const [displayName, setDisplayName] = useState("");

  const userManagementRoleList = useSelector(getUserManagementRoleList);
  const userManagementRoleLoading = useSelector(getUserManagementRoleLoading);
  const userManagementRoleUsersList = useSelector(
    getUserManagementRoleUsersList
  );
  const userManagementRoleUsersListLoading = useSelector(
    getUserManagementRoleUsersLoading
  );
  const savePayloadData = useSelector(getSavePayloadData);
  const baseInfo = useSelector(getBaseInfo);
  const editRoleData = useSelector(getEditRoleData);
  const individualRoleData = useSelector(getIndividualRoleData);
  const saveRolesLoading = useSelector(getSaveRolesLoading);
  const individualRoleLoading = useSelector(getIndividualRoleLoading);
  const updateRolesLoading = useSelector(getUpdateRolesLoading);
  const editCanvasStudentStatusLoading = useSelector(getEditCanvasStudentStatusLoading);

  useEffect(() => {
    if (individualRoleData && individualRoleData.display_name) {
      setDisplayName(individualRoleData.display_name);
    }

  }, [JSON.stringify(individualRoleData)]);

  useEffect(() => {
    const payload = {
      role: radioValue,
      roleType: selectedRoleType,
      customName: name,
      student: students,
    };
    dispatch(setRoleDeatails(payload));
  }, [radioValue, selectedRoleType, name, students]);

  useEffect(() => {
    if (
      individualRoleData &&
      individualRoleData.advisorData &&
      individualRoleData.advisorData.selectionType &&
      individualRoleData.advisorData.selectionType == BY_USER_NAME &&
      individualRoleData.advisorData.userDetails
    ) {
      const newUsers = individualRoleData.advisorData.userDetails.map((student) => ({
        id: student.student_id,
        name: student.name,
        sisId: student.sisId,
      }));
      setDefaultStudents(newUsers);
      setStudentType(BY_USER_NAME);
    } else if (
      individualRoleData &&
      individualRoleData.advisorData &&
      individualRoleData.advisorData.selectionType &&
      individualRoleData.advisorData.selectionType == BY_ROLE &&
      individualRoleData.advisorData.userDetails
    ) {
      setDefaultStudentsRoleType(individualRoleData.advisorData.selectedValue);
      const payload = { roleId: individualRoleData.advisorData.selectedValue }
      setStudentRoleType(payload)
      setStudentType(BY_ROLE);
    }
  }, [individualRoleData]);

  useEffect(() => {
    if (
      individualRoleData &&
      individualRoleData.advisorData &&
      individualRoleData.advisorData.userDetails &&
      students.length
    ) {
      setEditStudents(getChanges(individualRoleData.advisorData.userDetails, students));
    }
  }, [individualRoleData, students]);

  useEffect(() => {
    if (individualRoleData && individualRoleData.role_name)
      setRoleName(individualRoleData.role_name);
  }, [individualRoleData]);

  useEffect(() => {
    if (transferBox.length && editRoleData) {
      setUpdateRoleUsers(
        compareUpdateUsers(individualRoleData.userData, transferBox)
      );
    }
  }, [individualRoleData.userData, transferBox]);

  useEffect(() => {
    const uniqueRoles = transferBox.reduce((acc, item) => {
      if (!acc.includes(item.from_base_role)) {
        acc.push(item.from_base_role);
      }
      return acc;
    }, []);
    setCoustomRoles(uniqueRoles);
  }, [transferBox]);

  useEffect(() => {
    if (individualRoleData.role_type) {
      setSelectedRoleType(individualRoleData.role_type);
    }
  }, [individualRoleData.role_type]);

  useEffect(() => {
    if (editRoleData && individualRoleData.section == CANVAS) {
      setRadioValue(CANVAS_ROLE);
      return;
    }
    if (editRoleData && individualRoleData.section == CUSTOM) {
      setRadioValue(CUSTOM_ROLE);
      return;
    }
  }, [editRoleData, individualRoleData.section]);

  useEffect(() => {
    if (Object.keys(savePayloadData).length) {
      dispatch(setSavePayload({}));
    }
  }, [, selectedGroupName, selectedRoleType]);

  useEffect(() => {
    if (
      Object.keys(selectedGroupName) &&
      selectedGroupName.role &&
      !searchValue.length
    ) {
      const payload = {
        role_type: selectedGroupName.role,
        searchValue: "",
        roleId: selectedGroupName.roleId,
        base_role_type: selectedGroupName.base_role_type
      };
      dispatch(fetchRoleUsers(payload));
    } else if (Object.keys(selectedGroupName) && searchValue.length > 2) {
      const payload = {
        role_type: selectedGroupName.role,
        searchValue: searchValue,
        roleId: selectedGroupName.roleId,
        base_role_type: selectedGroupName.base_role_type
      };
      dispatch(fetchRoleUsers(payload));
    }
  }, [selectedGroupName, searchValue]);

  useEffect(() => {
    if (transferBox.length === 0) {
      dispatch(setUserAvailability(true))
    }
    else {
      dispatch(setUserAvailability(false))
    }
  }, [transferBox])

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      selectedRoleType === ADMIN &&
      selectedGroupName.label &&
      selectedGroupName.role &&
      displayName &&
      !editRoleData
    ) {
      const payload = {
        section: CANVAS,
        role_mode: ADMIN,
        enroll_type: selectedGroupName.role,
        roleId: selectedGroupName.roleId,
        stu_list: [],
        selection_type: "",
        custom_role_name: displayName,
        fromBaseRole: selectedGroupName.label,
        base_role_type: selectedGroupName.base_role_type
      };
      dispatch(setSavePayload(payload));
    }
  }, [radioValue, selectedRoleType, selectedGroupName, baseInfo, displayName, editRoleData]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      selectedRoleType === ADVISOR &&
      selectedGroupName.label &&
      selectedGroupName.role &&
      studentType === BY_ROLE &&
      studentRoleType.roleId &&
      displayName &&
      !editRoleData
    ) {
      const payload = {
        section: CANVAS,
        role_mode: ADVISOR,
        enroll_type: selectedGroupName.role,
        roleId: selectedGroupName.roleId,
        stu_list: [],
        selection_type: BY_ROLE,
        custom_role_name: displayName,
        fromBaseRole: selectedGroupName.label,
        base_role_type: selectedGroupName.base_role_type,
        student_group_id: studentRoleType.roleId
      };
      dispatch(setSavePayload(payload));
    }
  }, [radioValue, selectedRoleType, selectedGroupName, baseInfo, studentType, studentRoleType, displayName, editRoleData]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      selectedRoleType === ADVISOR &&
      selectedGroupName.label &&
      selectedGroupName.role &&
      studentType === BY_USER_NAME &&
      students.length &&
      displayName &&
      !editRoleData
    ) {
      const payload = {
        section: CANVAS,
        role_mode: ADVISOR,
        enroll_type: selectedGroupName.role,
        roleId: selectedGroupName.roleId,
        stu_list: students,
        selection_type: BY_USER_NAME,
        custom_role_name: displayName,
        fromBaseRole: selectedGroupName.label,
        base_role_type: selectedGroupName.base_role_type
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    selectedRoleType,
    selectedGroupName,
    baseInfo,
    studentType,
    students,
    displayName,
    editRoleData
  ]);

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      selectedRoleType === ADMIN &&
      name.length &&
      transferBox.length &&
      customRoles.length &&
      !editRoleData
    ) {
      const payload = {
        section: CUSTOM,
        role_mode: ADMIN,
        roleId: "",
        enroll_type: "",
        stu_list: [],
        selection_type: "",
        custom_role_name: name,
        custom_users: transferBox,
        fromBaseRole: customRoles,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    selectedRoleType,
    baseInfo,
    name,
    transferBox,
    customRoles,
    editRoleData,
  ]);

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      selectedRoleType === ADVISOR &&
      studentType === BY_ROLE &&
      name.length &&
      transferBox.length &&
      customRoles.length &&
      !editRoleData &&
      studentRoleType.roleId
    ) {
      const payload = {
        section: CUSTOM,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list: [],
        selection_type: BY_ROLE,
        custom_role_name: name,
        custom_users: transferBox,
        fromBaseRole: customRoles,
        student_group_id: studentRoleType.roleId,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    selectedRoleType,
    baseInfo,
    name,
    transferBox,
    studentType,
    customRoles,
    editRoleData,
    studentRoleType,
  ]);

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      selectedRoleType === ADVISOR &&
      studentType === BY_USER_NAME &&
      name.length &&
      transferBox.length &&
      customRoles.length &&
      students.length &&
      !editRoleData
    ) {
      const payload = {
        section: CUSTOM,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list: students,
        selection_type: BY_USER_NAME,
        custom_role_name: name,
        custom_users: transferBox,
        fromBaseRole: customRoles,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    selectedRoleType,
    baseInfo,
    name,
    transferBox,
    studentType,
    students,
    customRoles,
    editRoleData,
  ]);

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      individualRoleData.role_type === ADMIN &&
      name.length &&
      customRoles.length &&
      editRoleData
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CUSTOM,
        role_mode: ADMIN,
        roleId: "",
        enroll_type: "",
        stu_list: [],
        selection_type: "",
        custom_role_name: name,
        changed_users: updateRoleUsers,
        fromBaseRole: customRoles,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    individualRoleData,
    baseInfo,
    name,
    updateRoleUsers,
    customRoles,
    editRoleData,
  ]);

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      individualRoleData.role_type === ADVISOR &&
      studentType === BY_ROLE &&
      name.length &&
      customRoles.length &&
      editRoleData &&
      studentRoleType.roleId
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CUSTOM,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list: [],
        selection_type: BY_ROLE,
        custom_role_name: name,
        changed_users: updateRoleUsers,
        fromBaseRole: customRoles,
        student_group_id: studentRoleType.roleId,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    individualRoleData,
    baseInfo,
    name,
    updateRoleUsers,
    studentType,
    customRoles,
    editRoleData,
    studentRoleType,
  ]);  

  useEffect(() => {
    if (
      radioValue == CUSTOM_ROLE &&
      individualRoleData.role_type === ADVISOR &&
      studentType === BY_USER_NAME &&
      name.length &&
      customRoles.length &&
      editRoleData &&
      editStudents
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CUSTOM,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list: editStudents.length > 0 ? editStudents : [],
        selection_type: BY_USER_NAME,
        custom_role_name: name,
        changed_users: updateRoleUsers,
        fromBaseRole: customRoles,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    individualRoleData,
    baseInfo,
    name,
    updateRoleUsers,
    studentType,
    customRoles,
    editRoleData,
    editStudents,
  ]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      individualRoleData.role_type === ADVISOR &&
      studentType === BY_USER_NAME &&
      editRoleData &&
      editStudents &&
      displayName
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CANVAS,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list: editStudents,
        selection_type: BY_USER_NAME,
        custom_role_name: displayName,
        fromBaseRole: individualRoleData.role_name,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    individualRoleData,
    studentType,
    editRoleData,
    editStudents,
    displayName,
  ]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      individualRoleData.role_type === ADVISOR &&
      studentType === BY_ROLE &&
      editRoleData &&
      editStudents &&
      studentRoleType.roleId &&
      displayName
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CANVAS,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list:[],
        selection_type: BY_ROLE,
        changed_users: [],
        custom_role_name: displayName,
        fromBaseRole: individualRoleData.role_name,
        student_group_id: studentRoleType.roleId,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    JSON.stringify(individualRoleData),
    studentType,
    editRoleData,
    editStudents,
    studentRoleType,
    displayName,
  ]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      individualRoleData.role_type === ADVISOR &&
      editRoleData &&
      editStudents &&
      studentRoleType.roleId &&
      displayName
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CANVAS,
        role_mode: ADVISOR,
        roleId: "",
        enroll_type: "",
        stu_list:[],
        selection_type:"",
        changed_users: [],
        custom_role_name: displayName,
        fromBaseRole: individualRoleData.role_name,
        student_group_id: studentRoleType.roleId,
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    JSON.stringify(individualRoleData),
    studentType,
    editRoleData,
    editStudents,
    studentRoleType,
    displayName,
  ]);

  useEffect(() => {
    if (
      radioValue == CANVAS_ROLE &&
      individualRoleData.role_type === ADMIN &&
      editRoleData &&
      displayName
    ) {
      const payload = {
        role_uuid: individualRoleData.role_uuid,
        section: CANVAS,
        role_mode: ADMIN,
        roleId: "",
        enroll_type: "",
        stu_list:[],
        selection_type:"",
        changed_users: [],
        custom_role_name: displayName,
        fromBaseRole: individualRoleData.role_name,
        student_group_id: "",
      };
      dispatch(setSavePayload(payload));
    }
  }, [
    radioValue,
    JSON.stringify(individualRoleData),
    editRoleData,
    displayName,
  ]);

  const roleType = [ADMIN, ADVISOR];

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleGroupNameChange = (value) => {
    setSelectedGroupName(value);
  };

  const handleRoleTypeChange = (value) => {
    setSelectedRoleType(value);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const displayNameChange = (event) => {
    const { value } = event.target
    setDisplayName(value);
  }

  return (
    <>
      <Loader
        isLoading={
          saveRolesLoading ||
          individualRoleLoading ||
          updateRolesLoading ||
          editCanvasStudentStatusLoading
        }
      />
      <Card className="role-management-details-switch-card">
        <div className="role-management-details-switch-card-content">
          <Row gutter={[8, 16]}>
            <Col>
              {editRoleData && (
                <Radio.Group
                  onChange={onChange}
                  value={
                    individualRoleData.section == "canvas"
                      ? "canvas-role"
                      : "custom-role"
                  }
                  disabled={editRoleData}
                >
                  <Radio value={"canvas-role"}>Canvas Role</Radio>
                  <Radio value={"custom-role"}>Custom Roles</Radio>
                </Radio.Group>
              )}
              {!editRoleData && (
                <Radio.Group onChange={onChange} defaultValue={"canvas-role"}>
                  <Radio value={"canvas-role"}>Canvas Role</Radio>
                  <Radio value={"custom-role"}>Custom Roles</Radio>
                </Radio.Group>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <Form.Item colon={false} label="Group Name" labelAlign="left">
                {radioValue === CUSTOM_ROLE ? (
                  <GroupName input={setName} roleName={roleName} />
                ) : (
                  <SelectUser
                    radioValue={radioValue}
                    style={{ height: "40px" }}
                    loading={userManagementRoleLoading}
                    list={userManagementRoleList}
                    defaultValue={
                      editRoleData
                        ? individualRoleData.role_name
                        : "Select Group"
                    }
                    onChange={handleGroupNameChange}
                    disabled={editRoleData}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                colon={false}
                label="Role Type"
                labelAlign="left"
                style={{ marginLeft: 16 }}
              >
                {individualRoleData.role_type && (
                  <SelectRoleType
                    list={roleType}
                    defaultValue={individualRoleData.role_type}
                    onChange={handleRoleTypeChange}
                    disabled={editRoleData}
                  />
                )}
                {editRoleData === false && (
                  <SelectRoleType
                    list={roleType}
                    defaultValue={"Admin"}
                    onChange={handleRoleTypeChange}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                colon={false}
                label="Display Name"
                labelAlign="left"
                style={{ marginTop: -10 }}
              >
                <Input
                  value={displayName}
                  onChange={displayNameChange}
                  disabled={radioValue === CUSTOM_ROLE}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="details-second-card">
        <div style={{ position: "relative", minHeight: "100px" }}>
          {!editRoleData &&
            radioValue === CANVAS_ROLE &&
            selectedGroupName === "Select Group" &&
            selectedRoleType === ADMIN && (
              <Tag className="details-second-card-default">
                Please select group to see the list
              </Tag>
            )}
          {radioValue === CANVAS_ROLE &&
            selectedGroupName !== "Select Group" &&
            selectedRoleType === ADMIN &&
            !editRoleData && (
              <GroupMembers
                list={userManagementRoleUsersList}
                loading={userManagementRoleUsersListLoading}
                onChange={handleSearch}
              />
            )}
          {radioValue === CUSTOM_ROLE &&
            selectedRoleType === ADMIN &&
            !editRoleData && (
              <ItemTransferBox
                userList={userManagementRoleUsersList}
                loading={userManagementRoleUsersListLoading}
                setUsers={setTransferBox}
                setCustomRoleType={setCustomRoleType}
              />
            )}
          {radioValue === CUSTOM_ROLE &&
            selectedRoleType === ADVISOR &&
            !editRoleData && (
              <Tabs
                style={{
                  position: "relative",
                  minHeight: "100px",
                  marginTop: "-15px",
                }}
              >
                <Tabs.TabPane
                  tab="Group Members"
                  key="item-1"
                  className="group-members"
                >
                  <ItemTransferBox
                    userList={userManagementRoleUsersList}
                    loading={userManagementRoleUsersListLoading}
                    setUsers={setTransferBox}
                    setCustomRoleType={setCustomRoleType}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Students" key="item-2" className="students">
                  <Student
                    studentType={setStudentType}
                    students={setStudents}
                    defaultStudents={defaultStudents}
                    setStudentRoleType={setStudentRoleType}
                    defaultStudentsRoleType={defaultStudentsRoleType}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
          {radioValue === CUSTOM_ROLE &&
            individualRoleData.role_type === ADMIN && (
              <ItemTransferBox
                userList={userManagementRoleUsersList}
                loading={userManagementRoleUsersListLoading}
                setUsers={setTransferBox}
                setCustomRoleType={setCustomRoleType}
              />
            )}
          {radioValue === CANVAS_ROLE &&
            individualRoleData.role_type === ADMIN && (
              <EditGroupMembers
                list={individualRoleData.userData}
                loading={userManagementRoleUsersListLoading}
              />
            )}
          {radioValue === CUSTOM_ROLE &&
            individualRoleData.role_type === ADVISOR && (
              <Tabs
                style={{
                  position: "relative",
                  minHeight: "100px",
                  marginTop: "-15px",
                }}
              >
                <Tabs.TabPane
                  tab="Group Members"
                  key="item-1"
                  className="group-members"
                >
                  <ItemTransferBox
                    userList={userManagementRoleUsersList}
                    loading={userManagementRoleUsersListLoading}
                    setUsers={setTransferBox}
                    setCustomRoleType={setCustomRoleType}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Students" key="item-2" className="students">
                  <Student
                    studentType={setStudentType}
                    students={setStudents}
                    defaultStudents={defaultStudents}
                    setStudentRoleType={setStudentRoleType}
                    defaultStudentsRoleType={defaultStudentsRoleType}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
          {!editRoleData && radioValue === CANVAS_ROLE && selectedRoleType === ADVISOR && (
            <Tabs
              style={{
                position: "relative",
                minHeight: "100px",
                marginTop: "-15px",
              }}
            >
              <Tabs.TabPane
                tab="Group Members"
                key="item-1"
                className="group-members"
              >
                <GroupMembers
                  list={userManagementRoleUsersList}
                  loading={userManagementRoleUsersListLoading}
                  onChange={handleSearch}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Students" key="item-2" className="students">
                <Student
                  studentType={setStudentType}
                  students={setStudents}
                  setStudentRoleType={setStudentRoleType}
                />
              </Tabs.TabPane>
            </Tabs>
          )}
          {radioValue === CANVAS_ROLE &&
            individualRoleData.role_type === ADVISOR && (
              <Tabs
                style={{
                  position: "relative",
                  minHeight: "100px",
                  marginTop: "-15px",
                }}
              >
                <Tabs.TabPane
                  tab="Group Members"
                  key="item-1"
                  className="group-members"
                >
                  <EditGroupMembers
                    list={individualRoleData.userData}
                    loading={userManagementRoleUsersListLoading}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Students" key="item-2" className="students">
                  <Student
                    studentType={setStudentType}
                    students={setStudents}
                    defaultStudents={defaultStudents}
                    setStudentRoleType={setStudentRoleType}
                    defaultStudentsRoleType={defaultStudentsRoleType}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
        </div>
      </Card>
    </>
  );
};

export default Details;
