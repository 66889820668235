import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from "antd";

const RiskIndicator = ({ value, color }) => {
  return (
    <div className="advisor-riskIndicator">
      <Tag color={color}>{value}</Tag>
    </div>
  );
};

RiskIndicator.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
};

export default RiskIndicator;