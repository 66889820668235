import React, { useEffect, useState } from "react";
import { Input, Tabs, Button, Table, Switch, Divider } from "antd";
import Loader from "../../../../../dashboardCommon/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getCoursePayloadData,
  getUserManagementPermissionsInfoLoading,
} from "../../../../../../selectors/userManagement/userManagement.selector";
import { setCoursePayload } from "../../../../../../actions/userManagement/userManagement.action";

const { TabPane } = Tabs;

const CourseAccessManagement = ({
  courseListData,
  courseListLoading,
  accountId,
  accountName,
  courseList,
  onClose,
}) => {
  const dispatch = useDispatch();
  const coursePayloadData = useSelector(getCoursePayloadData);
  const userManagementPermissionsInfoLoading = useSelector(
    getUserManagementPermissionsInfoLoading
  );

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [switchAccess, setSwitchAccess] = useState([]);
  const [restrictedCourses, setRestrictedCourses] = useState([]);
  const [allowedCourses, setAllowedCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (
      coursePayloadData &&
      coursePayloadData[accountId] &&
      coursePayloadData[accountId].restricted_courses
    ) {
      setRestrictedCourses(coursePayloadData[accountId].restricted_courses);
    } else if (courseList && courseList.length) {
      setRestrictedCourses(courseList);
    }
  }, [coursePayloadData, accountId, courseList]);

  useEffect(() => {
    const initialData = courseListData.map((obj) => ({
      ...obj,
      course_status: !restrictedCourses.some(
        (course) => course.course_id == obj.course_id
      ),
    }));
    setSwitchAccess(initialData);
    setAllowedCourses(initialData.filter((course) => course.course_status));
  }, [courseListData, restrictedCourses]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggle = (checked, course) => {
    const updatedSwitchAccess = switchAccess.map((item) =>
      item.course_id == course.course_id
        ? { ...item, course_status: checked }
        : item
    );
    setSwitchAccess(updatedSwitchAccess);

    if (checked) {
      setAllowedCourses((prev) => [
        ...prev.filter((item) => item.course_id !== course.course_id),
        { ...course, course_status: true },
      ]);
      setRestrictedCourses((prev) =>
        prev.filter((item) => item.course_id != course.course_id)
      );
      return;
    } else {
      setRestrictedCourses((prev) => [
        ...prev.filter((item) => item.course_id != course.course_id),
        { ...course, course_status: false },
      ]);
      setAllowedCourses((prev) =>
        prev.filter((item) => item.course_id != course.course_id)
      );
    }
  };

  const selectAll = () => {
    const updatedSwitchAccess = switchAccess.map((item) => ({
      ...item,
      course_status: true,
    }));
    setSwitchAccess(updatedSwitchAccess);
    setAllowedCourses(updatedSwitchAccess);
    setRestrictedCourses([]);
  };

  const deselectAll = () => {
    const updatedSwitchAccess = switchAccess.map((item) => ({
      ...item,
      course_status: false,
    }));
    setSwitchAccess(updatedSwitchAccess);
    setRestrictedCourses(
      updatedSwitchAccess.map((item) => ({
        ...item,
        course_status: false,
      }))
    );
    setAllowedCourses([]);
  };

  const handleClick = () => {
    const payload = {
      account_id: accountId,
      data: {
        account_id: accountId,
        accountName: accountName,
        restricted_courses: restrictedCourses,
        allowed_courses: allowedCourses,
        selectAll: allowedCourses.length === 0 && restrictedCourses.length === 1 ? false : !allowedCourses.length,
        deSelectAll: !restrictedCourses.length,
        blockedCourse: restrictedCourses,
      },
    };
    dispatch(setCoursePayload(payload));
    onClose();
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterData = (data) =>
    data.filter((item) =>
      item.course_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const columns = [
    {
      dataIndex: "course_name",
      key: "course_id",
    },
    {
      dataIndex: "course_status",
      key: "course_id",
      render: (value, row) => (
        <Switch
          checked={value}
          onChange={(checked) => handleToggle(checked, row)}
        />
      ),
      align: "right",
    },
  ];

  const operations = (
    <>
      <Button type="primary" ghost onClick={selectAll}>
        Deselect All
      </Button>
      <Button
        type="primary"
        ghost
        style={{ marginLeft: 8 }}
        onClick={deselectAll}
      >
        Select All
      </Button>
    </>
  );

  return (
    <>
      <Loader isLoading={courseListLoading} />
      <Input
        placeholder="Search Courses"
        style={{ marginBottom: 16 }}
        value={searchQuery}
        onChange={handleSearch}
      />
      <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
        <TabPane tab="All" key="1">
          <Table
            columns={columns}
            dataSource={filterData(switchAccess)}
            pagination={{
              total: switchAccess.length,
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              style: { marginTop: 16, textAlign: "right" },
            }}
            rowKey="course_id"
            style={{ marginTop: 16 }}
            showHeader={false}
          />
        </TabPane>
        <TabPane tab="Restricted" key="2">
          <Table
            columns={columns}
            dataSource={filterData(restrictedCourses)}
            pagination={{
              total: restrictedCourses.length,
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              style: { marginTop: 16, textAlign: "right" },
            }}
            rowKey="course_id"
            style={{ marginTop: 16 }}
            showHeader={false}
          />
        </TabPane>
        <TabPane tab="Allowed" key="3">
          <Table
            columns={columns}
            dataSource={filterData(allowedCourses)}
            pagination={{
              total: allowedCourses.length,
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              style: { marginTop: 16, textAlign: "right" },
            }}
            rowKey="course_id"
            style={{ marginTop: 16 }}
            showHeader={false}
          />
        </TabPane>
      </Tabs>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 16,
        }}
      >
        {switchAccess.length > 0 && (
          <Button onClick={handleClick} type="primary">
            Update
          </Button>
        )}
      </div>
    </>
  );
};

export default CourseAccessManagement;
