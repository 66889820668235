export const menu = {
  routs:{
    vettrak: "vettrak",
    axcelerate: "axcelerate",
  },
  riskAnalysis: 'risk-analysis',
  settings: 'settings',
  directSettings: '/insights/vettrak/generic/settings',
  axcelerateDirectSettings: '/insights/axcelerate/generic/settings',
};
