import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import menuData from "../../../../layout/dashboardManagement/menuData";
import { setBreadcrumb } from "../../../../../actions/dashboardManagement/layout.action";
import ViewRoleLayout from "./viewRoleLayout";

const UserViewRoleContainer = () => {
  const dispatch = useDispatch();

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
      href: menuData.manageDashboard.url
    }, {
      key: menuData.manageUser.key,
      section: menuData.manageUser.name,
    }, {
      key: menuData.addGroup.key,
      section: menuData.addGroup.name,
    }
  ]

  useEffect(() => {
    dispatch(setBreadcrumb(breadcrumbItems));
  }, [])

  return (
    <ViewRoleLayout/>
  );
}
export default UserViewRoleContainer;