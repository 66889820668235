import React, { useEffect, useState } from 'react';
import { get as _get } from 'lodash';
import { useHistory } from 'react-router';
import { Button, Col, List, Row, Space, Switch } from 'antd';
import PropTypes from 'prop-types';
import { SettingOutlined } from '@ant-design/icons';
import { onError } from '../../../../../utils/notificationHandler';
import impressionHandler from '../../../../../utils/impressionHandler/impressionHandler';
import dashboardConfig from '../../../../../constant/vetTrack/dashboardConfig';
import { menu } from '../../../../../constant/vetTrack/menu';

const CardItem = ({ dashboard, activeChange }) => {
  const [dashboardData, setDashboardData] = useState({});

  const urlPathName = window.location.href;
  const parsedUrl = new URL(urlPathName);
  const hostName = parsedUrl.host;
  const history = useHistory();


  const returnUrl = () => {
    const riskAppUrl = new URL(dashboard.path);
    const riskAppPath = riskAppUrl.pathname;
    history.push(riskAppPath);
  };

  const getDashboardData = () => _get(dashboardConfig, `${dashboard.access_code}`, dashboardConfig.DEFAULT);

  // get dashboard configurations from config file
  useEffect(() => {
    if (dashboard) {
      setDashboardData(getDashboardData());
    }
  }, [dashboard]);

  const getRelevantSettingUrl = () => {
    if (hostName?.includes(menu.routs.vettrak)) {
      return menu.directSettings;
    }
    if (hostName?.includes(menu.routs.axcelerate)) {
      return menu.axcelerateDirectSettings;
    }
    return menu.directSettings;
  }

  const navigateTo = (url, isSettingPage) => {
    if (isSettingPage) {
      history.push(getRelevantSettingUrl());
    } else if (url) {
      impressionHandler
        .sendUserAccessDataAsync({
          dashboard: dashboard.access_code,
          accessCode: dashboard.accessHash,
        })
        .then(() => returnUrl())
        .catch(() => returnUrl());
    } else {
      onError('No Url Specified');
    }
  };

  const renderActiveSwitch = () => {
    if (dashboardData.showActive) {
      return (
        <div className="activeSwitch">
          <Switch
            size="small"
            checked={dashboard.maintenance === false}
            onChange={value => activeChange(dashboard.id, value)}
          />{' '}
          <span>Active</span>
        </div>
      );
    }
  };

  return (
    <List.Item key={dashboard.id} className="vtt-dashboard-list-item">
      <Row gutter={[24, 24]}>
        <Col xs={10} md={8} lg={6} xl={4}>
          <img
            className="image"
            alt="dashboard thumbnail"
            src={dashboard.thumbnail}
            onClick={() => navigateTo(dashboard.path, false)}
          />
        </Col>
        <Col xs={14} md={16} lg={18} xl={20}>
          <List.Item.Meta
            title={dashboard.name}
            className="metaDetails"
            description={
              <span>
                {dashboardData &&
                  <div
                    className="vtt-on-click-div"
                  >
                    {dashboardData.description}
                  </div>
                }

              </span>
            }
          />
          <Row>
            <Col span={20}>
              {renderActiveSwitch()}
            </Col>
            <Col span={4} className="vtt-dashboard-button-group">
              <div>
                <Space>
                  <Button
                    icon={<SettingOutlined />}
                    onClick={() => navigateTo("", true)}
                    size="large"
                  >
                    Settings
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <a href="#_" onClick={() => navigateTo(dashboard.path, false)} className="linkWrap"></a>
    </List.Item>
  );
};

CardItem.propTypes = {
  dashboard: PropTypes.object,
  activeChange: PropTypes.func,
};

export default CardItem;
