import React, { useState, useEffect } from "react";
import { Button, Card, Row, Tag, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CoursePermissionDrawer from "../addPermission/coursePermissionDrawer/coursePermissionDrawer";
import { fetchCourseList } from "../../../../../actions/userManagement/userManagement.action";
import { getCoursePayloadData } from "../../../../../selectors/userManagement/userManagement.selector";
import { filterDataBySelectedAccount } from "../../../../../utils/userManagement/userManagement.helper";

const { Title } = Typography;
const courseLength = 5;

const Account = ({
  accountId,
  accountName,
  individualAccountData,
  setDelete,
}) => {
  const dispatch = useDispatch();

  const [restrictedCourseList, setRestrictedCourseList] = useState([]);
  const [allowedCourseList, setAllowedCourseList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const coursePayloadData = useSelector(getCoursePayloadData);

  useEffect(() => {
    if (accountId && individualAccountData) {
      setCourseList(
        filterDataBySelectedAccount(accountId, individualAccountData)
      );
    }
  }, [individualAccountData, accountId]);

  useEffect(() => {
    if (
      coursePayloadData &&
      coursePayloadData[accountId] &&
      coursePayloadData[accountId].restricted_courses
    ) {
      setRestrictedCourseList(coursePayloadData[accountId].restricted_courses);
    }
  }, [coursePayloadData, accountId]);

  useEffect(() => {
    if (
      coursePayloadData &&
      coursePayloadData[accountId] &&
      coursePayloadData[accountId].allowed_courses
    ) {
      setAllowedCourseList(coursePayloadData[accountId].allowed_courses);
    }
  }, [coursePayloadData, accountId]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    const payload = {
      clickedAccount: [
        {
          selectedAccount: accountId,
          accountName: accountName,
        },
      ],
    };
    dispatch(fetchCourseList(payload));
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const deleteItem = () => {
    const payload = {
      id: accountId,
    };
    setDelete(payload);
  };

  return (
    <>
      <Card className="permission-sub-card">
        <Row className="account-row">
          <Title level={4} className="title-left">
            {accountName}
          </Title>
          <Button
            type="primary"
            ghost
            className="course-access-button"
            onClick={showDrawer}
          >
            Course Access
          </Button>
          <CoursePermissionDrawer
            onClose={onClose}
            open={open}
            accountId={accountId}
            accountName={accountName}
            courseList={restrictedCourseList.length ? [] : courseList}
          />
          <Button
            icon={<DeleteOutlined />}
            className="delete-button"
            onClick={deleteItem}
          />
        </Row>
        <Row>
          <Title level={5} className="title-left">
            Restricted Courses
          </Title>
        </Row>
        <Row>
          <div className="course-tags">
            {(coursePayloadData && coursePayloadData[accountId]
              ? restrictedCourseList
              : courseList
            )
              .slice(0, 5)
              .map((course) => (
                <Tag
                  className="subject-tags"
                  color="default"
                  key={course.course_id}
                >
                  {course.course_name}
                </Tag>
              ))}
            {(coursePayloadData && coursePayloadData[accountId]
              ? restrictedCourseList
              : courseList
            ).length > 5 && (
                <Tag className="subject-tags" color="default">
                  +{" "}
                  {(restrictedCourseList.length
                    ? restrictedCourseList
                    : courseList
                  ).length - courseLength}{" "}
                  more
                </Tag>
              )}
          </div>
        </Row>
      </Card>
    </>
  );
};

export default Account;
