import React, { useCallback, Fragment } from 'react';
import { Button, Form, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsumers } from "../../../../actions/consumer.action";
import menuData from "../../../layout/dashboardManagement/menuData";
import { getSubUserPartnerId } from "../../../../selectors/onboarding/auth.selector";
import _debounce from "lodash/debounce";

const HeaderControls = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const userPartner = useSelector(getSubUserPartnerId);

    const debouncedCall = value => {
        onUserSearch({ value });
    };

    const onUserSearch = useCallback(_debounce(({ value }) => {
        if (value !== undefined) {
            dispatch(fetchConsumers({
                query: {
                    name: value
                }
            }, userPartner))
            return;
        }
        return;
    }, 500), []);

    return (
        <Fragment>

            <Form form={form} name="horizontal_login" layout="inline" size={"large"}>
                <Form.Item style={{ marginTop: 10 }}>
                    <Link to={menuData.manageTenantAdmin.url}>
                        <Button style={{ height: "100%" }}>Stat Management</Button>
                    </Link>
                </Form.Item>
                <Form.Item
                    style={{ marginTop: 10 }}
                    name="searchConsumer">
                    <Input
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                        type="text"
                        placeholder="Search"
                        onChange={e => debouncedCall(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginTop: 10 }}>
                    <Link
                        to={{
                            pathname: "/setup/consumer/create"
                        }}>
                        <Button
                            type="primary"
                            htmlType="button">
                            Add New
                        </Button>
                    </Link>
                </Form.Item>
            </Form>
        </Fragment>
    );
};

export default HeaderControls;