import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Button, Divider, Input } from "antd";
import { getAccountsHierarchyList, getCoursePayloadData } from "../../../../../../selectors/userManagement/userManagement.selector";
import TreeNode from "./treeData";
import { removeItemPayload, setCoursePayload } from "../../../../../../actions/userManagement/userManagement.action";
import { getAccountChanges } from "../../../../../../utils/userManagement/userManagement.helper";

const { Search } = Input;
const ADD = "add";
const REMOVE = "remove";

const AccountSelector = ({ setAccounts, toggleOn, onClose }) => {
  const dispatch = useDispatch();
  const accountsHierarchyList = useSelector(getAccountsHierarchyList);
  const coursePayloadData = useSelector(getCoursePayloadData);

  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    if (toggleOn.length) {
      setSelectedNodes(toggleOn);
    }
  }, [toggleOn]);

  const handleNodeToggle = (action, node) => {
    if (action === ADD) {
      setSelectedNodes((prevSelectedNodes) => [
        ...prevSelectedNodes,
        { title: node.title, id: node.id, course_data: [] },
      ]);
    } else if (action === REMOVE) {
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((selectedNode) => selectedNode.id !== node.id)
      );
    }
  };

  const handleClick = () => {
    const { addedItems, removedItems } = getAccountChanges(
      coursePayloadData,
      selectedNodes
    );
    if (removedItems) {
      removedItems.forEach((deleteItem) => {
        dispatch(removeItemPayload(deleteItem));
      });
    }
    if (addedItems) {
      addedItems.forEach((selectedNodes) => {
        const payload = {
          account_id: selectedNodes.id,
          data: {
            account_id: selectedNodes.id,
            accountName: selectedNodes.title,
            restricted_courses: selectedNodes.course_data,
            allowed_courses: [],
            selectAll: false,
            deSelectAll: true,
            blockedCourse: [],
          },
        };
        dispatch(setCoursePayload(payload));
      });
    }
    setAccounts(selectedNodes);
    onClose();
  };

  return (
    <div>
      <Search placeholder="Search Accounts" style={{ marginBottom: 16 }} />
      <ul className="accountSelector-ul">
        {accountsHierarchyList.map((node) => (
          <TreeNode
            key={node.id}
            node={node}
            selectedNodes={selectedNodes}
            onNodeToggle={handleNodeToggle}
          />
        ))}
      </ul>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 16,
        }}>
        <Button type="primary" onClick={handleClick}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default AccountSelector;
