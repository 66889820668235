import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const EnrollmentStatusFilter = ({ statusList, statusLoading, enrollmentStatusSelect }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!statusLoading && statusList) {
      setSelectedItem(statusList[0]);
      enrollmentStatusSelect(statusList[0]);
    }
  }, [statusList, statusLoading]);

  const handleChange = (value) => {
    setSelectedItem(value);
    enrollmentStatusSelect(value);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  if (!statusList) {
    return <Spin size="small" indicator={antIcon} />;
  }

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Please select"
      value={selectedItem}
      onChange={handleChange}
      notFoundContent={statusLoading ? <Spin size="small" /> : null}
    >
      {statusList.map(item => (
        <Option
          className="select-option-dropdown"
          key={item}
          value={item}
        >
          {item}
        </Option>
      ))}
    </Select>
  );
};

export default EnrollmentStatusFilter;