import React, { useEffect } from "react";
import { Drawer } from "antd";
import { useDispatch } from "react-redux";
import AccountSelector from "./accountSelector";
import { fetchAccountHierarchy } from "../../../../../../actions/userManagement/userManagement.action";

const AccountPermissionDrawer = ({ onClose, open, setAccounts ,toggleOn }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAccountHierarchy());
  }, [dispatch]);

  return (
    <div>
      <Drawer
        className="account-permission-drawer"
        destroyOnClose={true}
        title="Course Access Management"
        placement="right"
        onClose={onClose}
        maskClosable={false}
        width="700px"
        visible={open}
      >
        <AccountSelector setAccounts={setAccounts} toggleOn={toggleOn}
        onClose={onClose} />
      </Drawer>
    </div>
  );
};

export default AccountPermissionDrawer;
