import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Input } from "antd";

const UserManagementSearch = ({ onChange }) => {

  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  return (
    <Input
      onChange={handleChange}
      className="userManagement-search"
      placeholder="Search by username or user ID"
      suffix={
        <SearchOutlined
          style={{
            color: 'rgba(0,0,0,.45)',
          }}
        />
      }
    />
  );
};

export default UserManagementSearch;