import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useDispatch } from "react-redux";
import { setMenuSelected, setBreadcrumb } from "../../../../actions/dashboardManagement/layout.action";
import menuData from "../../../layout/dashboardManagement/menuData";
import UserManagementSearch from "../common/Search";
import UserManagementTableView from "./usersTableView";


const { Title } = Typography;

const UserUsersContainer = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.manageUser.key,
      section: menuData.manageUser.name,
    },
  ];

  useEffect(() => {
    dispatch(setMenuSelected(menuData.userUsers.key));
    dispatch(setBreadcrumb(breadcrumbItems));
  },);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  return (
    <div className="in-container userManagement">
      <div className="userManagement-Header" >
        <Title level={3} style={{ margin: 0 }}>User Management</Title>
        <UserManagementSearch onChange={handleSearchChange} />
      </div>
      <UserManagementTableView
        searchValue={searchTerm}
      />
    </div>
  );
};

export default UserUsersContainer;

