import React, { useState } from "react";
import { Modal, Input, Table, Row, Col, Tooltip, Typography } from "antd";
import { WarningFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  getComplianceNATTableData,
} from '../../../../../../../../../../../../selectors/vetTrack/analysis.selector';

const GridItemModal = ({ visible, onCancel, modalName }) => {
  const { Text } = Typography;
  const [searchText, setSearchText] = useState("");
  const tableData = useSelector(getComplianceNATTableData)

  const renderEmptyCell = (name) => {
    return (
      <Tooltip title={`${name} is empty`} placement="topLeft">
        <WarningFilled  className="empty-cell-icon"/>
        <div className="empty-cell-background" />
      </Tooltip>
    );
  };
  
  const columns = tableData?.data?.list?.length ? Object.keys(tableData?.data?.list[0]).map((name)=>({
    title: name,
    dataIndex: name,
    key: name,
    render: (text) => {
      if(text || Number.isInteger(text)){
        return text; 
      }
      return renderEmptyCell(name);
    }
  })) : []

  const dataSource = tableData?.data?.list?.length ? tableData?.data?.list.map((data, key)=>({
    ...data,
    key
  })) : []

  const filterObjectsByPartialMatch = (array, keyword) => {
    return array.filter((obj) => {
      const stringAttributes = Object.keys(obj).filter(
        (key) => typeof obj[key] === 'string'
      );
  
      for (const key of stringAttributes) {
        if (obj[key].toLowerCase().includes(keyword.toLowerCase())) {
          return true;
        }
      }
        return false;
    });
  }
  

  const filteredData = filterObjectsByPartialMatch(dataSource, searchText)

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={<Text strong>{modalName}</Text>}
      footer={null}
      width="80%"
    >
      <Row gutter={[10, 10]} className="nat-card-container-body">
        <Col lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Input
            placeholder="Search..."
            value={searchText}
            size="large"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col lg={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Table
            loading={tableData?.isLoading}
            columns={columns}
            dataSource={filteredData}
            bordered
            scroll={{ x: "max-content" }}
            className="nat-popup-table"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default GridItemModal;

GridItemModal.propTypes = {
  visible: PropTypes.bool, 
  onCancel: PropTypes.func,
  modalName: PropTypes.string
}