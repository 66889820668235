import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { userManageStatus } from "../../../../../actions/userManagement/userManagement.action";

const { Option } = Select;
const ACTIVE = "Active";
const INACTIVE = "Inactive";

const RawStatus = ({ statusValue, roleUuId, userId, tableName }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(statusValue ? ACTIVE : INACTIVE);

  useEffect(() => {
    setStatus(statusValue ? ACTIVE : INACTIVE);
  }, [statusValue]);

  const handleStatusChange = (value) => {
    setStatus(value);
    if (value == ACTIVE) {
      const payload = {
        status_from: tableName,
        role_uuid: roleUuId,
        main_status: true,
        user_id: userId,
      };
      dispatch(userManageStatus(payload));
    } else {
      const payload = {
        status_from: tableName,
        role_uuid: roleUuId,
        main_status: false,
        user_id: userId,
      };
      dispatch(userManageStatus(payload));
    }
  };

  const selectClassName =
    status === ACTIVE
      ? "userManagement-table-row-status-green"
      : "userManagement-table-row-status-red";

  return (
    <div className={selectClassName}>
      <Select
        className="ant-select-outline-red"
        value={status}
        onChange={handleStatusChange}
        suffixIcon={<CaretDownOutlined />}
      >
        <Option value="Active" style={{ color: "green" }}>
          ● Active
        </Option>
        <Option value="Inactive" style={{ color: "red" }}>
          ● Inactive
        </Option>
      </Select>
    </div>
  );
};

export default RawStatus;
