import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourse,
  fetchCoursesReport,
  resetModuleProgressData,
  resetClusterSection,
} from '../../actions/innovative/course.action';
import { setBreadcrumb, setTopNavParam } from '../../actions/innovative/layout.action';
import { fetchSettings } from '../../actions/innovative/settings.action';
import { makeInnovativeCouserSwitchValues } from '../../selectors/innovative/course.selector';
import session from '../session';
import { getAdvisorViewVisibility } from '../../selectors/innovative/advisorView.selector';

const useCourseHandlers = () => {
  const [switchFormValues, setSwitchFormValues] = useState({ active: true, completed: false });
  const switchValues = useSelector(makeInnovativeCouserSwitchValues);
  const advisorViewVisibility = useSelector(getAdvisorViewVisibility);

  const dispatch = useDispatch();

  // prepare breadcrumb segments / data
  const prepareBreadcrumb = (item) => {
    return [
      {
        key: 'home',
        section: "Home",
      },
      {
        key: item["course_id"],
        section: item["course_name"],
      }
    ];
  }

  // after a selecting a course from the side bar
  // here we need to update the breadcrumb, fetch course details, fetch course report
  const selectCourse = (item) => {
    if (Object.keys(item).length === 0) {
      return;
    }

    const breadCrumb = prepareBreadcrumb(item)
    const requestParams = {
      "course_id": item["course_id"],
      "student_states": Object.keys(switchValues).filter(key => switchValues[key] === true && key),
    };

    dispatch(resetClusterSection());
    dispatch(resetModuleProgressData());
    dispatch(setBreadcrumb(breadCrumb));
    dispatch(fetchCourse({ requestParams }));
    dispatch(fetchCoursesReport({ requestParams }));
    dispatch(fetchSettings({
      requestParams: {
        "subject_id": item["course_id"],
        "school_id": session.get([
          session.keys.innovativeMeta,
          "school_id"
        ]),
      }
    }));
    dispatch(setTopNavParam("settings", {
      courseId: item["course_id"]
    }));
  };

  return {
    selectCourse,
    switchFormValues: switchValues,
    setSwitchFormValues,
  }
}

export default useCourseHandlers;