import React, { useEffect, useState } from "react";
import { Table, Tag, Button, Card } from "antd";
import { useDispatch } from "react-redux";
import { DeleteOutlined, EditOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";
import {
  editRole,
  individualRoleFetch,
  roleDataDelete,
  roleDataSync,
} from "../../../../actions/userManagement/userManagement.action";
import { useHistory } from "react-router";
import menuData from "../../../layout/dashboardManagement/menuData";
import Loader from "../../../innovative_student/common/layout/Loader";
import RawStatus from "../common/statusTableCompornet";
import { CANVAS } from "../../../../utils/userManagement/constant";

const pageSize = 10;

const RoleManagementTable = ({ roleList, roleListLoading, searchValue }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (searchValue) {
      const filtered = roleList.filter(
        (item) =>
          item.role_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.role_uuid.includes(searchValue)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(roleList);
    }
  }, [searchValue, roleList]);

  const handleEditClick = (row) => {
    const payload = {
      role_uuid: row.role_uuid,
    };
    dispatch(individualRoleFetch(payload));
    dispatch(editRole("true"));
    history.push(menuData.addGroup.url);
  };
  const handleViewClick = (row) => {
    const payload = {
      role_uuid: row.role_uuid,
    };
    dispatch(individualRoleFetch(payload));
    history.push(menuData.viewRole.url);
  }; 

  const handleSyncClick = (row) => {
    const payload = {
      role_uuid: row.role_uuid,
      role_mode: row.role_type,
      role_name: row.role_name,
      base_role_type: row.base_role_type,
      section: "canvas",
      syncStatus: true,
      roleId:row.canvas_role_id,
    };
    dispatch(roleDataSync(payload));
  };

  const handleDeleteClick = (row) => {
    const payload = {
      role_uuid: row.role_uuid,
      role_mode: row.role_type,
    };
    dispatch(roleDataDelete(payload));
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = (key, status) => {
    console.log(`User ${key} status changed to ${status}`);
  };

  const paginationConfig = {
    pageSize: pageSize,
    current: currentPage,
    total: filteredData.length,
    onChange: handlePageChange,
    style: { marginRight: "20px" },
  };

  const columns = [
    {
      title: "Role ID",
      dataIndex: "role_custom_id",
      key: "role_custom_id",
      align: "left",
    },
    {
      title: "Role Name",
      dataIndex: "display_name",
      key: "role_custom_id",
    },
    {
      title: "Permission Type",
      dataIndex: "role_type",
      key: "role_custom_id",
    },
    {
      title: "Type",
      dataIndex: "section",
      key: "role_custom_id",
      render: (value, row) => (
        <Tag color={value === "canvas" ? "#FF4D4F" : "#4A44E5"}>
          {value === "canvas" ? "Canvas Roles" : "Internal Role"}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "role_status",
      key: "role_custom_id",
      render: (value, record) => (
        <RawStatus
          statusValue={value}
          roleUuId={record.role_uuid}
          userId={""}
          tableName={"role"}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "section",
      key: "role_custom_id",
      align: "right",
      render: (value, row) => (
        <div className="role-management-action-button-container">
          <Button
            type="primary"
            className="action-button"
            ghost
            icon={<EyeOutlined />}
            onClick={() => handleViewClick(row)}
          />
          {value === CANVAS && (
            <Button
              className="action-button"
              icon={<SyncOutlined />}
              onClick={() => handleSyncClick(row)}
              disabled={!row.role_status}
            />)}
          <Button
            className="action-button"
            icon={<EditOutlined />}
            onClick={() => handleEditClick(row)}
          />
          <Button
            className="action-button"
            danger
            ghost
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(row)}
          />
        </div>
      ),
    },
  ];

  return (
    <Card className="userManagement-table">
      <Loader isLoading={roleListLoading} />
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={paginationConfig}
        scroll={{ x: 1000 }}
      />
    </Card>
  );
};

export default RoleManagementTable;
