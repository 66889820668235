import React from "react";
import { Drawer } from "antd";
import CourseAccessManagement from "./coursePermission";
import { getCourseListData, getCourseListLoading } from "../../../../../../selectors/userManagement/userManagement.selector";
import { useSelector } from "react-redux";

const CoursePermissionDrawer = ({ onClose, open, accountId, accountName, courseList }) => {
  const courseListData = useSelector(getCourseListData);
  const courseListLoading = useSelector(getCourseListLoading);

  return (
    <div>
      <Drawer
        className="account-permission-drawer"
        destroyOnClose={true}
        title="Course Access Management"
        placement="right"
        onClose={onClose}
        maskClosable={false}
        width="700px"
        visible={open}
      >
        <CourseAccessManagement
          courseListData={courseListData}
          courseListLoading={courseListLoading}
          accountId={accountId}
          accountName={accountName}
          courseList={courseList}
          onClose={onClose}
        />
      </Drawer>
    </div>
  );
};

export default CoursePermissionDrawer;
