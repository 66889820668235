import React from 'react';

const UserManagementLayout = ({ children }) => {

  return (
    <div className='gs-wrapper'>
      {children}
    </div>
  );
}

export default UserManagementLayout;
