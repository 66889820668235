import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Form, Input, Radio, Row, Spin, Tabs, Tag } from "antd";
import { useSelector } from "react-redux";
import {
  getIndividualRoleData,
  getIndividualRoleLoading,
  getUserManagementRoleUsersLoading
} from "../../../../../selectors/userManagement/userManagement.selector";
import { Select } from 'antd';
import CustomUserView from "./CustomUserView";
import ViewStudent from "./viewStudent";
import { LoadingOutlined } from "@ant-design/icons";
import { ADMIN, ADVISOR, CANVAS, CUSTOM } from "../../../../../utils/userManagement/constant";
import EditGroupMembers from "../addGroup/editCanvasGroupMembers";

const RoleView = () => {
  const individualRoleData = useSelector(getIndividualRoleData);
  const individualRoleLoading = useSelector(getIndividualRoleLoading);
  const userManagementRoleUsersListLoading = useSelector(
    getUserManagementRoleUsersLoading
  );

  const isCanvasAdvisor = useCallback(() => {
    const value = individualRoleData.role_type === ADMIN &&
      individualRoleData.section === CANVAS ? false : true;
    return value;
  }, [JSON.stringify(individualRoleData)]);

  return (
    <>
      <Spin indicator={<LoadingOutlined/>} spinning={individualRoleLoading}>
        <Card className="role-management-details-switch-card">
          <div className="role-management-details-switch-card-content">
            <Row gutter={[8, 16]}>
              <Col>
                <Radio.Group
                  value={individualRoleData.section == "canvas" ? "canvas-role" : "custom-role"}
                  disabled
                >
                  <Radio value={"canvas-role"}>Canvas Role</Radio>
                  <Radio value={"custom-role"}>Custom Roles</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={[8, 16]}>
              <Col span={15}>
                <Form.Item colon={false} label="Group Name" labelAlign="left">
                  <Select
                    value={individualRoleData.role_name}
                    style={{ height: "40px" }}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  colon={false}
                  label="Role Type"
                  labelAlign="left"
                  style={{ marginLeft: 16 }}
                >
                  <Select
                    value={individualRoleData.role_type}
                    style={{ height: "40px" }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  label="Display Name"
                  labelAlign="left"
                  style={{ marginTop: -10 }}
                >
                  <Input
                    value={individualRoleData.display_name}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="details-second-card">
          <div
            className="details-container"
          >
            <Tabs
              className="tabs-container"
            >
              <Tabs.TabPane
                tab="Group Members"
                key="item-1"
                className="group-members"
              >
                {individualRoleData.section === CANVAS && (
                    <EditGroupMembers
                      list={individualRoleData.userData}
                      loading={userManagementRoleUsersListLoading}
                    />
                  )}
                {individualRoleData.section === CUSTOM && (
                    <CustomUserView
                      inputData={individualRoleData.userData}
                    />
                  )}
              </Tabs.TabPane>
              {individualRoleData.advisorData && Object.keys(individualRoleData.advisorData).length > 0 && (
                <Tabs.TabPane tab="Students" key="item-2" className="students">
                  <ViewStudent inputData={individualRoleData.advisorData} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </Card>
      </Spin>
    </>
  );
};

export default RoleView;
