import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getSelectedRole,
  setRoleUuId,
} from '../../actions/innovative/advisorView.action';
import { setBreadcrumb } from '../../actions/innovative/layout.action';

const useRoleHandlers = () => {
  const [selectedRole, setSelectedRole] = useState({});
  const dispatch = useDispatch();

  const prepareBreadcrumb = (item) => {
    return [
      {
        key: 'home',
        section: "Home",
      },
      {
        key: item["role_id"],
        section: item["role_name"],
      }
    ];
  }

  const selectRole = (item) => {
    if (Object.keys(item).length === 0) {
      setSelectedRole(item);
      return;
    }
    const breadCrumb = prepareBreadcrumb(item);
    dispatch(setBreadcrumb(breadCrumb));
    dispatch(getSelectedRole(item));
    setSelectedRole(item);
    
  };

  useEffect(() => {
    if (selectedRole.role_uuid) {
      const requestParams = {
        "role_uuid": selectedRole.role_uuid,
      };
      dispatch(setRoleUuId(requestParams));
    }
  }, [selectedRole.role_uuid]);

  return { selectRole, selectedRole };
}

export default useRoleHandlers;