import {
  VTT_COMPLIANCE_CARDS_DATA_FETCH,
  VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_STUDENTS_DATA_FETCH,
  VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_OVERVIEW_DATA_FETCH,
  VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
  VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH,
  VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING,
} from '../../../../constant/actionTypes';
import service from '../../../../utils/serviceHandlers/jobReady/customServices/jrGeneric';
import { resolveResponseErrorFormat } from '../../../../components/vetTrack/analysis/utils/general';

/** Fetch compliance cards data * */
// fetch compliance cards data loading
const fetchComplianceCardsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch compliance cards data handler
const fetchComplianceCardsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_CARDS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch compliance cards data
export const fetchComplianceCards = data => dispatch => {
  dispatch(fetchComplianceCardsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskCard`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchComplianceCardsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchComplianceCardsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch compliance students data * */
// fetch compliance students data loading
const fetchComplianceStudentsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch compliance students data handler
const fetchComplianceStudentsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_STUDENTS_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch compliance students data
export const fetchComplianceStudents = data => dispatch => {
  dispatch(fetchComplianceStudentsLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskStudents`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchComplianceStudentsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchComplianceStudentsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch compliance overview data * */
// fetch compliance overview data loading
const fetchComplianceOverviewLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch compliance overview data handler
const fetchComplianceOverviewDataStatus = response => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_OVERVIEW_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch compliance overview data
export const fetchComplianceOverview = data => dispatch => {
  dispatch(fetchComplianceOverviewLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewCharts`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchComplianceOverviewDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchComplianceOverviewDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};

/** Fetch compliance overview duration to expire data * */
// fetch compliance overview duration to expire data loading
const fetchComplianceOverviewDurationToExpireLoading = (
  isLoading = true,
) => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
    payload: { isLoading },
  });
};

// fetch compliance overview duration to expire data handler
const fetchComplianceOverviewDurationToExpireDataStatus = response => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch compliance overview duration to expire data
export const fetchComplianceOverviewDurationToExpire = data => dispatch => {
  dispatch(fetchComplianceOverviewDurationToExpireLoading());

  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewPopups`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchComplianceOverviewDurationToExpireDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchComplianceOverviewDurationToExpireDataStatus(
          resolveResponseErrorFormat(error),
        ),
      );
    });
};

const fetchRiskOverviewPopupsLoading = (isLoading = true) => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING,
    payload: { isLoading },
  });
};

const fetchRiskOverviewPopupsDataStatus = response => dispatch => {
  dispatch({
    type: VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH,
    payload: {
      isLoading: false,
      error: response.error,
      ...(response.data && { data: response.data }),
    },
  });
};

// fetch compliance students data
export const fetchRiskOverviewPopups = data => dispatch => {
  dispatch(fetchRiskOverviewPopupsLoading());
  service
    .make({
      service: `insights/vettrak/generic/risk-analysis/dashboard/fetchRiskOverviewPopups`,
      method: 'POST',
      data,
    })
    .then(response => {
      dispatch(
        fetchRiskOverviewPopupsDataStatus({
          data: response.data,
        }),
      );
    })
    .catch(error => {
      dispatch(
        fetchRiskOverviewPopupsDataStatus(resolveResponseErrorFormat(error)),
      );
    });
};
