import React, { useState, useEffect, useMemo } from 'react';
import { Card, Row, Divider, Pagination, Col, Select, Input, List } from 'antd';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

const { Option } = Select;

const ALL = "All";

const CustomUserView = ({ inputData }) => {
  const [searchValue, setSearchValue] = useState('');
  const [finalItems, setFinalItems] = useState([]);
  const [Role, setRole] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(ALL);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  useEffect(() => {
    if (Array.isArray(inputData) && inputData.length) {
      const uniqueLabels = Array.from(
        new Map(inputData.map(enrollment => [`${enrollment.from_base_role}|${enrollment.label}`, {
          from_base_role: enrollment.from_base_role,
          label: enrollment.label
        }])).values()
      );
      setRole([{
        from_base_role: ALL,
        label: "All"
      }, ...uniqueLabels]);
    }
  }, [inputData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = value => {
    setSelectedRole(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRole === ALL) {
      setFilteredData(inputData);
      return;
    }
    const filteredUsers = inputData.filter(user => user.from_base_role.includes(selectedRole));
    setFilteredData(filteredUsers);
  }, [inputData, selectedRole]);

  useEffect(() => {
    if (filteredData) {
      const finalData = filteredData.filter((item) => {
        const name = _get(item, 'user_profile_name', '').toLowerCase();
        return name.includes(searchValue.toLowerCase());
      });
      setFinalItems(finalData);
    }
  }, [filteredData, searchValue]);


  const paginatedData = useMemo(() => {
    return finalItems.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [finalItems, currentPage, pageSize]);

  return (
    <div>
      <Card className="transfer-box-card">
        <Row gutter={[8, 8]}>
          <Col span={8}>
              <Select
                style={{ width: '100%' }}
                placeholder="Select Role"
                onChange={handleCategoryChange}
                value={selectedRole}
              >
                {Role.map((role) => (
                  <Option key={role.from_base_role} value={role.from_base_role}>{role.label}</Option>
                ))}
              </Select>
          </Col>
          <Col span={16}>
            <Input
              placeholder="Search Names"
              onChange={handleSearch}
              value={searchValue}
            />
          </Col>
        </Row>
        <Divider className="transfer-box-divider" />
        <Row gutter={[8, 8]}>
          <div style={{ height: 450, overflow: 'auto', width: '100%' }}>
            <List
              itemLayout="horizontal"
              dataSource={paginatedData}
              renderItem={item => (
                <List.Item
                  className="transfer-box-list-item"
                >
                  <List.Item.Meta
                    title={item.user_profile_name}
                  />
                </List.Item>
              )}
            />
          </div>
        </Row>
        <Divider className="transfer-box-divider" />
        <div style={{ textAlign: 'right' }}>
          <Pagination
            className="transfer-box-pagination"
            current={currentPage}
            pageSize={pageSize}
            total={finalItems.length}
            onChange={handlePageChange}
          />
        </div>
      </Card>
    </div>
  );
};

CustomUserView.propTypes = {
  inputData: PropTypes.array.isRequired,
};

export default CustomUserView;
