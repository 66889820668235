import React, { useEffect, useState } from "react";
import { Button, Card, notification, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AccountPermissionDrawer from "./accountPermissionDrawer/accountPermissionDrawer";
import {
  deleteCoursePermission,
  fetchCoursePermission,
  removeItemPayload,
  sendPermissionPayload,
  setCoursePayload,
} from "../../../../../actions/userManagement/userManagement.action";
import {
  getCoursePayloadData,
  getCoursePermissionData,
  getSaveRolesStatus,
  getUpdateRolesData,
  getCoursePermissionLoading,
  getSaveCoursePermissionLoading,
  getEditCoursePermissionLoading,
  getUserManagementPermissionsInfoLoading,
  getEditCanvasStudentStatus,
} from "../../../../../selectors/userManagement/userManagement.selector";
import Account from "./permissionAccountCard";
import {
  PemissionFormat,
  filterAndReturnRemovedItems,
  transformData,
} from "../../../../../utils/userManagement/userManagement.helper";
import Loader from "../../../../dashboardCommon/Loader";

const { Title } = Typography;

const Permission = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [individualAccountData, setIndividualAccountData] = useState([]);
  const [toggleOn, setToggleOn] = useState([]);
  const [original, setOriginal] = useState([]);
  const [deleteItem, setDelete] = useState([]);
  const [deletePayload, setDeletePayload] = useState([]);

  const coursePermissionData = useSelector(getCoursePermissionData);
  const coursePayloadData = useSelector(getCoursePayloadData);
  const updateRolesData = useSelector(getUpdateRolesData);
  const saveRolesStatus = useSelector(getSaveRolesStatus);
  const coursePermissionLoading = useSelector(getCoursePermissionLoading);
  const saveCoursePermissionLoading = useSelector(getSaveCoursePermissionLoading);
  const editCoursePermissionLoading = useSelector(getEditCoursePermissionLoading);
  const editCanvasStudentStatus = useSelector(getEditCanvasStudentStatus);
  const userManagementPermissionsInfoLoading = useSelector(getUserManagementPermissionsInfoLoading);

  useEffect(() => {
    const savedData = transformData(individualAccountData);
    savedData.forEach((selectedNodes) => {
      const payload = {
        account_id: selectedNodes.id,
        data: {
          account_id: selectedNodes.id,
          accountName: selectedNodes.title,
          restricted_courses: selectedNodes.course_data,
          allowed_courses: [],
          selectAll: selectedNodes.selectAll,
          deSelectAll: selectedNodes.deSelectAll,
          blockedCourse: selectedNodes.course_data,
        },
      };
      dispatch(setCoursePayload(payload));
    });
  }, [JSON.stringify(individualAccountData)]);

  useEffect(() => {
    if (deletePayload.length && Object.keys(updateRolesData).length) {
      deletePayload.forEach((item) => {
        const payload = {
          consumer_id: updateRolesData.schoolId,
          role_uuid: updateRolesData.data,
          removed_account: item.id,
        };
        dispatch(deleteCoursePermission(payload));
      });
    }
  }, [deletePayload, JSON.stringify(updateRolesData)]);

  useEffect(() => {
    if (deletePayload.length && Object.keys(editCanvasStudentStatus).length) {
      deletePayload.forEach((item) => {
        const payload = {
          consumer_id: editCanvasStudentStatus.schoolId,
          role_uuid: editCanvasStudentStatus.data,
          removed_account: item.id,
        };
        dispatch(deleteCoursePermission(payload));
      });
    }
  }, [deletePayload, JSON.stringify(editCanvasStudentStatus)]);

  useEffect(() => {
    if (deleteItem && deleteItem.id) {
      const filteredArray = accounts.filter(
        (item) => item.id !== deleteItem.id
      );
      setAccounts(filteredArray);
      dispatch(removeItemPayload(deleteItem.id));
    }
  }, [deleteItem]);

  useEffect(() => {
    if (
      Object.keys(coursePayloadData).length &&
      Object.keys(saveRolesStatus).length
    ) {
      dispatch(
        sendPermissionPayload(
          PemissionFormat(
            coursePayloadData,
            saveRolesStatus.schoolId,
            saveRolesStatus.data
          )
        )
      );
    } else if (
      Object.keys(coursePayloadData).length &&
      Object.keys(updateRolesData).length
    ) {
      dispatch(
        sendPermissionPayload(
          PemissionFormat(
            coursePayloadData,
            updateRolesData.schoolId,
            updateRolesData.data
          )
        )
      );
    }
  }, [
    JSON.stringify(coursePayloadData),
    JSON.stringify(updateRolesData),
    JSON.stringify(saveRolesStatus),
  ]);

  useEffect(() => {
    if (
      Object.keys(coursePayloadData).length &&
      Object.keys(editCanvasStudentStatus).length
    ) {
      dispatch(
        sendPermissionPayload(
          PemissionFormat(
            coursePayloadData,
            editCanvasStudentStatus.schoolId,
            editCanvasStudentStatus.data
          )
        )
      );
    } 
  }, [
    JSON.stringify(coursePayloadData),
    JSON.stringify(editCanvasStudentStatus),
  ]);

  useEffect(() => {
    if (
      updateRolesData &&
      typeof updateRolesData === "object" &&
      Object.keys(updateRolesData).length
    ) {
      const payload = {
        consumer_id: updateRolesData.schoolId,
        role_uuid: updateRolesData.data,
      };
      dispatch(fetchCoursePermission(payload));
    }
  }, [JSON.stringify(updateRolesData)]);

  useEffect(() => {
    if (
      editCanvasStudentStatus &&
      typeof editCanvasStudentStatus === "object" &&
      Object.keys(editCanvasStudentStatus).length
    ) {
      const payload = {
        consumer_id: editCanvasStudentStatus.schoolId,
        role_uuid: editCanvasStudentStatus.data,
      };
      dispatch(fetchCoursePermission(payload));
    }
  }, [JSON.stringify(editCanvasStudentStatus)]);

  useEffect(() => {
    if (coursePermissionData && coursePermissionData.data) {
      setIndividualAccountData(coursePermissionData.data);
    }
  }, [JSON.stringify(coursePermissionData)]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setToggleOn(accounts);
  }, [accounts]);

  useEffect(() => {
    setToggleOn(transformData(individualAccountData));
    setAccounts(transformData(individualAccountData));
    setOriginal(transformData(individualAccountData));
  }, [individualAccountData]);

  useEffect(() => {
    if (original && accounts) {
      const { newArray1, removedItems } = filterAndReturnRemovedItems(
        original,
        accounts
      );
      setOriginal(newArray1);
      setDeletePayload(removedItems);
    }
  }, [accounts]);

  return (
    <>
      <Loader isLoading={coursePermissionLoading || saveCoursePermissionLoading ||
        editCoursePermissionLoading || userManagementPermissionsInfoLoading} />
      <Card className="permission-main-card">
        <div className="header-container">
          <Title level={4}>Permission</Title>
          <div className="add-button-container">
            <Button type="primary" className="add-button" onClick={showDrawer}>
              + Add
            </Button>
          </div>
          <AccountPermissionDrawer
            onClose={onClose}
            open={open}
            setAccounts={setAccounts}
            toggleOn={toggleOn}
          />
        </div>
        <div className="account-container">
          {accounts &&
            accounts.map((permission) => (
              <div className="account-list" key={permission.id}>
                <Account
                  accountId={permission.id}
                  accountName={permission.title}
                  individualAccountData={individualAccountData}
                  setDelete={setDelete}
                />
              </div>
            ))}
        </div>
      </Card>
    </>
  );
};

export default Permission;
