import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

const GroupName = ({ input, roleName }) => {
  const [inputValue, setInputValue] = useState(roleName);

  useEffect(() => {
    if (roleName.length) {
      setInputValue(roleName);
      input(roleName);
    }
  }, [roleName]);

  const handleInputChange = (event) => {
    const { value } = event.target
    setInputValue(value);
    input(value);
  };

  return (
    <Input
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

export default GroupName;