import React, {useMemo} from "react";
import { Card } from "antd";
import PropTypes from "prop-types";
import viewMore from '../../../../../../../../../../../../assets/images/vettrack/analysis/icon/arrow-right-circle.png'

const GridItem = ({ name, missingCount, percentage, handleClick }) => {

  const styles = useMemo(() => ({
    width: `${percentage}%`,
  }), [percentage]);

  return (
    <Card bodyStyle={{ padding: 10 }} onClick={() =>handleClick(name)} className="nat-card-container nat-card">
      <div className='nat-card-body'>
        <div className='nat-card-body-title'>
          <span>{name}</span>
          <img className="nat-card-right-icon" src={viewMore} alt="view-more" />
        </div>
        <div className="nat-card-missing-count">{missingCount}</div>
        <div className="nat-card-presentage">{percentage}%</div>
      </div>
      <div className='nat-progress-bar' style={styles}></div>
      <div className="nat-horizontal-line"></div>
    </Card>
  );
};

export default GridItem;

GridItem.propTypes = {
  name: PropTypes.string, 
  missingCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
  handleClick: PropTypes.func
}