import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

const AdvisorSearch = ({ SearchFun }) => {
  const [query, setQuery] = useState('');

  const debouncedSearch = useCallback(
    debounce((query) => {
      SearchFun(query);
    }, 2000),
    []
  );

  const onSearchChanged = (e) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };

  return (
    <Form className="courseSearch">
      <Form.Item>
        <Input
          size="large"
          addonAfter={<SearchOutlined />}
          value={query}
          onChange={onSearchChanged}
          placeholder="Search..."
        />
      </Form.Item>
    </Form>
  );
};

AdvisorSearch.defaultProps = {
  placeholder: "Search",
};

AdvisorSearch.propTypes = {
  SearchFun: PropTypes.func,
};

export default AdvisorSearch;