import React, { Fragment, memo } from 'react';
import PropTypes from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IndividualReload from './IndividualReload';

const CellWithLoader = ({
  id,
  score,
  color,
  isLoading,
  component: Component,
  column,
  error
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let content;

  if (isLoading && !error && column === "lateSubmission") {
    return content = <Spin className={'in-loader'} indicator={antIcon} />;

  }
  if (isLoading && !error) {
    return content = null;

  }
  if (!isLoading && !error) {
    return content = <Component value={score} color={color} />;

  }
  if (error && column === "lateSubmission") {
    return content = <IndividualReload
      studentId={id}
    />
  }

  return (
    <>
      {content}
    </>
  );
};

CellWithLoader.propTypes = {
  score: PropTypes.number,
  color: PropTypes.string,
  component: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  column: PropTypes.string,
};

export default memo(CellWithLoader);