import {
  FETCH_ADVISOR_VISIBILITY,
  FETCH_ADVISOR_VISIBILITY_LOADING,
  UPDATE_ADVISOR_VIEW_VISIBILITY,
  SELECTED_ROLE,
  FETCH_ROLE_LIST,
  FETCH_ROLE_LIST_LOADING,
  ADVISOR_FILTERS,
  ADVISOR_FILTERS_LOADING,
  FETCH_ADVISOR_STUDENT,
  ADVISOR_STUDENT_LOADING,
  RESET_ADVISOR_STUDENT,
  SET_ADVISOR_STUDENT_FILTERS,
  RESET_ADVISOR_STUDENT_FILTERS,
  ADVISOR_MAIN_DATA_LOADING,
  FETCH_ADVISOR_MAIN_DATA,
  RESET_ADVISOR_MAIN_DATA,
  ADVISOR_TERMS_LOADING,
  ADVISOR_TERMS,
  SET_STUDENT_PAGE_NUM,
  SET_ROLE_UUID,
  SET_STUDENT_SEARCH_VALUE,
  SET_All_COURES_LOADING_VALUE,
} from "../../constant/actionTypes";
import request from "../../utils/request";
import { getAccessToken } from "../../utils/general";

// AdvisorView visibility Status
export const fetchAdvisorVisibilityStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: FETCH_ADVISOR_VISIBILITY,
    payload: {
      isLoading,
      error,
      data: response.response.data,
    },
  });
};

export const fetchingAdvisorVisibilityData = () => dispatch => {
  dispatch({
    type: FETCH_ADVISOR_VISIBILITY_LOADING,
    payload: {
      isLoading: true
    },
  });
};

export const fetchAdvisorVisibility = data => dispatch => {
  dispatch(fetchingAdvisorVisibilityData());
  const feedReducer = response => {
    dispatch(fetchAdvisorVisibilityStatus({ isLoading: false, response }));
  };

  const requestOptions = {
    service: `insights/advisorProfile/fetch_advisor_visibility/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

// updates AdvisorView visibility
export const updateAdvisorViewVisibility = data => dispatch => {
  dispatch({
    type: UPDATE_ADVISOR_VIEW_VISIBILITY,
    payload: {
      isVisible: data,
    },
  });
};

// selected role data
export const getSelectedRole = data => dispatch => {
  dispatch({
    type: SELECTED_ROLE,
    payload: {
      data
    },
  });
};

// fetch role list
export const fetchRolesStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: FETCH_ROLE_LIST,
    payload: {
      isLoading,
      error,
      data: response,
    },
  });
};

export const fetchingAdvisorRolesData = () => dispatch => {
  dispatch({
    type: FETCH_ROLE_LIST_LOADING,
    payload: {
      isLoading: true
    },
  });
};

export const fetchAdvisorRoles = data => dispatch => {
  dispatch(fetchingAdvisorRolesData());
  const feedReducer = response => {
    dispatch(fetchRolesStatus({ isLoading: false, response }));
  };

  const requestOptions = {
    service: `insights/advisorProfile/fetch_roles/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

// advisor filters
export const advisorFiltersData = () => dispatch => {
  dispatch({
    type: ADVISOR_FILTERS_LOADING,
    payload: {
      isLoading: true,
    },
  });
};

export const setAdvisorFiltersStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: ADVISOR_FILTERS,
    payload: {
      isLoading,
      error,
      data: response,
    },
  });
};

export const fetchAdvisorFilters = data => dispatch => {
  dispatch(advisorFiltersData());

  const feedReducer = (response) => {
    dispatch(setAdvisorFiltersStatus({ isLoading: false, response }));
  };

  const requestOptions = {
    service: `insights/advisorProfile/filter_data/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

// advisor terms
export const advisorTermData = () => dispatch => {
  dispatch({
    type: ADVISOR_TERMS_LOADING,
    payload: {
      isLoading: true,
    },
  });
};

export const setAdvisorTermStatus = data => dispatch => {
  const { isLoading, error, response } = data;
  dispatch({
    type: ADVISOR_TERMS,
    payload: {
      isLoading,
      error,
      data: response,
    },
  });
};

export const fetchAdvisorTermFilters = data => dispatch => {
  dispatch(advisorTermData());

  const feedReducer = (response) => {
    dispatch(setAdvisorTermStatus({ isLoading: false, response }));
  };

  const requestOptions = {
    service: `insights/advisorProfile/term_data/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

// fetch advisor student
export const advisorStudentData = () => dispatch => {
  dispatch({
    type: ADVISOR_STUDENT_LOADING,
    payload: {
      isLoading: true,
    },
  });
};

export const fetchingAdvisorStudent = data => dispatch => {
  const { error, response } = data;
  dispatch({
    type: FETCH_ADVISOR_STUDENT,
    payload: {
      isLoading: false,
      error,
      data: response,
    },
  });
};

export const fetchAdvisorStudent = data => dispatch => {
  dispatch(advisorStudentData());

  const feedReducer = (response) => {
    dispatch(fetchingAdvisorStudent({ isLoading: false, response }));
  };

  const requestOptions = {
    service: `insights/advisorProfile/fetch_students/${getAccessToken()}`,
    method: "POST",
    authorized: true,
    data: data,
    onSuccess: feedReducer,
    onError: feedReducer,
  };

  request.make(requestOptions);
};

// reset advisor students
export const resetAdvisorStudent = () => dispatch => {
  dispatch({
    type: RESET_ADVISOR_STUDENT,
  });
};

// set student filters
export const setAdvisorStudentFilters = data => dispatch => {
  dispatch({
    type: SET_ADVISOR_STUDENT_FILTERS,
    payload: {
      data: data,
    },
  });
};

// reset advisor student filters
export const resetAdvisorStudentFilters = () => dispatch => {
  dispatch({
    type: RESET_ADVISOR_STUDENT_FILTERS,
  });
};

// fetch main data
export const advisorMainDataLoading = data => dispatch => {
  dispatch({
    type: ADVISOR_MAIN_DATA_LOADING,
    payload: {
      isLoading: true,
      studentId: data?.user_id,
    },
  });
};

export const fetchingAdvisorMainData = (responseData, data) => dispatch => {
  const { error, response } = responseData;
  dispatch({
    type: FETCH_ADVISOR_MAIN_DATA,
    payload: {
      isLoading: false,
      error,
      data: response?.data,
      studentId: data?.user_id,
    },
  });
};

export const fetchAdvisorMainData = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(advisorMainDataLoading(data));

    const feedReducer = (response) => {
      dispatch(fetchingAdvisorMainData(response, data));
      resolve(response);
    }

    const requestOptions = {
      service: `insights/advisorProfile/get_main_data/${getAccessToken()}`,
      method: "POST",
      authorized: true,
      data: data,
      onSuccess: feedReducer,
      onError: (error) => {
        feedReducer(error);
        reject(error);
      },
    };

    request.make(requestOptions);
  });
};
// reset main data
export const resetAdvisorMainData = () => dispatch => {
  dispatch({
    type: RESET_ADVISOR_MAIN_DATA,
  });
};

// set Role UuId
export const setRoleUuId = data => dispatch => {
  dispatch({
    type: SET_ROLE_UUID,
    payload: {
      data: data,
    },
  });
};

// set Student page number
export const setStudentPageNumber = data => dispatch => {
  dispatch({
    type: SET_STUDENT_PAGE_NUM,
    payload: {
      data: data,
    },
  });
};

export const setStudentSearchValue = data => dispatch => {
  dispatch({
    type: SET_STUDENT_SEARCH_VALUE,
    payload: {
      data: data,
    },
  });
};

export const setAllCouresLoadingValue = data => dispatch => {
  dispatch({
    type: SET_All_COURES_LOADING_VALUE,
    payload: {
      data: data,
    },
  });
};