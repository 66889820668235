import React, { useEffect } from 'react';
import AdvisorView from './advisorView'
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  makeAdvisorStudentPageNo,
  makeAdvisorStudentRoleUuId,
  makeAdvisorStudentSearchValue,
} from '../../../../selectors/innovative/advisorView.selector';
import { fetchAdvisorStudent, resetAdvisorMainData } from '../../../../actions/innovative/advisorView.action';

const searchLength =2;

const AdvisorGrid = ({ courseId, selectedTerm }) => {
  const dispatch = useDispatch();

  const advisorStudentRoleUuId = useSelector(makeAdvisorStudentRoleUuId);
  const advisorStudentPageNo = useSelector(makeAdvisorStudentPageNo);
  const advisorStudentSearchValue = useSelector(makeAdvisorStudentSearchValue);

  useEffect(() => {
    if (advisorStudentRoleUuId && advisorStudentPageNo && !advisorStudentSearchValue) {
      const payload = {
        role_uuid: advisorStudentRoleUuId,
        pageNo: advisorStudentPageNo,
      }
      dispatch(fetchAdvisorStudent(payload));
    }

  }, [advisorStudentRoleUuId, advisorStudentPageNo, advisorStudentSearchValue]);

  useEffect(() => {
    if (advisorStudentRoleUuId && advisorStudentSearchValue.length > searchLength) {
      const payload = {
        role_uuid: advisorStudentRoleUuId,
        pageNo: "",
        search_value: advisorStudentSearchValue,
      }
      dispatch(fetchAdvisorStudent(payload));
    }

  }, [advisorStudentRoleUuId, advisorStudentSearchValue]);

  return (
    <>
      <AdvisorView
        courseId={courseId}
        selectedTerm={selectedTerm}
      />
    </>
  );
};
AdvisorView.propTypes = {
  courseId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedTerm: PropTypes.string,
};
export default AdvisorGrid;
