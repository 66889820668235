import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setMenuSelected, setBreadcrumb } from "../../../../actions/dashboardManagement/layout.action";
import menuData from "../../../layout/dashboardManagement/menuData";
import RoleManagementTableView from "./roleTableView";
import { useHistory } from "react-router";
import UserManagementSearch from "../common/Search";
import {
  clearEditCanvasStudents,
  clearPermissionInfo,
  clearRoleData,
  clearStudentRoleData,
  fetchRolesTable,
  ResetCoursePayload,
  ResetCoursePermissionData,
  resetCoursePermissionPayload,
  ResetCustomSearch,
  resetPayloadData,
  ResetRoleUsers,
  resetSaveData,
} from "../../../../actions/userManagement/userManagement.action";
import {
  getDeleteRoleData,
  getDeleteRoleDataLoading,
  getSyncRoleDataLoading,
  getUserManageStaus,
  getUserManageStausLoading,
} from "../../../../selectors/userManagement/userManagement.selector";
import { useSelector } from 'react-redux';
import Loader from "../../../dashboardCommon/Loader";
import { get as _get } from 'lodash';

const { Title } = Typography;

const UserRolesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  const deleteRoleData = useSelector(getDeleteRoleData);
  const syncRoleDataLoading = useSelector(getSyncRoleDataLoading);
  const deleteRoleDataLoading = useSelector(getDeleteRoleDataLoading);
  const userManageStausLoading = useSelector(getUserManageStausLoading);
  const userManageStaus = useSelector(getUserManageStaus);

  const breadcrumbItems = [
    {
      key: 'admin',
      section: "Admin",
    },
    {
      key: menuData.manageUser.key,
      section: menuData.manageUser.name,
    },
  ];

  useEffect(() => {
    const status = _get(deleteRoleData, 'status');
    if (status === 200) {
      dispatch(fetchRolesTable());
    }
  }, [deleteRoleData]);
  
  useEffect(() => {
    if (userManageStaus.status === 200) {
      dispatch(fetchRolesTable());
    }
  }, [userManageStaus]);

  const addGroupPanel = () => {
    history.push(menuData.addGroup.url);
  }

  useEffect(() => {
    dispatch(setMenuSelected(menuData.userRoles.key));
    dispatch(setBreadcrumb(breadcrumbItems));
    dispatch(resetSaveData());
    dispatch(clearRoleData());
    dispatch(resetPayloadData());
    dispatch(clearRoleData());
    dispatch(ResetCoursePayload());
    dispatch(resetCoursePermissionPayload());
    dispatch(ResetCoursePermissionData());
    dispatch(ResetRoleUsers());  
    dispatch(ResetCustomSearch());
    dispatch(clearPermissionInfo());
    dispatch(clearEditCanvasStudents());
    dispatch(clearStudentRoleData());
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div className="in-container userManagement">
      <Loader isLoading={syncRoleDataLoading || deleteRoleDataLoading || userManageStausLoading} />
      <div className="userManagement-Header">
        <Title level={3}>Role Management</Title>
        <div className="roleManagement-search-addGroup-container">
          <UserManagementSearch onChange={handleSearch} />
          <Button className="roleManagement-add-group-button"
            type="primary"
            onClick={addGroupPanel}
          >Add Group</Button>
        </div>
      </div>
      <RoleManagementTableView
        searchValue={searchValue}
      />
    </div>
  );
};

export default UserRolesContainer;