import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import menuData from "../../../../layout/dashboardManagement/menuData";
import Sidebar from "../addGroup/sideBar";
import RoleView from "./roleView";


const items = [
  { key: menuData.details.key, title: menuData.details.name },
];

const ViewRoleLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Title } = Typography;
  const [activeTab, setActiveTab] = useState("details");

  const handleMenuClick = (key) => {
    setActiveTab(key);
  };

  const handleBack = () => {
    history.push(menuData.userRoles.url);
  };

  return (
    <>
      <Sidebar
        title="Add Group"
        activeTab={activeTab}
        items={items}
        onMenuClick={handleMenuClick}
      />
      <div className="role-management-details-container">
        <RoleView/>
      </div>
      <Card className="role-management-details-submit-card">
        <div className="role-management-details-submit-card-button-container">
          <Button
            onClick={handleBack}
          >Back
          </Button>
        </div>
      </Card>
    </>
  );
};

export default ViewRoleLayout;
