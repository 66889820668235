import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRoleStudent,
  fetchStudentRoles,
} from "../../../../../actions/userManagement/userManagement.action";
import {
  getUserManagementRoleStudentList,
  getUserManagementRoleStudentListPagination,
  getUserManagementRoleStudentLoading,
  getUserManagementStudentRoles,
} from "../../../../../selectors/userManagement/userManagement.selector";
import {
  BY_ROLE,
  BY_USER_NAME,
} from "../../../../../utils/userManagement/constant";
import { Button, Input, Row, Select, Spin, Tag, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const InputGroup = Input.Group;
const { Title } = Typography;

const ViewStudent = ({ inputData }) => {
  const dispatch = useDispatch();

  const [pageNum, setPagenum] = useState(1);
  const [studentRole, setStudentRole] = useState([]);
  const [byRoleData, setByRoleData] = useState({});

  const userManagementRoleStudentList = useSelector(
    getUserManagementRoleStudentList
  );
  const userManagementRoleStudentListPagination = useSelector(
    getUserManagementRoleStudentListPagination
  );
  const userManagementRoleStudentLoading = useSelector(
    getUserManagementRoleStudentLoading
  );
  const userManagementStudentRoles = useSelector(getUserManagementStudentRoles);

  useEffect(() => {
    setStudentRole(inputData.selectionType);
  }, [inputData]);

  useEffect(() => {
    if (inputData.selectionType === BY_ROLE) {
      dispatch(fetchStudentRoles());
    }
  }, []);

  useEffect(() => {
    if (
      inputData.selectionType === BY_ROLE &&
      userManagementStudentRoles.length
    ) {
      const role = userManagementStudentRoles.find(
        (item) => item.roleId === inputData.selectedValue
      );
      if (role) {
        setByRoleData(role);
        return;
      }
        setByRoleData({});
    }
  }, [inputData, JSON.stringify(userManagementStudentRoles)]);

  useEffect(() => {
    if ((inputData.selectionType === BY_ROLE)) {
      const payload = {
        id: inputData.selectedValue,
        base_role_type: "StudentEnrollment",
        pagination: { next: true, currentPage: 0 },
      };
      dispatch(fetchRoleStudent(payload));
    }
  }, [inputData]);

  const previousClick = () => {
    if (userManagementRoleStudentListPagination.previous) {
      setPagenum((prevCount) => prevCount - 1);
      const Payload = {
        id: byRoleData.roleId,
        base_role_type: byRoleData.base_role_type,
        pagination: {
          currentPage: pageNum,
          previous: userManagementRoleStudentListPagination.previous,
        },
      };
      dispatch(fetchRoleStudent(Payload));
    }
  };

  const nextClick = () => {
    if (userManagementRoleStudentListPagination.next) {
      setPagenum((prevCount) => prevCount + 1);
      const Payload = {
        id: byRoleData.roleId,
        base_role_type: byRoleData.base_role_type,
        pagination: {
          currentPage: pageNum,
          next: userManagementRoleStudentListPagination.next,
        },
      };
      dispatch(fetchRoleStudent(Payload));
    }
  };

  const onClickHandlerFirst = () => {
    if (userManagementRoleStudentListPagination.previous) {
      setPagenum(0);
      const Payload = {
        id: byRoleData.roleId,
        base_role_type: byRoleData.base_role_type,
        pagination: {
          currentPage: 0,
          previous: false,
        },
      };
      dispatch(fetchRoleStudent(Payload));
    }
  };

  return (
    <div>
      <Row>
        <Title level={5}>Students</Title>
      </Row>
      <Row>
        <InputGroup compact>
          <Select
            defaultValue={inputData.selectionType}
            style={{ width: "25%" }}
            disabled={true}
          >
            <Option value="ByUsername">By Username</Option>
            <Option value="ByRole">By Role</Option>
          </Select>
          {studentRole === BY_USER_NAME ? (
            <Input style={{ width: "75%" }} disabled={true} />
          ) : (
            <Input
              style={{ width: "75%" }}
              disabled={true}
              value={byRoleData.label}
            />
          )}
        </InputGroup>
      </Row>
      <div className="user-manage-scroll-container">
        <div className="user-manage-spinner">
          <Spin
            indicator={<LoadingOutlined spin />}
            size="small"
            spinning={userManagementRoleStudentLoading}
          />
        </div>
        {studentRole === BY_USER_NAME &&
          inputData.userDetails.map((user) => (
            <Tag
              color="#108ee9"
              key={user.student_id}
              className="student-tag"
            >
              {user.name}
              {user.sisId ? ` (${user.sisId})` : ""}
            </Tag>
          ))}
        {inputData &&
          inputData.selectionType === BY_ROLE &&
          userManagementRoleStudentList.map((user) => (
            <Tag
              color="#108ee9"
              key={user.sis_user_id}
              className="student-tag"
            >
              {user.name}
              {user.sis_user_id ? ` (${user.sis_user_id})` : ""}
            </Tag>
          ))}
        {inputData &&
          inputData.selectionType === BY_ROLE &&
          userManagementRoleStudentList.length > 0 && (
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                className="studentBox-navigation"
                onClick={onClickHandlerFirst}
                disabled={!userManagementRoleStudentListPagination.previous}
              >
                First
              </Button>
              <Button
                className="studentBox-navigation"
                onClick={previousClick}
                disabled={!userManagementRoleStudentListPagination.previous}
              >
                Prev
              </Button>
              <Button
                className="studentBox-navigation"
                onClick={nextClick}
                disabled={!userManagementRoleStudentListPagination.next}
              >
                Next
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default ViewStudent;
