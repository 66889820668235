import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Row, Card, Typography } from "antd";
import { useSelector } from "react-redux";
import options from "./chartOptions";
import { getComplianceOverviewData } from "../../../../../../../../../../../../selectors/vetTrack/analysis.selector";

const StackedPercentageColumnAvetmissChart = () => {
  const [optionData, setOptionData] = useState(options);
  const { Text } = Typography;
  const chartRef = useRef(null); // Create a ref to the HighchartsReact component

  const analysisData = useSelector(getComplianceOverviewData);
  const { categories = [], seriesData = [] } =
    analysisData?.stackedColumnChartData || {};

  useEffect(() => {
    if (categories?.length) {
      setOptionData((options) => ({
        ...options,
        xAxis: {
          ...options?.xAxis,
          categories: categories,
        },
      }));
    }
    if (seriesData?.length) {
      setOptionData((options) => ({
        ...options,
        series: seriesData,
      }));
    }
  }, [categories, seriesData]);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        if (chart) {
          chart.reflow();
        }
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card bodyStyle={{ padding: 10 }} className="avetmiss-card-container">
      <Text strong>AVETMISS</Text>
      <Row className="avetmiss-card-body">
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={optionData}/>
      </Row>
    </Card>
  );
};

export default StackedPercentageColumnAvetmissChart;
