export default {
  manageDashboard: {
    name: 'Application Management',
    key: 'dashboard',
    icon: "DashboardFilled",
    url: '/vettrak/dashboard/manage',
  }, 
  manageUsers: {
    name: 'User Management',
    key: 'users',
    icon: "UserOutlined",
    url: '/vettrak/user/manage',
  },
  axcelerateManageDashboard: {
    name: 'Application Management',
    key: 'dashboard',
    icon: "DashboardFilled",
    url: '/axcelerate/dashboard/manage',
  }, 
  axcelerateManageUsers: {
    name: 'User Management',
    key: 'users',
    icon: "UserOutlined",
    url: '/axcelerate/user/manage',
  },
};
