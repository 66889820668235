import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const ConfirmationModal = props => {
  const { 
    modalStatus,
    closeModal,
    saveSelection,
    title,
    children,
    setToggle,
  } = props;

  const onCancel = () => {
    closeModal(false);
    setToggle(false);
  };

  const onAccept = () => {
    saveSelection();
    closeModal(false);
  };

  return (
    <Modal
      title={<strong>{title}</strong>}
      visible={modalStatus}
      okText="Understood"
      width={360}
      onCancel={onCancel}
      onOk={onAccept}
      className='cluster-settings-modal'
      centered
    >
      {children}
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  modalStatus: PropTypes.bool,
  closeModal: PropTypes.func,
  saveSelection: PropTypes.func,
};

export default ConfirmationModal;
