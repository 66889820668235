import React, { memo, useState } from "react";
import { Col, DatePicker, Form, Row } from "antd";
import moment from "moment";

const DATE_FORMAT = "YYYY MMM DD";

const AdvisorDateFilter = ({ handleAdvisorDateFilter }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const DateSet = (start, end) => {
    const enrollmentStart = start && start.isValid() ? start.format(DATE_FORMAT) : null;
    const enrollmentEnd = end && end.isValid() ? end.format(DATE_FORMAT) : null;
    handleAdvisorDateFilter(enrollmentStart, enrollmentEnd);
  };

  const handleStartDateChange = (date) => {
    if (date && date.isValid()) {
      setStartDate(date);
      DateSet(date, endDate);
    } else {
      setStartDate(null);
      DateSet(null, endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && date.isValid()) {
      setEndDate(date);
      DateSet(startDate, date);
    } else {
      setEndDate(null);
      DateSet(startDate, null);
    }
  };

  const disabledStartDate = (current) => {
    return current && current.isValid() && endDate && current >= moment(endDate).endOf('day');
  };

  const disabledEndDate = (current) => {
    return current && current.isValid() && startDate && current <= moment(startDate).endOf('day');
  };

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          colon={false}
          label="Enrollment Start"
          style={{
            marginRight: "7.5px",
          }}
        >
          <DatePicker
            className="advisor-dateFilter"
            disabledDate={disabledStartDate}
            onChange={handleStartDateChange}
            placeholder={"Starts Before"}
            format={DATE_FORMAT}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          colon={false}
          label="Enrollment End"
          style={{
            marginLeft: "7.5px",
          }}
        >
          <DatePicker
            className="advisor-dateFilter"
            disabledDate={disabledEndDate}
            onChange={handleEndDateChange}
            placeholder={"Ends After"}
            format={DATE_FORMAT}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(AdvisorDateFilter);