import _get from "lodash/get";
import { createSelector } from "reselect";

// Advisor View Toggle status
export const makeAdvisorViewData = state => _get(state, 'innovative.advisorView.advisorView', {});

export const getAdvisorViewVisibility = createSelector(
  makeAdvisorViewData, 
  data => _get(data, 'isVisible', false)
);

export const getAdvisorAccessLoading = createSelector(
  makeAdvisorViewData, 
  data => _get(data, 'accessLoading', false)
);

export const getAdvisorAccess = createSelector(
  makeAdvisorViewData, 
  data => _get(data, 'access', false)
);

// Advisor View Role List
export const makeAdvisorRoleListData = state => _get(state, 'innovative.advisorView.roleList', {});

export const makeAdvisorRoleListLoading = createSelector(
  makeAdvisorRoleListData, 
  data => _get(data, 'isLoading', false)
);

export const makeAdvisorRoleList = createSelector(
  makeAdvisorRoleListData, 
  data => _get(data, 'data.response.data', [])
);

// Advisor Filters
export const makeAdvisorFiltersData = state => _get(state, 'innovative.advisorView.advisorFilters', {});

export const makeAdvisorFiltersLoading = createSelector(
  makeAdvisorFiltersData, 
  data => _get(data, 'isLoading', false)
);

export const makeAdvisorFilters = createSelector(
  makeAdvisorFiltersData, 
  data => _get(data, 'data.response.data', {})
);

// Advisor Terms
export const makeAdvisorTermsData = state => _get(state, 'innovative.advisorView.advisorTerms', {});

export const makeAdvisorTermsLoading = createSelector(
  makeAdvisorTermsData, 
  data => _get(data, 'isLoading', false)
);

export const makeAdvisorTerms = createSelector(
  makeAdvisorTermsData, 
  data => _get(data, 'data.response.data', {})
);

// Advisor Students
export const makeAdvisorStudentsData = state => _get(state, 'innovative.advisorView.advisorStudents', {});

export const makeAdvisorStudentsLoading = createSelector(
  makeAdvisorStudentsData, 
  data => _get(data, 'isLoading', false)
);

export const makeAdvisorStudents = createSelector(
  makeAdvisorStudentsData, 
  data => _get(data, 'data.response.data.studentData', [])
);

export const makeAdvisorStudentsCount = createSelector(
  makeAdvisorStudentsData,
  data => _get(data, 'data.response.data.totalCount', '')
);

export const makeAdvisorStudentsSearch = createSelector(
  makeAdvisorStudentsData,
  data => _get(data, 'data.response.data.searchValue', '')
);

// Advisor View student Filters set
export const makeAdvisorViewStudentFilterData = state => _get(state, 'innovative.advisorView.studentAdvisorFilters', {});

export const getAdvisorViewStudentFilter = createSelector(
  makeAdvisorViewStudentFilterData, 
  data => _get(data, 'data', false)
);

// Advisor Main Data
export const makeAdvisorMainData = state => _get(state, 'innovative.advisorView.studentData', {});

export const makeAdvisorMainDataLoading = createSelector(
  makeAdvisorMainData, 
  data => _get(data, 'isLoading', false)
);

export const makeAdvisorMainDataResponse = createSelector(
  makeAdvisorMainData, 
  data => _get(data, 'data', {})
);

// Advisor Student fetching Parameters
export const makeAdvisorStudentRoleUuIdData = state => _get(state, 'innovative.advisorView.advisorStudentRoleUuId', {});

export const makeAdvisorStudentRoleUuId = createSelector(
  makeAdvisorStudentRoleUuIdData, 
  data => _get(data, 'role_uuid', '')
);

export const makeAdvisorStudentPageNoData = state => _get(state, 'innovative.advisorView.advisorStudentpageNo', {});

export const makeAdvisorStudentPageNo = createSelector(
  makeAdvisorStudentPageNoData, 
  data => _get(data, 'pageNo', '')
);

export const makeAdvisorStudentSearchValueData = state => _get(state, 'innovative.advisorView.advisorStudentSearchValue', {});

export const makeAdvisorStudentSearchValue = createSelector(
  makeAdvisorStudentSearchValueData, 
  data => _get(data, 'value', '')
);

export const makeAdvisorallCouresLoadingStateData = state => _get(state, 'innovative.advisorView.allCouresLoading', {});

export const getAllCouresLoadingState = createSelector(
  makeAdvisorallCouresLoadingStateData, 
  data => _get(data, 'isLoading', false)
);