import React from 'react';
import { Layout } from 'antd';
import { LoginOutlined, MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { get as _get } from 'lodash';
import vttLogo from '../../../../assets/images/vtt-logo.png';
import Sidebar from '../../../vetTrack/dashboardManagement/common/layout/Sidebar';
import menuData from './vetTrackMenuData';
import UserLogout from '../../../auth/externalAuth/Logout/Logout';
import VTTLogout from '../components/logOutButton';

// VET-Check this
import { getDataFromLocalStore } from '../../../../utils/platformBaseHelpers/jobReady/general';
import '../../../../assets/css/vetTrack/vttDashboardManagement.css';
import './main.css';
import { getDomainMetaData } from '../../../../selectors/tentacleMeta.selector';
import { menu } from '../../../../constant/vetTrack/menu';

const VetTrackDashboardManagementLayout = ({ children }) => {
  const history = useHistory();

  const parsedUrl = new URL(window.location.href);
  const hostName = parsedUrl.host;

  const isHidden = true;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
  const {
    manageDashboard,
    manageUsers,
    axcelerateManageDashboard,
    axcelerateManageUsers,
  } = menuData;

  const domainData = useSelector(getDomainMetaData);
  const schoolLogo = _get(domainData, 'icon', '');

  const getRelevantSideBarManageUser = () => {
    if (hostName?.includes(menu.routs.vettrak)) {
      return manageUsers;
    }
    if (hostName?.includes(menu.routs.axcelerate)) {
      return axcelerateManageUsers;
    }
    return manageUsers;
  }

  const getRelevantSideBarApplicationManage = () => {
    if (hostName?.includes(menu.routs.vettrak)) {
      return [manageDashboard];
    }
    if (hostName?.includes(menu.routs.axcelerate)) {
      return [axcelerateManageDashboard];
    }
    return [manageDashboard]; 
  }

  const prepareSidebarItems = items =>
    items.map(item => ({
      name: item.name,
      key: item.key,
      icon: item.icon,
      click: () => history.push(item.url),
    }));

  const validateSidebarItems = items => {
    // check if the user has admin rights and add admin only items
    const { isAdmin } = getDataFromLocalStore([
      { key: 'isAdmin', default: false },
    ]);

    if (isAdmin) {
      const manageUser = getRelevantSideBarManageUser();
      return [...items, manageUser];
    }
    return items;
  };

  const sidebarItems = prepareSidebarItems(
    validateSidebarItems(getRelevantSideBarApplicationManage()),
  );

  const onLogoError = (error) => {
    error.target.src = vttLogo;
  }

  return (
    <Layout>
      <Layout.Header
        className="vtt-header withMenu"
        style={{ background: 'white' }}
      >
        <div className="mobileNav">
          <MenuOutlined
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          />
        </div>
        <div className="logo">
          <img onError={onLogoError} src={schoolLogo} alt="headerLogo" />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            height: '100%',
          }}
        >

          <UserLogout
            button={({ onClick }) => (
              <span
                onKeyDown={() => { }}
                title="Sign out"
                onClick={onClick}
                className="vett-setting-icon"
                style={{ border: 'none', cursor: 'pointer' }}
                role="button"
                tabIndex={0}
              >
                <LoginOutlined />
              </span>
            )}
          />

          {!isHidden && <VTTLogout />}

        </div>
      </Layout.Header>
      <Layout className="vtt-sidebarLayout">
        <Sidebar
          collapsed={isSidebarCollapsed}
          setCollapsed={setIsSidebarCollapsed}
          sidebarItems={sidebarItems}
        />
        <Layout.Content className="vtt-content-main">
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default VetTrackDashboardManagementLayout;
