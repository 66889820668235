import React, { useEffect, useState } from "react";
import { Button, Card, notification, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Sidebar from "./sideBar";
import Details from "./details";
import {
  getAccountsHierarchyList,
  getCoursePayloadData,
  getEditCanvasStudentStatus,
  getEditCoursePermissionStatus,
  getEditRoleData,
  getPermissionPayloadData,
  getRoleDeatails,
  getSaveCoursePermissionStatus,
  getSavePayloadData,
  getSaveRolesStatus,
  getUpdateRolesData,
  getUserManagementPermissionsInfo,
  getUserManagementPermissionsInfoStatus,
  getUserManagementSetUserAvailability,
} from "../../../../../selectors/userManagement/userManagement.selector";
import {
  clearEditCanvasStudents,
  clearEditSaveStatus,
  clearPermissionInfo,
  clearRoleData,
  clearSaveStatus,
  editCanvasStudents,
  editCoursePermissions,
  editRoleDataAction,
  fetchAllRoles,
  fetchPermissionInfo,
  ResetCoursePayload,
  ResetCoursePermissionData,
  resetCoursePermissionPayload,
  resetIndividualRoleData,
  resetPayloadData,
  resetSaveData,
  saveCoursePermissions,
  saveUsers,
} from "../../../../../actions/userManagement/userManagement.action";
import menuData from "../../../../layout/dashboardManagement/menuData";
import Permission from "../addPermission/permission";
import {
  ADMIN,
  ADVISOR,
  CANVAS,
  CANVAS_ROLE,
  CUSTOM,
  SAVE_SUCCESS,
  SUCCESS,
} from "../../../../../utils/userManagement/constant";
import {
  checkCoursesStatus,
  identifyCourseContradictions,
  showContradictionsNotification,
} from "../../../../../utils/userManagement/userManagement.helper";

const items = [
  { key: menuData.details.key, title: menuData.details.name },
  { key: menuData.permission.key, title: menuData.permission.name },
];

const AddGroupView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Title } = Typography;
  const [activeTab, setActiveTab] = useState("details");

  const savePayloadData = useSelector(getSavePayloadData);
  const editRoleData = useSelector(getEditRoleData);
  const saveRolesStatus = useSelector(getSaveRolesStatus);
  const permissionPayloadData = useSelector(getPermissionPayloadData);
  const saveCoursePermissionStatus = useSelector(getSaveCoursePermissionStatus);
  const updateRolesData = useSelector(getUpdateRolesData);
  const editCoursePermissionStatus = useSelector(getEditCoursePermissionStatus);
  const userManagementSetUserAvailability = useSelector(getUserManagementSetUserAvailability);
  const userManagementPermissionsInfo = useSelector(getUserManagementPermissionsInfo);
  const userManagementPermissionsInfoStatus = useSelector(getUserManagementPermissionsInfoStatus);
  const editCanvasStudentStatus = useSelector(getEditCanvasStudentStatus);
  const roleDeatails = useSelector(getRoleDeatails);
  const accountsHierarchyList = useSelector(getAccountsHierarchyList);
  const coursePayloadData = useSelector(getCoursePayloadData);

  useEffect(() => {
    if (Object.keys(userManagementPermissionsInfo).length === 0
      && userManagementPermissionsInfoStatus === "Success") {
      if (Object.keys(permissionPayloadData).length && Object.keys(saveRolesStatus).length) {
        dispatch(saveCoursePermissions(permissionPayloadData));
        return;
      }
      if (Object.keys(permissionPayloadData).length && Object.keys(updateRolesData).length) {
        dispatch(editCoursePermissions(permissionPayloadData));
        return;
      }
      if (Object.keys(permissionPayloadData).length && Object.keys(editCanvasStudentStatus).length) {
        dispatch(editCoursePermissions(permissionPayloadData));
        return;
      }
      dispatch(clearPermissionInfo());
    }
  }, [
    userManagementPermissionsInfo,
    JSON.stringify(permissionPayloadData),
    JSON.stringify(saveRolesStatus),
    JSON.stringify(updateRolesData),
    JSON.stringify(editCanvasStudentStatus),
  ]);

  useEffect(() => {
    if (userManagementPermissionsInfo && userManagementPermissionsInfo.message) {
      notification.info({
        message: "Courses Conflict",
        description:
          userManagementPermissionsInfo.message,
      });
    }
  }, [userManagementPermissionsInfo]);

  const handleMenuClick = (key) => {
    setActiveTab(key);
  };


  const handleSaveAndNext = () => {
    if (userManagementSetUserAvailability && savePayloadData.section === CUSTOM) {
      notification.error({
        message: 'Unable to save changes',
        description: 'Please select at least one user to proceed.',
      });
      return;
    }
    if (
      Object.keys(savePayloadData).length &&
      savePayloadData.section === CANVAS &&
      !editRoleData
    ) {
      dispatch(saveUsers(savePayloadData));
      return;
    }
    if (
      Object.keys(savePayloadData).length &&
      savePayloadData.section === CANVAS &&
      editRoleData
    ) {
      dispatch(editCanvasStudents(savePayloadData));
      return;
    }
    if (
      Object.keys(savePayloadData).length &&
      savePayloadData.section === CUSTOM &&
      !editRoleData &&
      !userManagementSetUserAvailability
    ) {
      dispatch(saveUsers(savePayloadData));
      return;
    }
    if (
      Object.keys(savePayloadData).length &&
      savePayloadData.section === CUSTOM &&
      !userManagementSetUserAvailability
    ) {
      dispatch(editRoleDataAction(savePayloadData));
      return;
    }
    if (
      Object.keys(saveRolesStatus).length &&
      Object.keys(permissionPayloadData).length &&
      !identifyCourseContradictions(accountsHierarchyList, coursePayloadData).length
    ) {
      const courseInforPayload = {
        role_uuid: saveRolesStatus.data,
        account_data: permissionPayloadData.account_data,
      };
      dispatch(fetchPermissionInfo(courseInforPayload));
      return;
    }
    if (
      Object.keys(updateRolesData).length &&
      Object.keys(permissionPayloadData).length &&
      !identifyCourseContradictions(accountsHierarchyList, coursePayloadData).length) {
      const courseInforPayload = {
        role_uuid: updateRolesData.data,
        account_data: permissionPayloadData.account_data,
      };
      dispatch(fetchPermissionInfo(courseInforPayload));
      return;
    }
    if (
      Object.keys(editCanvasStudentStatus).length &&
      Object.keys(permissionPayloadData).length &&
      !identifyCourseContradictions(accountsHierarchyList, coursePayloadData).length
    ) {
      const courseInforPayload = {
        role_uuid: editCanvasStudentStatus.data,
        account_data: permissionPayloadData.account_data,
      };
      dispatch(fetchPermissionInfo(courseInforPayload));
      return;
    }
    if (
      permissionPayloadData && updateRolesData &&
      Object.keys(permissionPayloadData).length === 0 &&
      Object.keys(updateRolesData).length
    ) {
      const payload = {
        consumer_id: updateRolesData.schoolId,
        role_uuid: updateRolesData.data,
        account_data: []
      };
      dispatch(editCoursePermissions(payload));
      return;
    }
    if (identifyCourseContradictions(accountsHierarchyList, coursePayloadData).length) {
      const contradictions = identifyCourseContradictions(accountsHierarchyList, coursePayloadData);
      showContradictionsNotification(contradictions);
      return;
    }
  };

  const handleCancel = () => {
    history.push(menuData.userRoles.url);
  };

  useEffect(() => {
    if (
      savePayloadData.role_mode === ADMIN &&
      saveRolesStatus.statusText === SUCCESS
    ) {
      setActiveTab(menuData.permission.key);
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
      return;
    }
    if (
      savePayloadData.role_mode === ADVISOR &&
      saveRolesStatus.statusText === SUCCESS
    ) {
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
      dispatch(resetSaveData());
      history.push(menuData.userRoles.url);
      return;
    }
  }, [savePayloadData, saveRolesStatus]);
  useEffect(() => {
    dispatch(fetchAllRoles());
  }, [])

  useEffect(() => {
    if (
      editCanvasStudentStatus.statusText === SUCCESS &&
      savePayloadData.role_mode === ADVISOR
    ) {
      history.push(menuData.userRoles.url);
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
      dispatch(clearEditCanvasStudents());
      return;
    }
    if (
      editCanvasStudentStatus.statusText === SUCCESS &&
      savePayloadData.role_mode === ADMIN
    ) {
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
      setActiveTab(menuData.permission.key);
      return;
    }
  }, [editCanvasStudentStatus, savePayloadData]);

  useEffect(() => {
    if (editCoursePermissionStatus.statusText === SAVE_SUCCESS) {
      dispatch(clearRoleData());
      dispatch(ResetCoursePayload());
      dispatch(resetCoursePermissionPayload());
      dispatch(ResetCoursePermissionData());
      dispatch(clearEditSaveStatus());
      dispatch(clearPermissionInfo());
      dispatch(clearEditCanvasStudents());
      history.push(menuData.userRoles.url);
    }
  }, [JSON.stringify(editCoursePermissionStatus)]);

  useEffect(() => {
    if (saveCoursePermissionStatus.statusText === SAVE_SUCCESS) {
      dispatch(resetSaveData());
      dispatch(ResetCoursePayload());
      dispatch(resetCoursePermissionPayload());
      dispatch(clearSaveStatus());
      dispatch(clearPermissionInfo());
      dispatch(clearRoleData());
      history.push(menuData.userRoles.url);
    }
  }, [JSON.stringify(saveCoursePermissionStatus)]);

  useEffect(() => {
    if (
      savePayloadData.role_mode === ADMIN &&
      updateRolesData &&
      updateRolesData.statusText &&
      updateRolesData.statusText === SUCCESS
    ) {
      setActiveTab(menuData.permission.key);
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
    }

    if (
      savePayloadData.role_mode === ADVISOR &&
      updateRolesData &&
      updateRolesData.statusText &&
      updateRolesData.statusText === SUCCESS
    ) {
      dispatch(resetPayloadData());
      dispatch(resetIndividualRoleData());
      history.push(menuData.userRoles.url);
    }
  }, [savePayloadData, JSON.stringify(updateRolesData)]);

  return (
    <>
      <Sidebar
        title="Add Group"
        activeTab={activeTab}
        items={items}
        onMenuClick={handleMenuClick}
      />
      <div className="role-management-details-container">
        {activeTab === menuData.details.key && (
          <>
            <Title level={4}>Details</Title>
            <Details />
          </>
        )}
        {activeTab === menuData.permission.key && (
          <Permission />
        )}
      </div>
      <Card className="role-management-details-submit-card">
        <div className="role-management-details-submit-card-button-container">
          <Button
            onClick={handleCancel}
          >Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSaveAndNext}>
            {activeTab === menuData.details.key && roleDeatails.roleType === ADMIN
              ? "Save and next"
              : "Save"
            }
          </Button>
        </div>
      </Card>
    </>
  );
};

export default AddGroupView;
