import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography } from "antd";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import GridItem from "./NatGridItem";
import GridItemModal from "./NatModal";
import {
  getComplianceOverviewData,
  getDashboardFiltersFetchData
} from '../../../../../../../../../../../../selectors/vetTrack/analysis.selector';
import {
  fetchRiskOverviewPopups,
} from '../../../../../../../../../../../../actions/vetTrack/analysis/riskAnalysis/compliance.action';

const GridComponent = () => {
  const COMPLIANCE_SUBSELECTION_ID = 3;
  const { Text } = Typography;

  const dispatch = useDispatch();
  const analysisData = useSelector(getComplianceOverviewData)
  const filterData = useSelector(getDashboardFiltersFetchData);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalName, setModalName] = useState('Modal Title');
  const [filterProps, setFilterProps] = useState({
    enrolmentYears: [],
    states: [],
    qualificationLevels: [],
    courses: [],
  });

  useEffect(() => {
    if (filterData) {
      const enrolmentYears = filterData?.enrolmentYears?.map((each) => each?.id)
      const states = filterData?.states?.map((each) => each?.id)
      const qualificationLevels = filterData?.qualificationLevels?.map((each) => each?.id)
      const courses = filterData?.courses?.map((each) => each?.id)
      setFilterProps((props) => ({ ...props, enrolmentYears, states, qualificationLevels, courses }))
    }
  }, [filterData]);

  const showModal = (natfileName) => {
    setModalVisible(true);
    setModalName(natfileName)
    dispatch(fetchRiskOverviewPopups({
      filters: { ...filterProps }, 
      subSectionId: COMPLIANCE_SUBSELECTION_ID, 
      popupType: natfileName
    }))
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const gridItems = analysisData?.natFiles || []

  return (
    <Card bodyStyle={{ padding: 10 }} className="nat-card-container">
      <Text strong>NAT Files</Text>
      <Row gutter={[10, 10]} className='nat-card-container-body'>
        {gridItems.map((item, index) => (
          <Col key={index} lg={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 12 }}>
            <GridItem
              name={item.fileName}
              missingCount={item.missingCount}
              percentage={item.missingPercentage}
              handleClick={showModal}
            />
          </Col>
        ))}
      </Row>
      <GridItemModal visible={modalVisible} onCancel={handleCancel} modalName={modalName}/>
    </Card>
  );
};

export default GridComponent;

GridComponent.propTypes = {
  gridItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
}