import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';
import CardItem from './partials/CardItem';

const MyDashboards = ({ dashboards, isLoading, onActiveChange }) => (
  <div className="container applicationList">
      <h4>Application Management</h4>
      <List
        grid={{ gutter: 16, column: 1 }}
        itemLayout="horizontal"
        dataSource={dashboards}
        loading={isLoading}
        renderItem={dashboard => (
          <CardItem dashboard={dashboard} activeChange={onActiveChange} />
        )}
      />
  </div>
);

MyDashboards.propTypes = {
  dashboards: PropTypes.array,
  isLoading: PropTypes.bool,
  onActiveChange: PropTypes.func,
};

export default MyDashboards;
