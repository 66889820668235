import {
  VTT_COMPLIANCE_CARDS_DATA_FETCH,
  VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_STUDENTS_DATA_FETCH,
  VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_OVERVIEW_DATA_FETCH,
  VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH,
  VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING,
  VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING,
  VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH
} from '../../../../constant/actionTypes';

const defaultState = {
  cards: {
    isLoading: false,
    data: {},
  },
  students: {
    isLoading: false,
    data: {},
  },
  overview: {
    isLoading: false,
    data: {
      lineChartData: null,
      barChartData: null,
      pieChartData: null,
    },
  },
  durationToExpire: {
    isLoading: false,
    data: {},
  },
  natTableData: {
    isLoading: false,
    data: {},
  },
};

const complianceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case VTT_COMPLIANCE_CARDS_DATA_FETCH:
    case VTT_COMPLIANCE_CARDS_DATA_FETCH_LOADING:
      return {
        ...state,
        cards: {
          ...state.cards,
          ...action.payload,
        },
      };
    case VTT_COMPLIANCE_STUDENTS_DATA_FETCH:
    case VTT_COMPLIANCE_STUDENTS_DATA_FETCH_LOADING:
      return {
        ...state,
        students: {
          ...state.students,
          ...action.payload,
        },
      };
    case VTT_COMPLIANCE_OVERVIEW_DATA_FETCH:
    case VTT_COMPLIANCE_OVERVIEW_DATA_FETCH_LOADING:
      return {
        ...state,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    case VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH:
    case VTT_COMPLIANCE_DURATION_TO_EXPIRE_DATA_FETCH_LOADING:
      return {
        ...state,
        durationToExpire: {
          ...state.durationToExpire,
          ...action.payload,
        },
      };
    case VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_DATA_FETCH:
    case VTT_COMPLIANCE_RISK_COMPLIANCE_POPUP_FETCH_LOADING:
      return {
        ...state,
        natTableData: {
          ...state.natTableData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default complianceReducer;
