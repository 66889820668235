import React, { Fragment, useState } from 'react';
import { Collapse, Space, Switch, Typography } from 'antd';
import ReadyTecSectionContainer from './readyTecSection';
import { menu } from '../../../../../../constant/vetTrack/menu';

const { Panel } = Collapse;

const SectionGroup = () => {
  const [isCanvasLmsActive, setCanvasLmsActive] = useState(false);
  const [isLiveSpringActive, setLiveSpringActive] = useState(false);
  const isHidden = true;
  const parsedUrl = new URL(window.location.href);
  const hostName = parsedUrl.host;

  const renderSectionHeader = (title, switchDisabled, value) => (
    <div>
      <Space style={{ display: 'flex', alignItems: 'center' }}>
        <Switch
          size="small"
          checked={value}
          disabled={switchDisabled}
          onClick={event => {
            event.stopPropagation();
            if (!switchDisabled) {
              if (title === "Canvas LMS") {
                setCanvasLmsActive(!isCanvasLmsActive);
              } else if (title === "Live Spring") {
                setLiveSpringActive(!isLiveSpringActive);
              }
            }
          }}
        />
        <Typography.Text strong>{title}</Typography.Text>
      </Space>
    </div>
  );

  const readyTechSwitchTitle = () => {
    if (hostName?.includes(menu.routs.vettrak)) {
      return "ReadyTech";
    }
    if (hostName?.includes(menu.routs.axcelerate)) {
      return "Axcelerate";
    }
    return "ReadyTech";
  }

  return (
    <Collapse
      expandIconPosition="right"
      defaultActiveKey="1"
      className="vtt-customAcordian"
    >
      <Panel className="vtt-activeData-panel" header={renderSectionHeader(readyTechSwitchTitle(), true, true)} key="1">
        <ReadyTecSectionContainer />
      </Panel>
      {isHidden ?
        <></>
        :
        <Fragment>
          <Panel className="vtt-activeData-panel" header={renderSectionHeader('Canvas LMS', false, isCanvasLmsActive)} key="2"></Panel>
          <Panel className="vtt-activeData-panel" header={renderSectionHeader('Live Spring', false, isLiveSpringActive)} key="3"></Panel>
        </Fragment>
      }
    </Collapse>
  );
};

export default SectionGroup;
