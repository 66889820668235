import React, { useEffect, useRef, useState } from 'react';
import { Col, Layout, Row, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../common/layout/Loader";
import TopCard from "./topCard";
import StudentGrid from "./studentGrid";
import AdvisorGrid  from "./advisorGrid";
import OutcomeGridContainer from "./outComeGrid/OutcomeGridContainer";
import Search from "../common/form/Search";
import GridSwitch from "../common/gridSwitches/GridSwitches";
import SidebarCourseView from '../common/layout/SidebarCourseView';
import { isEmpty } from 'lodash';
import NoDataOverlay from './NoDataOverlay';
import StudentClusterContainer from './studentClustering';
import {
  getChatMessages,
  getStudentClusterData,
  makeInnovativeCourseLoading,
  makeInnovativeCoursesMainReportClusterStatusResponseData
} from '../../../selectors/innovative/course.selector';
import { getAdvisorViewStudentFilter, getAdvisorViewVisibility } from "../../../selectors/innovative/advisorView.selector.js";
import {
  fetchingClusterData,
  requestClusterDataConfirmation,
  resetChatData,
  resetClusterData,
  resetModuleProgressData
} from '../../../actions/innovative/course.action';
import ChatBoxContainer from '../common/chatBoxContainer/chatBoxContainer';
import { getAnalyzeEnableStatus } from '../../../selectors/innovative/setting.selector';
import ModuleProgressGrid from './moduleProgressGrid/ModuleProgressGrid';
import { values } from 'lodash';
import AdvisorSearch from './advisorGrid/advisorSearch.js';
import { setStudentSearchValue } from '../../../actions/innovative/advisorView.action.js';

const { TabPane } = Tabs;
const CLUSTER_DATA_AVAILABLE_STATUS_CODE = 102;

const CourseView = (
  {
    selectedCourse,
    areCoursesLoading,
    selectedTerm,
    outcomeEnabled,
    onChangeStudentState,
    switchFormValues,
    setSwitchFormValues,
    clusteringEnabled,
  }
) => {
  const dispatch = useDispatch();

  const [studentSearch, setStudentSearch] = useState(null);
  const [outcomeSearch, setOutcomeSearch] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [moduleSearch, setModuleSearch] = useState(null);
  const [startCountDownTimer, setStartCountDownTimer] = useState(false);
  const [currentTab, setCurrentTab] = useState("students");
  const searchRef = useRef(null);

  const [isClusteringDataAvailable, setClusteringDataAvailable] = useState(false);
  const clusterStatus = useSelector(makeInnovativeCoursesMainReportClusterStatusResponseData);
  const isAnalyzingEnabled = useSelector(getAnalyzeEnableStatus);
  const chatData = useSelector(getChatMessages);
  const clusterData = useSelector(getStudentClusterData);
  const selectedCoursesLoading = useSelector(makeInnovativeCourseLoading);
  const advisorViewVisibility = useSelector(getAdvisorViewVisibility);

  const tabRef = useRef(null);

  useEffect(() => {
    dispatch(resetClusterData());
    dispatch(resetChatData());
    setCurrentTab("students");
    dispatch(resetModuleProgressData());
  }, [
    areCoursesLoading,
    JSON.stringify(selectedCourse),
    selectedCoursesLoading,
  ]);

  useEffect(() => {
    if (clusterStatus) {
      switch (clusterStatus.status) {
        case 102: {
          setClusteringDataAvailable(true);
          return;
        }
        case 204: {
          dispatch(fetchingClusterData(false));
          setClusteringDataAvailable(false);
          return;
        }
        default: {
          dispatch(fetchingClusterData(false));
          setClusteringDataAvailable(false);
          return;
        }
      }
    }
  }, [JSON.stringify(clusterStatus)])

  // load relevant search bar as default
  useEffect(() => {
    if (studentSearch && currentTab === "students") {
      setCurrentSearch(pev => studentSearch);
    }
    if (outcomeSearch && currentTab === "outcomes") {
      setCurrentSearch(pev => outcomeSearch);
    }
    if (moduleSearch && currentTab === "module_progress") {
      setCurrentSearch(pev => moduleSearch);
    }
  }, [studentSearch, outcomeSearch, moduleSearch])

  const onTabChange = (e) => {
    // change search bar
    setCurrentTab(e);

    // clear previous searched data
    onSearch({ target: { value: "" } })

    //empty search bar
    if (searchRef.current) {
      searchRef.current.state.value = "";
    }

    switch (e) {
      case "students":
        return setCurrentSearch(prev => studentSearch);
      case "outcomes":
        return setCurrentSearch(prev => outcomeSearch);
      case "module_progress":
        return setCurrentSearch(prev => moduleSearch);
      case "studentClustering": {
        if (clusterStatus.status === CLUSTER_DATA_AVAILABLE_STATUS_CODE && clusterData && !clusterData?.length) {
          dispatch(requestClusterDataConfirmation());
        }
        
        if (chatData.length > 0) {
          return setCurrentSearch(null);
        }

        return setCurrentSearch(null);
      }
      default:
        return
    }
  }

  const onSearch = (e) => {
    currentSearch && currentSearch(e);
  }

  const SearchFun = value => {
    dispatch(setStudentSearchValue(value))
  };

  const renderChatAI = () => {
    if (
      isAnalyzingEnabled &&
      isClusteringDataAvailable && 
      clusterData.length
    ) {
      return (
        <ChatBoxContainer
          isClusterCountDownOn={startCountDownTimer}
        />
      );
    }
  };

  const renderGridSwitches = () => (
    <div style={{ marginBottom: 10, display: "flex", justifyContent: "flex-end" }}>
      <GridSwitch
        onChange={onChangeStudentState}
        isLoading={areCoursesLoading}
        formValues={switchFormValues}
        setFormValues={setSwitchFormValues}
        switchConf={{
          switches: {
            switch1: { label: "Active Students", key: "active", onChangeKeys: ["active", "completed"] },
            switch2: { label: "Completed Students", key: "completed", onChangeKeys: ["completed", "active"] }
          },
        }}
      />
    </div>
  )

  const getLayOutContent = () => {
    if (isEmpty(selectedCourse)) {
      return <NoDataOverlay />;
    }

    return (
      <div className="course-in-container">
        <div className="in-sectionCover">
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <TopCard switches={renderGridSwitches} />
            </Col>
          </Row>
        </div>
        <div className="in-sectionCover">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Tabs
                activeKey={currentTab}
                tabBarStyle={{ fontSize: 40 }}
                className="customTabs"
                tabBarExtraContent={
                  currentSearch && (
                    <Search searchRef={searchRef} onSearch={onSearch} />
                  )
                }
                onChange={onTabChange}
              >
                <TabPane tab="Students" key="students">
                  <StudentGrid
                    courseId={selectedCourse.course_id}
                    selectedTerm={selectedTerm}
                    setSearchFunc={setStudentSearch}
                  />
                </TabPane>
                {clusteringEnabled && (
                  <TabPane tab="Student Classification" key="studentClustering" className="studentClasificationTab">
                    <div ref={tabRef}>
                      <StudentClusterContainer
                        isClusteringDataAvailable={isClusteringDataAvailable}
                        tabRef={tabRef}
                        setStartCountDownTimer={setStartCountDownTimer}
                        startCountDownTimer={startCountDownTimer}
                      />
                      {renderChatAI()}
                    </div>
                  </TabPane>
                )}
                <TabPane
                  tab="Module Progress"
                  key="module_progress"
                >
                  <ModuleProgressGrid
                    courseId={selectedCourse.course_id}
                    selectedTerm={selectedTerm}
                    isTabSelected={currentTab === "module_progress"}
                    setSearchFunc={setModuleSearch}
                  />
                </TabPane>
                {outcomeEnabled && (
                  <TabPane tab="Outcomes" key="outcomes">
                    <OutcomeGridContainer
                      courseId={selectedCourse.course_id}
                      selectedTerm={selectedTerm}
                      setSearchFunc={setOutcomeSearch}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    );
  };


  const getAdvisorLayOutContent = () => {
    if (isEmpty(selectedCourse)) {
      return <NoDataOverlay />;
    }

    return (
      <div className="course-in-container">
        <div className="in-sectionCover">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Tabs    
                tabBarStyle={{ fontSize: 40 }}
                className="customTabs"
                tabBarExtraContent={
                  <AdvisorSearch
                    SearchFun={SearchFun}
                  />
                }
                onChange={onTabChange}
              >
                <TabPane tab="Students" key="students">
                  <AdvisorGrid
                    courseId={selectedCourse.course_id}
                    selectedTerm={selectedTerm}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div className="in-sidebarLayout">
      {!advisorViewVisibility && <Loader isLoading={areCoursesLoading} />}
      <SidebarCourseView />
      <Layout>
        <Layout.Content className="">
          {advisorViewVisibility === false ? getLayOutContent() : getAdvisorLayOutContent()}
        </Layout.Content>
      </Layout>
    </div>
  );
};

CourseView.defaultProps = {
  areCoursesLoading: false,
  selectedCourse: {},
}

CourseView.propTypes = {
  selectedCourse: PropTypes.object,
  selectedCourses: PropTypes.array,
  areCoursesLoading: PropTypes.bool,
  onCourseSelect: PropTypes.func,
  onCourseSearch: PropTypes.func,
  terms: PropTypes.array,
  termsLoading: PropTypes.bool,
  termChange: PropTypes.func,
  selectedTerm: PropTypes.string,
  outcomeEnabled: PropTypes.bool,
  sidebarData: PropTypes.object,
  setSidebar: PropTypes.func,
  isClusteringDataAvailable: PropTypes.bool,
}

export default CourseView;
