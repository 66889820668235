import React from "react";
import { Table, Input, Typography } from "antd";

const columns = [
  {
    title: "User ID",
    dataIndex: "id",
    key: "id",
    className: "table-header",
  },
  {
    title: "User Name",
    dataIndex: "name",
    key: "name",
    className: "table-header",
  },
];

const GroupMembers = ({ list, loading ,onChange }) => {
  const { Title } = Typography;

  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Title level={5} style={{ marginRight: "auto", marginBottom: 16 }}>
          Group Members
        </Title>
        <div style={{ marginRight: 16 }}>
          <Input.Search placeholder="Search Users" onChange={handleChange} />
        </div>
      </div>
      <Table
        style={{ marginBottom: "1px" }}
        loading={loading}
        dataSource={list}
        columns={columns}
        pagination={{ pageSize: 8 }}
        className="roleManagement-table"
        rowClassName="roleManagement-row"
      />
    </>
  );
};

export default GroupMembers;
