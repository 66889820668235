import React, { useEffect } from 'react';
import { Card, Table } from "antd";
import PropTypes from "prop-types";
import Loader from "../../common/layout/Loader";

const AdvisorStudentTable = ({
  isLoading,
  dataSource,
  columns,
  pagination,
  onChange,
  rowKey,
}) => {

  return (
    <Card
      className="tableCoverCard"
    >
      <Loader isLoading={isLoading} />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        rowKey={rowKey}
        onChange={onChange}
        scroll={{ x: 1000 }}
      />
    </Card>
  );
};

AdvisorStudentTable.defaultProps = {
  isLoading: false,
};

AdvisorStudentTable.propTypes = {
  isLoading: PropTypes.bool,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  pagination : PropTypes.object,
  onChange: PropTypes.func,
  rowKey :PropTypes.string,
}

export default AdvisorStudentTable;