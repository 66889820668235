import React from "react";
import { Layout, Menu, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

const { Search } = Input;

const Sidebar = ({ title, onSearch, suppressHeader, activeTab, items, onMenuClick }) => {
  const renderSearch = () => {
    if (typeof onSearch === "function") {
      return <Search onSearch={onSearch} />;
    }
    return null;
  };

  const renderTitle = () => {
    if (title) {
      return <h5>{title}</h5>;
    }
    return null;
  };

  const renderHeader = () => {
    if (!suppressHeader) {
      const className = `searchFilter ${typeof onSearch !== "function" ? "no-search" : ""}`;
      return (
        <div className={className}>
          {renderTitle()}
          {renderSearch()}
        </div>
      );
    }
    return null;
  };

  return (
    <Layout.Sider width={250} collapsedWidth="0" trigger={null}>
      {renderHeader()}
      <div className="menuScroller">
        <Menu
          mode="inline"
          selectedKeys={[activeTab]}
        >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={<MailOutlined />}
              style={item.key === 'permission' && (activeTab === 'details' ? { opacity: 0.5 } : null)}
              disabled={item.key === 'permission' && activeTab !== 'details' && activeTab !== 'permission'}
            >
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
